import { put, call, takeLatest } from "redux-saga/effects"
import * as tournamentAPI from "api/tournament"
import Spinner from "components/commons/Spinner"
import notify from "components/commons/notification"
import * as types from "./types"
import * as actions from "./actions"
import i18n from "i18n"

const showNotify = (err, top) => {
	let errCode = ""
	if (err?.response?.data?.errorKey) {
		errCode = err?.response?.data?.errorKey
	} else if (err?.response?.data?.error) {
		errCode = err?.response?.data?.error
	}
	if (errCode) {
		notify({ desc: errCode, top })
	}
}

// payload will be a tournamentId
function* loadTournamentSaga({ payload: tournamentId }) {
	try {
		Spinner.show()
		const tournament = yield call(
			[tournamentAPI, "getTournamentById"],
			tournamentId
		)
		yield put(actions.setTournamentAction(tournament.data))
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(actions.loadTournamentFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* loadTournamentsSaga() {
	try {
		Spinner.show()
		const tournaments = yield call([tournamentAPI, "getAllTournaments"])
		yield put(actions.setTournamentsAction(tournaments.data.rows))
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(actions.loadTournamentsFailedAction())
	} finally {
		Spinner.destroy()
	}
}

// payload will be a tournamentId
function* loadTournamentHeatSaga({ payload: tournamentId }) {
	try {
		Spinner.show()
		const tournament = yield call(
			[tournamentAPI, "getTournamentHeatById"],
			tournamentId
		)
		yield put(actions.setTournamentHeatAction(tournament.data))
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(actions.loadTournamentHeatFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* createTournamentSaga({ payload }) {
	try {
		Spinner.show()

		const reqParams = {
			...(payload || {}),
		}
		const tournament = yield call(
			[tournamentAPI, "createTournament"],
			reqParams
		)
		yield put(actions.setTournamentAction(tournament.data))
		notify({
			type: "success",
			title: i18n.t("notification.createTournament.title"),
			desc: i18n.t("notification.createTournament.description"),
		})
		yield put(actions.createTournamentFinishedAction())
	} catch (error) {
		if (error.toString() === "Error: Request failed with status code 401") {
			notify({
				title: i18n.t("notification.noLogin.title"),
				desc: i18n.t("notification.noLogin.description")}
			)
		} else{
			yield call(showNotify, error, 40)
		}
		yield put(actions.createTournamentFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* updateTournamentSaga({ payload }) {
	try {
		Spinner.show()

		const reqParams = {
			...(payload || {}),
		}
		yield call([tournamentAPI, "updateTournament"], reqParams)
		notify({
			type: "success",
			title: i18n.t("notification.updateTournament.title"),
			desc: i18n.t("notification.updateTournament.description"),
		})
	} catch (error) {
		if (error?.response?.data?.errorKey) {
			notify({
				type: "error",
				title: i18n.t(`notification.${error?.response?.data?.errorKey}.title`),
				desc: i18n.t(
					`notification.${error?.response?.data?.errorKey}.description`
				),
			})
		}
		yield put(actions.updateTournamentFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* cancelTournamentSaga({ payload }) {
	try {
		Spinner.show()
		yield call([tournamentAPI, "cancelTournament"], {
			tournamentId: payload,
		})
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(actions.createTournamentFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* inviteUserToTournametSaga(data) {
	try {
		Spinner.show()
		const { userId, tournamentId } = data.payload
		yield call([tournamentAPI, "inviteUser"], tournamentId, userId)
		notify({
			type: "success",
			title: i18n.t("notification.inviteTournament.title"),
			desc: i18n.t("notification.inviteTournament.description"),
		})
	} catch (error) {
		if (error?.response?.data?.errorKey) {
			notify({
				type: "error",
				title: i18n.t(`notification.${error?.response?.data?.errorKey}.title`),
				desc: i18n.t(
					`notification.${error?.response?.data?.errorKey}.description`
				),
			})
		}
		yield put(actions.inviteTournamentFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* enterTournamentSaga({ payload }) {
	try {
		Spinner.show()
		yield call([tournamentAPI, "enterTournament"], payload)
		notify({
			type: "success",
			title: i18n.t("notification.entrySuccessful.title"),
			desc: i18n.t("notification.entrySuccessful.description"),
		})
		yield put(actions.enterTournamentFinishedAction())
	} catch (error) {
		if (error?.response?.data?.errorKey) {
			notify({
				type: "error",
				title: i18n.t(`notification.${error?.response?.data?.errorKey}.title`),
				desc: i18n.t(
					`notification.${error?.response?.data?.errorKey}.description`
				),
			})
		}
		yield put(actions.enterTournamentFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* enterTournamentHeatSaga({ payload }) {
	try {
		Spinner.show()
		yield call([tournamentAPI, "enterTournamentHeat"], payload)
		notify({
			type: "success",
			title: i18n.t("notification.entryHeatSuccessful.title"),
			desc: i18n.t("notification.entryHeatSuccessful.description"),
		})
		yield put(actions.enterTournamentHeatFinishedAction())
	} catch (error) {
		if (error?.response?.data?.errorKey) {
			notify({
				type: "error",
				title: i18n.t(`notification.${error?.response?.data?.errorKey}.title`),
				desc: i18n.t(
					`notification.${error?.response?.data?.errorKey}.description`
				),
			})
		}
		yield put(actions.enterTournamentHeatFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* loadInvitationsSaga() {
	try {
		Spinner.show()
		const invitations = yield call([
			tournamentAPI,
			"getAllinvites",
			{ entryStatus: "invited" },
		])
		yield put(actions.setInvitationsAction(invitations.data.rows))
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(actions.loadInvitationsFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* rejectUserTournametSaga(data) {
	try {
		Spinner.show()
		const { userId, tournamentId } = data.payload
		yield call([tournamentAPI, "rejectUser"], tournamentId, userId)
		yield put(actions.rejectTournamentFinishedAction())
		notify({
			type: "success",
			title: i18n.t("notification.rejectUser.title"),
			desc: i18n.t("notification.rejectUser.description"),
		})
	} catch (error) {
		if (error?.response?.data?.errorKey) {
			notify({
				type: "error",
				title: i18n.t(`notification.${error?.response?.data?.errorKey}.title`),
				desc: i18n.t(
					`notification.${error?.response?.data?.errorKey}.description`
				),
			})
		}
		yield put(actions.rejectTournamentFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* rejectInviteSaga(data) {
	try {
		Spinner.show()
		const { tournamentId } = data.payload
		yield call([tournamentAPI, "rejectInvite"], tournamentId)
		yield put(actions.rejectTournamentFinishedAction())
		notify({
			type: "success",
			title: i18n.t("notification.rejectUser.title"),
			desc: i18n.t("notification.rejectUser.description"),
		})
	} catch (error) {
		if (error?.response?.data?.errorKey) {
			notify({
				type: "error",
				title: i18n.t(`notification.${error?.response?.data?.errorKey}.title`),
				desc: i18n.t(
					`notification.${error?.response?.data?.errorKey}.description`
				),
			})
		}
		yield put(actions.rejectInviteFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* loadTracksSaga() {
	try {
		Spinner.show()
		const tracks = yield call([tournamentAPI, "getAllTracks"], {
			trackType: "tournament",
		})
		yield put(actions.setTracksAction(tracks.data.rows))
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(actions.loadTracksFailedAction())
	} finally {
		Spinner.destroy()
	}
}

export default [
	takeLatest(types.LOAD_TRACKS_REQ, loadTracksSaga),
	takeLatest(types.LOAD_TOURNAMENT_HEAT_REQ, loadTournamentHeatSaga),
	takeLatest(types.REJECT_INVITE_REQ, rejectInviteSaga),
	takeLatest(types.ENTER_TOURNAMENT_HEAT_REQ, enterTournamentHeatSaga),
	takeLatest(types.REJECT_USER_REQ, rejectUserTournametSaga),
	takeLatest(types.LOAD_INVITATIONS_REQ, loadInvitationsSaga),
	takeLatest(types.UPDATE_TOURNAMENT_REQ, updateTournamentSaga),
	takeLatest(types.ENTER_TOURNAMENT_REQ, enterTournamentSaga),
	takeLatest(types.INVITE_USER_REQ, inviteUserToTournametSaga),
	takeLatest(types.CANCEL_TOURNAMENT_REQ, cancelTournamentSaga),
	takeLatest(types.CREATE_TOURNAMENT_REQ, createTournamentSaga),
	takeLatest(types.LOAD_TOURNAMENT_REQ, loadTournamentSaga),
	takeLatest(types.LOAD_TOURNAMENTS_REQ, loadTournamentsSaga),
]
