import { createAction } from "@reduxjs/toolkit"
import propertyTypes from "./types"

export const getGasBalanceReqAction = createAction(
	propertyTypes.GET_GAS_BALANCE_REQ
)
export const getGasBalanceSuccAction = createAction(
	propertyTypes.GET_GAS_BALANCE_SUCC
)
export const getGasBalanceFailAction = createAction(
	propertyTypes.GET_GAS_BALANCE_FAIL
)
