import { createSelector } from "@reduxjs/toolkit"

const stateSelector = (state) => state.bulletins
const teamSelector = (state) => state.teams

export const bulletinsSelector = createSelector(
	stateSelector,
	(state) => state.entities
)

export const bulletinSelector = createSelector(
	stateSelector,
	(state) => state.entity
)

export const bulletinIdSelector = createSelector(
	bulletinSelector,
	(bulletin) => bulletin?.id || null
)

export const bulletinMsgSelector = createSelector(
	bulletinSelector,
	(bulletin) => bulletin?.message || null
)

export const teamIdSelector = createSelector(
	teamSelector,
	(team) => team?.id || null
)
