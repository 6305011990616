import { createAction } from "@reduxjs/toolkit"
import walletActionTypes from "./types"

export const getWalletAction = createAction(walletActionTypes.GET_WALLET)
export const getWalletSuccess = createAction(
	walletActionTypes.GET_WALLET_SUCCESS
)
export const getWalletFailure = createAction(
	walletActionTypes.GET_WALLET_FAILED
)

export const getWalletTransactionsAction = createAction(
	walletActionTypes.GET_WALLET_TRANSACTIONS
)
export const getWalletTransactionsSuccess = createAction(
	walletActionTypes.GET_WALLET_TRANSACTIONS_SUCCESS
)
export const getWalletTransactionsFailure = createAction(
	walletActionTypes.GET_WALLET_TRANSACTIONS_FAILED
)

export const initiateWalletTransactionsAction = createAction(
	walletActionTypes.INITIATE_WALLET_TRANSACTIONS
)
export const initiateWalletTransactionsSuccess = createAction(
	walletActionTypes.INITIATE_WALLET_TRANSACTIONS_SUCCESS
)
export const initiateWalletTransactionsFailure = createAction(
	walletActionTypes.INITIATE_WALLET_TRANSACTIONS_FAILED
)
