import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"

import { useLocation, useHistory } from "react-router-dom"
import classNames from "classnames"
import { getUserMenu } from "constants/routes"
import { getUsernameSelector, isAuthorizedSelector } from "store/auth/selectors"
import { logoutAction } from "store/auth/actions"

import { Menu } from "antd"
import IconPlus from "components/commons/Icons/IconPlus"
import IconMinus from "components/commons/Icons/IconMinus"

import "./styles.scss"

const { SubMenu } = Menu

const rootSubmenuKeys = ["sub1", "sub2", "sub4"]
const SideNavbar = () => {
	const { t, i18n } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()
	const isAuthorized = useSelector(isAuthorizedSelector)

	const raceRoutes = [
		{ title: t("race.races"), route: "/race" },
		{ title: t("race.pendingRaces"), route: "/race/pendingraces" },
		{ title: t("teams"), route: "/teams" },
		{ title: t("race.results"), route: "/race/results" },
		{ title: t("race.leaderboards"), route: "/race" },
	]
	const [openKeys, setOpenKeys] = useState(["sub1"])
	const username = useSelector(getUsernameSelector)
	const { pathname } = useLocation()

	const handleLogout = () => {
		dispatch(logoutAction())
		history.push("/")
	}

	const userMenus = useMemo(
		() => getUserMenu(username),
		[i18n.language, username]
	)

	const onOpenChange = (keys) => {
		const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
		if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			setOpenKeys(keys)
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
		}
	}

	const subRouteClassName = (route) =>
		classNames({
			"text-aqua": pathname.indexOf(route) >= 0 || openKeys.includes(),
			"text-darkGrey": pathname.indexOf(route) <= -1,
		})
	return (
		<Menu
			mode="inline"
			openKeys={openKeys}
			onOpenChange={onOpenChange}
			className="SideNavbar"
		>
			<Menu.Item
				style={{ background: "transparent" }}
				expandIcon={<IconPlus fill="#ababab" />}
				onClick={() => history.push("/marketplace")}
			>
				<span
					className={classNames({
						"text-darkGrey": pathname !== "/marketplace",
						"text-aqua": pathname === "/marketplace",
					})}
				>
					{t("menu.marketplace")}
				</span>
			</Menu.Item>

			{isAuthorized && (
				<SubMenu
					key="sub2"
					expandIcon={
						!openKeys.includes("sub2") ? (
							<IconPlus fill="#ababab" />
						) : (
							<IconMinus fill="#01dac5" />
						)
					}
					title={
						<h1 className={subRouteClassName("/dashboard")}>
							{t("menu.myAccount")}
						</h1>
					}
				>
					<div className="bg-black">
						{userMenus.map(({ route, title }) => (
							<Menu.Item
								className={classNames("menu-item", {
									active: route === pathname,
								})}
								key={`nav_user_${title}`}
								onClick={() => history.push(route)}
							>
								<span className="menu-item-text">{title}</span>
							</Menu.Item>
						))}
					</div>
				</SubMenu>
			)}
			<Menu.Item
				style={{ background: "transparent" }}
				expandIcon={<IconPlus fill="#ababab" />}
				onClick={() => history.push("/learn")}
			>
				<span
					className={classNames({
						"text-darkGrey": pathname !== "/learn",
						"text-aqua": pathname === "/learn",
					})}
				>
					{t("menu.learn")}
				</span>
			</Menu.Item>
			<SubMenu
				key="sub4"
				title={<h1 className={subRouteClassName("/race")}>{t("race.race")}</h1>}
				expandIcon={
					!openKeys.includes("sub4") ? (
						<IconPlus fill="#ababab" />
					) : (
						<IconMinus fill="#01dac5" />
					)
				}
			>
				<div className="bg-black">
					{raceRoutes.map(({ route, title }) => (
						<Menu.Item
							className={classNames("menu-item", {
								active: route === pathname,
							})}
							key={`nav_user_${title}`}
							onClick={() => history.push(route)}
						>
							<span className="menu-item-text">{title}</span>
						</Menu.Item>
					))}
				</div>
			</SubMenu>

			<Menu.Item
				style={{ background: "transparent" }}
				expandIcon={<IconPlus fill="#ababab" />}
				onClick={() => history.push("/rent")}
			>
				<span
					className={classNames({
						"text-darkGrey": pathname !== "/rent",
						"text-aqua": pathname === "/rent",
					})}
				>
					{t("menu.rent")}
				</span>
			</Menu.Item>

			<Menu.Item style={{ background: "transparent" }}>
				<a
					href="https://whitepaper.riotracers.com/"
					target="_blank"
					rel="noreferrer"
				>
					<span className="text-darkGrey">{t("whitePaper")}</span>
				</a>
			</Menu.Item>
			<Menu.Item
				style={{ background: "transparent" }}
				expandIcon={<IconPlus fill="#ababab" />}
				onClick={handleLogout}
			>
				<span className="text-darkGrey">{t("logout")}</span>
			</Menu.Item>
		</Menu>
	)
}
export default SideNavbar
