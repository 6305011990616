import { createReducer } from "@reduxjs/toolkit"
import discordActionTypes from "./types"

const initialState = {
	errorUnlinking: null,
	errorSyncingRoles: null,
	errorCb: null,
	cbLoading: null,
}

const discordReducer = createReducer(initialState, {
	[discordActionTypes.UNLINK_DISCORD_SUCCESS]: (state) => {
		state.errorUnlinking = null
	},

	[discordActionTypes.UNLINK_DISCORD_FAILURE]: (state, { payload }) => {
		state.errorUnlinking = payload
	},

	[discordActionTypes.SYNC_DISCORD_ROLES_SUCCESS]: (state) => {
		state.errorSyncingRoles = null
	},

	[discordActionTypes.SYNC_DISCORD_ROLES_FAILURE]: (state, { payload }) => {
		state.errorSyncingRoles = payload
	},

	[discordActionTypes.REDIRECT_DISCORD_CALLBACK_SUCCESS]: (state) => {
		state.errorCb = null
	},

	[discordActionTypes.REDIRECT_DISCORD_CALLBACK_FAILURE]: (
		state,
		{ payload }
	) => {
		state.errorCb = payload
	},

	[discordActionTypes.REDIRECT_DISCORD_CALLBACK_LOADING]: (
		state,
		{ payload }
	) => {
		state.cbLoading = payload
	},
})

export default discordReducer
