import { put, call, takeLatest } from "redux-saga/effects"
import request from "api/request"
import notify from "components/commons/notification"
import { getDriverSuccessAction, getDriverFailureAction } from "./actions"
import { GET_DRIVER } from "./types"

function getDriverRequest(driverId, saleStatus) {
	return request({
		path: `assets/driver/c/${driverId}`,
		opts: {
			params: { saleStatus },
			method: "GET",
		},
	})
}

function* getDriverSaga({ payload }) {
	try {
		const result = yield call(
			getDriverRequest,
			payload.driverId,
			payload.saleStatus
		)
		yield put(getDriverSuccessAction(result.data))
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(getDriverFailureAction(err))
	}
}

const driverSaga = [takeLatest(GET_DRIVER, getDriverSaga)]
export default driverSaga
