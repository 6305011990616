import { createAction } from "@reduxjs/toolkit"
import assetUpgradeTypes from "./types"

export const getUpgrades = createAction(assetUpgradeTypes.GET_UPGRADES)
export const getUpgradesSuccess = createAction(
	assetUpgradeTypes.GET_UPGRADES_SUCCESS
)
export const getUpgradesFailed = createAction(
	assetUpgradeTypes.GET_UPGRADES_FAILED
)

export const getUpgradesCost = createAction(assetUpgradeTypes.GET_UPGRADES_COST)
export const getUpgradesCostSuccess = createAction(
	assetUpgradeTypes.GET_UPGRADES_COST_SUCCESS
)
export const getUpgradesCostFailed = createAction(
	assetUpgradeTypes.GET_UPGRADES_COST_FAILED
)

export const initiateUpgrades = createAction(
	assetUpgradeTypes.INITIATE_UPGRADES
)
export const initiateUpgradesSuccess = createAction(
	assetUpgradeTypes.INITIATE_UPGRADES_SUCCESS
)
export const initiateUpgradesFailed = createAction(
	assetUpgradeTypes.INITIATE_UPGRADES_FAILED
)
