import { put, call, takeLatest } from "redux-saga/effects"
import request from "api/request"
import notify from "components/commons/notification"
import { getDropLimitsSuccess, getDropLimitsFailure } from "./actions"
import { GET_DROPLIMITS } from "./types"

function getDropLimitsApiRequest() {
  return request({
    path: `droplimits/all`,
    opts: {
      method: "GET",
    },
  })
}

function* getDropLimitsSaga({ payload }) {
  try {
    const result = yield call(getDropLimitsApiRequest, payload)
    yield put(getDropLimitsSuccess(result.data))
  } catch (err) {
    if (err.response) {
      notify({
        title: "Error",
        desc: err.response.data.message,
      })
    }
    yield put(getDropLimitsFailure(err))
  }
}

const droplimitsSaga = [takeLatest(GET_DROPLIMITS, getDropLimitsSaga)]
export default droplimitsSaga
