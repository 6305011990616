const GroupActionTypes = {
	GET_GROUP_STATUS: "[group] get group status request",
	GET_GROUP_STATUS_FAILED: "[group] get group status failed",
	REGISTER_GROUP: "[group] register group request",
	REGISTER_GROUP_FAILED: "[group] register group failed",
	SET_GROUP: "[group] set group data",
	GROUP_REGISTER_LOADING: "[group] register loading",
}

export default GroupActionTypes
