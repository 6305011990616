import { lazy } from "react"

const Home = lazy(() => import("views/Home"))
// const Theshop = lazy(() => import("views/Theshop"))
const Theworld = lazy(() => import("views/Theworld"))
const Race = lazy(() => import("views/Race"))
const MyAccount = lazy(() => import("views/MyAccount"))
const Marketplace = lazy(() => import("views/Marketplace"))
const ApiExplorer = lazy(() => import("views/ApiExplorer"))
const AssetDetail = lazy(() => import("views/AssetDetail"))
const PrivacyPolicy = lazy(() => import("views/PrivacyPolicy"))
const TermsOfService = lazy(() => import("views/TermsOfService"))
const Discord = lazy(() => import("views/Discord"))
const NotFound = lazy(() => import("views/NotFound"))
const Accounts = lazy(() => import("views/Accounts"))
const TeamManager = lazy(() => import("views/TeamManager"))
const Learn = lazy(() => import("views/Learn"))
const Drivers = lazy(() => import("views/Drivers"))
const AboutUs = lazy(() => import("views/AboutUs"))
const RaceReplay = lazy(() => import("views/RaceReplay"))
const Rent = lazy(() => import("views/Rent/Rent"))
const Tournament = lazy(() => import("views/Tournament"))

const appRoutes = [
	{
		path: "/",
		component: Home,
		isPrivate: false,
		exact: true,
	},
	// {
	//   path: "/shop",
	//   component: Theshop,
	//   isPrivate: false,
	//   exact: true,
	// },
	{
		path: "/world",
		component: Theworld,
		isPrivate: false,
		exact: false,
	},
	{
		path: "/race/replay/:raceId",
		component: RaceReplay,
		isPrivate: false,
		exact: false,
	},
	{
		path: "/race",
		component: Race,
		isPrivate: false,
		exact: false,
	},
	{
		path: "/dashboard",
		component: MyAccount,
		isPrivate: true,
		exact: false,
	},
	{
		path: "/marketplace",
		component: Marketplace,
		isPrivate: false,
		exact: false,
	},
	{
		path: "/rent",
		component: Rent,
		isPrivate: false,
		exact: false,
	},
	{
		path: "/detail/:type/:id",
		component: AssetDetail,
		isPrivate: false,
		exact: false,
	},
	{
		path: "/rr/:username",
		component: Accounts,
		isPrivate: false,
		exact: true,
	},
	{
		path: "/teams",
		component: TeamManager,
		isPrivate: false,
		exact: false,
	},
	{
		path: "/tournaments",
		component: Tournament,
		isPrivate: false,
		exact: false,
	},
	{
		path: "/privacy-policy",
		component: PrivacyPolicy,
		isPrivate: false,
		exact: false,
	},
	{
		path: "/terms-of-service",
		component: TermsOfService,
		isPrivate: false,
		exact: false,
	},
	{
		path: "/discord",
		component: Discord,
		isPrivate: false,
		exact: false,
	},
	{
		path: "/discord/auth",
		component: Discord,
		isPrivate: false,
		exact: false,
	},
	{
		path: "/learn",
		component: Learn,
		isPrivate: false,
		exact: true,
	},
	{
		path: "/drivers",
		component: Drivers,
		isPrivate: false,
		exact: true,
	},
	{
		path: "/about-us",
		component: AboutUs,
		isPrivate: false,
		exact: true,
	},
	{
		path: "*",
		component: NotFound,
		isPrivate: false,
		exact: false,
	},
	{
		path: "/api-playground",
		component: ApiExplorer,
		isPrivate: true,
		exact: true,
	},
]

export default appRoutes
