import { createSelector } from "@reduxjs/toolkit"

const walletStateSelector = (state) => state.wallet

export const balanceSelector = createSelector(
	walletStateSelector,
	(state) => state.wallet.balance
)

export const selectWalletTransactions = createSelector(
	walletStateSelector,
	(state) => state.transactions
)

export const initiateWalletTransSelector = createSelector(
	walletStateSelector,
	(state) => state.initiate
)
