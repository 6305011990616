import React, { useMemo } from "react"
import { Header, Footer } from "components/commons"
import AuthModal from "components/commons/AuthModal"
import useTopNoticeVisible from "hooks/useTopNoticeVisible"

import "./styles.scss"

const DefaultLayout = ({ children, className, noFooter }) => {
	const topNoticeVisible = useTopNoticeVisible()

	const classes = useMemo(() => {
		let base = "RioPage"
		if (className) {
			base = `${base} RioDashboardContainer ${className}`
		}
		if (topNoticeVisible) {
			return `${base} RioDashboardContainer TopBannerShown`
		}
		return base
	}, [topNoticeVisible])

	return (
		<div className={classes}>
			<Header />
			<div className="PageContent">{children}</div>
			{!noFooter && <Footer />}
			<AuthModal />
		</div>
	)
}

export default DefaultLayout
