/* eslint no-await-in-loop: "off" */
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Web3 from "web3"
import {
	logoutAction,
	authenticateReqAction,
	authenticateFailAction,
} from "store/auth/actions"
import { closeModalAction } from "store/modal/actions"
import { getBalanceReqAction, getBalanceSuccAction } from "store/coin/actions"
import { authStateSelector } from "store/auth/selectors"
import notify from "components/commons/notification"
import { providers, abi } from "../constants/providers"
import { has, assign, chain } from "lodash"
import { challengeApi } from "api/users"

const welcomeMetaMask =
	'Welcome to Riot Racers!\n\nClick "Sign" to sign in. No password needed!\n\nI accept the Riot Racers Terms of Service: https://riot.fun/terms-of-service'

function randomHex(length) {
	const arr = new Uint8Array((length || 40) / 2)
	window.crypto.getRandomValues(arr)
	return Array.from(arr, (dec) => dec.toString(16).padStart(2, "0")).join("")
}

function useMetaMaskAuth(onMetaMaskNotInstalled) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const authState = useSelector(authStateSelector)
	const [accounts, setAccounts] = useState(null)

	useEffect(() => {
		if (accounts) {
			dispatch(logoutAction())
		}
	}, [accounts])

	const findAndCreateOnSuccess = (ethereum, publicAddress, email) => {
		const clientSeed = randomHex(40)
		challengeApi(publicAddress, clientSeed).then((result) => {
			const { challengeText, id } = result.challenge
			const metaMaskMessage = `${welcomeMetaMask}\n${challengeText}\n${clientSeed}`
			ethereum.eth.personal
				.sign(metaMaskMessage, publicAddress, "")
				.then((signature) =>
					dispatch(
						authenticateReqAction({
							publicAddress,
							signature,
							email,
							history,
							clientSeed,
							challengeId: id,
						})
					)
				)
				.catch((err) => {
					dispatch(closeModalAction())
					dispatch(authenticateFailAction(err))
				})
		})
	}

	const initInstance = async (auth = false) => {
		if (typeof window.starzwallet !== "undefined") {
			const web3 = new Web3(window.starzwallet)
			if (auth) await web3.eth.requestAccounts()
			return web3
		}
		if (window.ethereum) {
			if (auth) await window.ethereum.enable()
			window.ethereum.on("accountsChanged", setAccounts)
			return new Web3(window.ethereum)
		} else if (window.web3) {
			return new Web3(window.currentProvider)
		} else if (typeof onMetaMaskNotInstalled === "function") {
			onMetaMaskNotInstalled()
		}
		return null
	}

	const web3Instance = () => {
		if (typeof window.starzwallet !== "undefined") {
			return new Web3(window.web3.currentProvider)
		}
		if (window.ethereum) {
			return new Web3(window.ethereum)
		} else if (window.web3) {
			return new Web3(window.currentProvider)
		}
		return null
	}

	const handleAuth = async () => {
		const ethereum = await initInstance(true)
		if (!ethereum) return
		const publicAddress =
			ethereum.eth.currentProvider.selectedAddress.toLowerCase()

		if (!authState.isLoggedIn) {
			findAndCreateOnSuccess(ethereum, publicAddress, null)
		} else if (
			authState.isLoggedIn &&
			(!authState.user.publicAddress ||
				authState.user.publicAddress === publicAddress)
		) {
			findAndCreateOnSuccess(ethereum, publicAddress, authState.user.email)
		} else {
			notify({ title: t("addressNotAssociated") })
		}
	}

	const getTokenBalance = ({
		ethereum,
		address,
		contractAddress,
		network,
		symbol,
	}) => {
		const tokenContract = new ethereum.eth.Contract(abi, contractAddress)

		return tokenContract.methods
			.balanceOf(address)
			?.call()
			.then((value) => ({
				[symbol]: ["usdt", "usdc"].includes(symbol)
					? value / 1e6
					: ethereum.utils.fromWei(value),
				network,
			}))
			.catch(() => null)
	}

	const getBalance = async (publicAddress = null) => {
		const ethereum = await initInstance()
		if (!ethereum) return

		dispatch(getBalanceReqAction())
		const address =
			publicAddress ||
			authState?.user?.publicAddress ||
			window?.ethereum?.selectedAddress

		if (!address) return

		const promises = []

		providers.forEach(({ network, rpcURL, tokens }) => {
			ethereum.eth.setProvider(new Web3.providers.HttpProvider(rpcURL))

			const swapBalances = tokens.map((token) =>
				getTokenBalance({
					ethereum,
					address,
					network,
					...token,
				})
			)

			const ethBalance = ethereum.eth
				.getBalance(address)
				.then((value) => ({
					eth: ethereum.utils.fromWei(value),
					network,
				}))
				.catch(() => null)

			promises.push(...[ethBalance, ...swapBalances])
		})

		const balances = await Promise.all(promises)

		const coinBalances = balances.reduce((sum, balance) => {
			if (!balance) return sum
			const { network, ...valueObj } = balance
			if (has(sum, network)) assign(sum[network], valueObj)
			else sum[network] = valueObj
			return sum
		}, {})

		dispatch(getBalanceSuccAction(coinBalances))
	}

	const getNetworkStatus = async () => {
		const ethereum = await web3Instance()
		if (!ethereum) return false
		return ethereum.eth.getChainId()
	}

	const payNow = async (
		value,
		success,
		error,
		started = null,
		sym = "riot"
	) => {
		try {
			const ethereum = await initInstance()
			if (!ethereum) return

			let chosenProviderUrl = ""
			let transactionSymbol = ""

			ethereum.eth.getAccounts().then(async (ethAccounts) => {
				const toAddress = process.env.REACT_APP_COMMUNITY_WALLET_ADDRESS
				const chainId = await getNetworkStatus()

				const fromAddress = ethAccounts[0]

				if (!fromAddress) {
					return notify({
						title: "Invalid Network",
						desc: "Please change to Ethereum or Polygon network",
					})
				}

				let amount = web3Instance().utils.toWei(`${value}`, "ether")
				if (sym === "usdc") {
					value *= 1e6
					amount = value
				}

				const latestBlock = await ethereum.eth.getBlock("latest")
				const gasEstimate = latestBlock?.baseFeePerGas

				const tx = {
					from: fromAddress,
					gasEstimate,
				}

				if (sym !== "eth" || (sym === "eth" && chainId === 137)) {
					const chosenContractAddress = providers.reduce(
						(tokenAddress, provider) => {
							if (provider.chainId === chainId) {
								chosenProviderUrl = provider.baseTransactionUrl
								const foundProvider = provider.tokens.find(
									({ symbol }) => symbol === sym
								)
								transactionSymbol = foundProvider?.symbol
								return foundProvider?.contractAddress
							}
							return tokenAddress
						},
						null
					)

					if (!chosenContractAddress) {
						return notify({
							title: "Invalid Network",
							desc: "Please change to Ethereum or Polygon network",
						})
					}

					const tokenContract = new ethereum.eth.Contract(
						abi,
						chosenContractAddress,
						{ from: fromAddress, to: toAddress }
					)

					// tx.gasEstimate = await tokenContract.methods
					// .transfer(toAddress, amount)
					// .estimateGas({ from: fromAddress })

					tx.to = tokenContract._address
					tx.data = tokenContract.methods
						.transfer(toAddress, amount)
						.encodeABI()
				} else {
					// tx.gasEstimate = await ethereum.eth.estimateGas({from: fromAddress})
					tx.to = toAddress
					tx.value = amount
				}
				return ethereum.eth
					.sendTransaction(tx)
					.on("transactionHash", (hash) => started(hash, chosenProviderUrl))
					.then(success)
					.catch(error)
			})
		} catch (err) {
			console.error(err)
			notify({ type: "error", title: t("transactionFailed") })
		}
	}

	return {
		handleAuth,
		getBalance,
		payNow,
		getNetworkStatus,
		publicAddress: authState.isMetaMaskLoggedIn
			? window?.ethereum?.selectedAddress
			: null,
	}
}

const web3Instance = () => {
	if (window.ethereum) {
		return new Web3(window.ethereum)
	} else if (window.web3) {
		return new Web3(window.currentProvider)
	}
}

export const getNetworkStatus = async () => {
	const ethereum = await web3Instance()
	if (!ethereum) return false
	const chainId = await ethereum.eth.getChainId()
	return chainId === 1
}

export default useMetaMaskAuth
