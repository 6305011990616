export const getFileExt = (filename) => {
	const ext = /^.+\.([^.]+)$/.exec(filename)
	return ext == null ? "" : ext[1]
}

export const getImageDimensions = (file) => {
	const img = document.createElement("img")
	const objectUrl = URL.createObjectURL(file)
	const promise = new Promise((resolve, reject) => {
		img.onload = () => {
			const width = img.naturalWidth
			const height = img.naturalHeight

			resolve({ width, height })
		}
		img.onerror = reject
	})

	img.src = objectUrl
	return promise
}
