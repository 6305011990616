export default ({ fill = "#ababab", ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="16.035"
		viewBox="0 0 24 16.035"
		{...props}
	>
		<g id="noun_racer_4303634" transform="translate(-5.223 -18.424)">
			<path
				id="Path_1347"
				data-name="Path 1347"
				d="M8.981,52.043,7,52.839c-.035.018-.065.036-.1.055a5.889,5.889,0,0,1,.988,3.265H7.531a5.236,5.236,0,0,0-1.4-2.532,2.694,2.694,0,0,0-.145.3l-.729,2.183c-.152.654.168.9.461.992a42.026,42.026,0,0,0,7.21.839v0h0a41.941,41.941,0,0,0,7.209-.839c.3-.091.616-.338.464-.992l-.729-2.183a2.9,2.9,0,0,0-.145-.3,5.236,5.236,0,0,0-1.4,2.532h-.363a5.88,5.88,0,0,1,.99-3.265c-.033-.019-.063-.036-.1-.055l-1.98-.8h-.052c-.087.1-.174.192-.267.281a5.2,5.2,0,0,1-7.27,0c-.093-.089-.181-.185-.268-.281h-.05Z"
				transform="translate(0 -23.477)"
				fill={fill}
			/>
			<path
				id="Path_1348"
				data-name="Path 1348"
				d="M55.128,32.748c.6,0,.885.167,1,.541a1.084,1.084,0,0,1-.962,1.383H54.19c-.7,0-.874-.472-.762-.807A2.135,2.135,0,0,1,55.128,32.748Zm-5.6-5.98c-.611,0-1.268,0-1.92.024a5.814,5.814,0,0,1,.2.792c.582-.019,1.169-.033,1.717-.034,1.119,0,2.327.041,3.351.11a1.248,1.248,0,0,1,.982.624,11.869,11.869,0,0,1,1.257,2.481s-3.208.32-5.59.32c-.545,0-1.4-.019-1.961-.045a9.943,9.943,0,0,1-.858,2.089h5.8c.253,0,.309.294.193.577a1.67,1.67,0,0,1-1.52,1h-2.4a3.043,3.043,0,0,1,.911,1.245l.547,1.636h3.934v1.332a.494.494,0,0,0,.5.495h1.4a.492.492,0,0,0,.494-.495v-1.4a1.192,1.192,0,0,0,.751-1.081V33.442a2.629,2.629,0,0,0-.35-1.16l.259-.061c.679-.157.82-.385.944-.847a.569.569,0,0,0-.58-.756H56.03a12.27,12.27,0,0,0-1.369-2.851,1.757,1.757,0,0,0-1.24-.828A28.27,28.27,0,0,0,49.527,26.768Z"
				transform="translate(-28.97 -5.827)"
				fill={fill}
				fillRule="evenodd"
			/>
			<path
				id="Path_1349"
				data-name="Path 1349"
				d="M16.387,22.812a.98.98,0,0,1,.438.1,7.372,7.372,0,0,0,2.767.5,7.383,7.383,0,0,0,2.768-.5c.438-.184.781-.159.739.493-.149,2.26-1.541,2.927-3.507,2.946-1.964-.019-3.358-.687-3.507-2.946C16.058,22.995,16.182,22.833,16.387,22.812Zm3.205-4.388c-3.243,0-5.668,2.358-4.563,6.324.783,2.811,2.37,4.4,4.563,4.4s3.782-1.587,4.564-4.4C25.261,20.782,22.835,18.424,19.592,18.424Z"
				transform="translate(-6.659)"
				fill={fill}
				fillRule="evenodd"
			/>
		</g>
	</svg>
)
