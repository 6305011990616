import { useSelector, useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { IPAD_LANDSCAPE } from "constants/breakPoints"

import { openModalAction } from "store/modal/actions"
import { logoutAction } from "store/auth/actions"
import { authStateSelector } from "store/auth/selectors"
import { web3ModalConnect } from "store/web3Modal/actions"
import useMetaMaskAuth from "hooks/useMetaMaskAuth"
import useWindowDimensions from "hooks/useWindowDimensions"

import DesktopHeader from "./DesktopHeader"
import MobileHeader from "./MobileHeader"
import BillboardBanner from "components/BillboardBanner"

function Header() {
	const dispatch = useDispatch()
	const history = useHistory()
	const authState = useSelector(authStateSelector)
	const { handleAuth } = useMetaMaskAuth(onMetaMaskNotInstalled)
	const { width } = useWindowDimensions()
	const isSmallDevice = width <= IPAD_LANDSCAPE
	const location = useLocation()

	const showBillboardBanner = () => {
		if (location.pathname === "/" || location.pathname.indexOf("/rr/") > -1)
			return false
		return !(location.pathname.indexOf("/dashboard/") > -1)
	}

	function onMetaMaskNotInstalled() {
		dispatch(openModalAction({ type: "authModal", step: 3 }))
	}

	const OpenModal = () => {
		dispatch(web3ModalConnect(history))
	}

	const handleLogout = () => {
		dispatch(logoutAction())
		history.push("/")
	}

	return (
		<div>
			{isSmallDevice ? (
				<MobileHeader {...{ authState, handleLogout, OpenModal, handleAuth }} />
			) : (
				<DesktopHeader
					{...{ authState, handleLogout, OpenModal, handleAuth }}
				/>
			)}
			{showBillboardBanner() && <BillboardBanner />}
		</div>
	)
}

export default Header
