import { createAction } from "@reduxjs/toolkit"
import leaderboardActionTypes from "./types"

export const getLeaderboardsAction = createAction(
	leaderboardActionTypes.GET_LEADERBOARDS
)
export const getLeaderboardsSuccAction = createAction(
	leaderboardActionTypes.GET_LEADERBOARDS_SUCCESS
)
export const getLeaderboardsFailAction = createAction(
	leaderboardActionTypes.GET_LEADERBOARDS_FAILED
)
