import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { Badge, Popover, Tooltip } from "antd"
import { cartTotalCountSelector } from "store/marketplace/selectors"
import { getCartAction } from "store/marketplace/actions"
import IconOrders from "components/commons/Icons/IconOrders"

const CartButton = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { totalCount } = useSelector(cartTotalCountSelector)

	const handleCartClick = () => {
		history.push("/marketplace/orders")
	}

	return (
		<Badge
			style={{
				backgroundColor: "#03DAC5",
				color: "#000",
				boxShadow: "none",
			}}
		>
			<div
				className="cartWrapp"
				style={{ marginRight: "20px", marginLeft: "25px" }}
			>
				<Tooltip placement="bottom" title={t("menu.orders")}>
					<IconOrders className="button-icon" onClick={handleCartClick} />
				</Tooltip>
			</div>
		</Badge>
	)
}

export default CartButton
