import { createSelector } from "@reduxjs/toolkit"

const web3StateSelector = (state) => state.web3Modal

export const selectWeb3Modal = createSelector(
	web3StateSelector,
	(state) => state.web3Modal
)

export const selectWeb3Provider = createSelector(
	web3StateSelector,
	(state) => state.provider
)
