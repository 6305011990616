import dayjs from "dayjs"
import { createSelector } from "@reduxjs/toolkit"

const stateSelector = (state) => state.group

export const isLoadedGroupSelector = createSelector(
	stateSelector,
	(state) => state.loaded
)

export const groupDetailSelector = createSelector(
	stateSelector,
	(state) => state.detail || {}
)

export const selectGroupRegisterLoader = createSelector(
	stateSelector,
	(state) => state.groupRegisteringLoading
)

export const validGroupSelector = createSelector(
	groupDetailSelector,
	(state) => {
		if (!Array.isArray(state)) return false

		let valid = false
		state.forEach((drop) => {
			if (drop.groupStart !== null && drop.groupEnd !== null) {
				if (
					dayjs
						.utc()
						.isBetween(
							dayjs.utc(drop.groupStart),
							dayjs.utc(drop.groupEnd),
							"second"
						)
				) {
					valid = true
				}
			}
		})
		return valid
	}
)
