import { put, call, takeLatest } from "redux-saga/effects"
import notify from "components/commons/notification"
import * as actions from "./actions"
import * as api from "api/races"
import * as types from "./types"

function* getRacesSaga({ payload: { params } }) {
	try {
		params.completed = false
		params.omitEntry = true
		const result = yield call(api.getRacesApi, params)
		yield put(
			actions.getRacesSuccessAction({
				data: result.data,
			})
		)
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(actions.getRacesFailureAction(err))
	}
}

function* getShardsSaga() {
	try {
		const result = yield call(api.getShardsApi)
		yield put(
			actions.getShardsSuccessAction({
				data: result.data,
			})
		)
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(actions.getShardsFailureAction(err))
	}
}

function* getResultsSaga({ payload: { params } }) {
	try {
		params.completed = true
		params.omitEntry = false
		const result = yield call(api.getRacesApi, params)
		yield put(
			actions.getResultsSuccessAction({
				data: result.data,
			})
		)
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(actions.getResultsFailureAction(err))
	}
}

function* getWinningsSaga() {
	try {
		const result = yield call(api.getMyRacesApi)
		yield put(
			actions.getWinningsSuccessAction({
				data: result.data,
			})
		)
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(actions.getWinningsFailureAction(err))
	}
}

function* loadRaceSaga({ payload: { params } }) {
	try {
		const result = yield call(api.loadRace, params)
		yield put(
			actions.loadRaceSuccessAction({
				data: result.data,
			})
		)
	} catch (err) {
		console.error("loadRaceSaga error:", err)
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(actions.loadRaceFailedAction(err))
	}
}

function* enterRaceSaga({ payload: { params } }) {
	try {
		const result = yield call(api.enterRaceApi, params)
		yield put(
			actions.enterRaceSuccessAction({
				data: result.data,
			})
		)
	} catch (err) {
		if (
			err.response &&
			err?.response?.data?.errorKey !== "reCaptchaV3Failed" &&
			err?.response?.data?.errorKey !== "reCaptchaResubmit"
		) {
			notify({
				title: "Error",
				messageKey: err.response.data.errorKey,
			})
		}
		yield put(actions.enterRaceFailureAction(err?.response?.data))
	}
}

function* exitRaceSaga({ payload: { params } }) {
	try {
		const result = yield call(api.exitRaceApi, params)
		yield put(
			actions.exitRaceSuccessAction({
				data: result.data,
			})
		)
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
				messageKey: err.response.data.errorKey,
			})
			yield put(actions.exitRaceFailureAction(err))
		}
	}
}

function* updateRaceSaga({ payload: { params } }) {
	try {
		const result = yield call(api.updateRaceApi, params)
		yield put(
			actions.updateRaceSuccessAction({
				data: result.data,
			})
		)
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
				messageKey: err.response.data.errorKey,
			})
			yield put(actions.updateRaceFailureAction(err))
		}
	}
}

function* getMyRacesSaga({ payload: { params } }) {
	try {
		const result = yield call(api.getMyRacesApi, params)
		yield put(
			actions.getMyRacesSuccessAction({
				data: result.data,
			})
		)
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
				messageKey: err.response.data.errorKey,
			})
			yield put(actions.getMyRacesFailureAction(err))
		}
	}
}

function* getMyWinningsTotalSaga() {
	try {
		const { data } = yield call(api.getMyWinningsTotalApi)
		yield put(actions.getMyWinningsSuccessAction(data))
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
				messageKey: err.response.data.errorKey,
			})
			yield put(actions.getMyWinningsFailureAction(err))
		}
	}
}

function* fetchStrategies() {
	try {
		yield put(actions.fetchMyStrategiesLoading(true))

		const { data } = yield call(api.getMyStrategies)
		yield put(actions.fetchMyStrategiesSuccess(data.strategies))
	} catch (err) {
		yield put(actions.fetchMyStrategiesFailed(err))
	} finally {
		yield put(actions.fetchMyStrategiesLoading(false))
	}
}

function* fetchEntries({ payload: { params } }) {
	try {
		yield put(actions.fetchMyEntriesLoading(true))
		const { data } = yield call(api.getMyEntries, params)
		yield put(actions.fetchMyEntriesSuccess(data))
	} catch (err) {
		yield put(actions.fetchMyEntriesFailed(err))
	} finally {
		yield put(actions.fetchMyEntriesLoading(false))
	}
}

/**
 *
 * @param {
 *   type: string,
 *   payload: {
 *     name: String,
 *     carId: Number,
 *     driverId: Number,
 *     strategyPosture: String,
 *     strategyEngine: String,
 *     strategyLine: String,
 *     strategyBraking: String,
 *     strategyAcceleration: String,
 *     strategyTire: String
 *   }
 *  } action
 */
function* saveNewStrategyInit({ payload }) {
	try {
		const tempId = Math.random() * 1000 + 89
		yield put(actions.saveNewStrategyLoading(true))
		const { data } = yield call(api.saveStrategy, payload)
		const newStrategy = { ...data, id: tempId }
		yield put(actions.saveNewStrategySuccess(newStrategy))
		notify({
			type: "success",
			title: "New strategy created.",
		})
		yield put(actions.openStrategyModal(false))
	} catch (err) {
		yield put(actions.saveNewStrategyFailed(err))
		notify({
			title: "Error",
			desc: "Unable to save strategy",
		})
	} finally {
		yield put(actions.saveNewStrategyLoading(false))
	}
}

function* deleteStrategy({ payload: strategyId }) {
	try {
		yield call(api.deleteStrategy, strategyId)
		yield put(actions.deleteStrategySuccess(strategyId))
	} catch (err) {
		yield put(deleteStrategy(err))
	}
}

/**
 *
 * @param {
 *  type: String,
 *  payload: Number
 * } action
 */
function* fetchUserEntryInit({ payload }) {
	try {
		const { data } = yield api.fetchUserRaceEntry(payload)
		yield put(actions.fetchUserRaceEntrySuccess(data))
	} catch (err) {
		yield put(actions.fetchUserRaceEntryFail(err))
	}
}

/**
 *
 * @param {
 *  type: String,
 *  payload: Number
 * } action
 */
function* fetchRaceEntryInit({ payload }) {
	try {
		const { data } = yield api.fetchRaceEntry(payload)
		yield put(actions.fetchRaceEntrySuccess(data))
	} catch (err) {
		yield put(actions.fetchRaceEntryFail(err))
	}
}

/**
 *
 * @param {
 *  type: string,
 *  payload(raceId): Number
 * }
 */
function* fetchRaceEntryStatusInit({ payload }) {
	try {
		const { data } = yield api.fetchRaceEntryStatus(payload)
		yield put(actions.fetchRaceEntryStatusSuccess(data))
	} catch (err) {
		yield put(actions.fetchRaceEntryStatusFail(err))
	}
}

const racesSaga = [
	takeLatest(types.GET_RACES, getRacesSaga),
	takeLatest(types.GET_SHARDS, getShardsSaga),
	takeLatest(types.GET_WINNINGS, getWinningsSaga),
	takeLatest(types.GET_RESULTS, getResultsSaga),
	takeLatest(types.ENTER_RACE, enterRaceSaga),
	takeLatest(types.GET_MY_RACES, getMyRacesSaga),
	takeLatest(types.FETCH_MY_ENTRIES, fetchEntries),
	takeLatest(types.FETCH_MY_STRATEGIES, fetchStrategies),
	takeLatest(types.SAVE_NEW_STRATEGY, saveNewStrategyInit),
	takeLatest(types.EXIT_RACE, exitRaceSaga),
	takeLatest(types.UPDATE_RACE, updateRaceSaga),
	takeLatest(types.FETCH_USER_RACE_ENTRY, fetchUserEntryInit),
	takeLatest(types.FETCH_RACE_ENTRY, fetchRaceEntryInit),
	takeLatest(types.LOAD_RACE, loadRaceSaga),
	takeLatest(types.GET_MY_WINNINGS, getMyWinningsTotalSaga),
	takeLatest(types.FETCH_RACE_ENTRY_STATUS, fetchRaceEntryStatusInit),
	takeLatest(types.DELETE_STRATEGY, deleteStrategy),
]
export default racesSaga
