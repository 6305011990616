import React from "react"

export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 19 13"
		{...props}
	>
		<g id="noun_treasure_2149206" transform="translate(-3 -9)">
			<g id="Group_1354" data-name="Group 1354">
				<path
					id="Path_1350"
					data-name="Path 1350"
					d="M18.5,9a3.505,3.505,0,0,1,3.464,3H10.972A4.5,4.5,0,0,0,9.326,9ZM16,13h1v.5a.5.5,0,0,1-1,0ZM6.5,9a3.5,3.5,0,0,1,3.45,3H3.05A3.5,3.5,0,0,1,6.5,9ZM3,22V20.585A1.5,1.5,0,0,1,4,22Zm2,0a2.5,2.5,0,0,0-2-2.449V13h7v6.56a2.479,2.479,0,0,0-.733.267A2.51,2.51,0,0,0,8,22Zm4.763-1.3A1.46,1.46,0,0,1,10,20.6V22H9a1.509,1.509,0,0,1,.763-1.3ZM11,22V20.585a1.947,1.947,0,0,1,.4.176,1.281,1.281,0,0,1,.576.9A2.14,2.14,0,0,1,12,22Zm1.99,0a3,3,0,0,0-.032-.5A2.256,2.256,0,0,0,11.9,19.9a2.838,2.838,0,0,0-.9-.332V13h4v.5a1.5,1.5,0,0,0,3,0V13h4v6.551A2.5,2.5,0,0,0,20,22ZM22,22H21a1.5,1.5,0,0,1,1-1.415Z"
				/>
			</g>
		</g>
	</svg>
)
