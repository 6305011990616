import { createReducer } from "@reduxjs/toolkit"
import modalActionTypes from "./types"

const initialState = {
	isOpen: false,
	type: null,
	step: null,
}

const modalReducer = createReducer(initialState, {
	[modalActionTypes.OPEN_MODAL]: (state, action) => {
		state.isOpen = true
		state.type = action.payload.type
		state.step = action.payload.step
	},
	[modalActionTypes.CLOSE_MODAL]: (state) => {
		state.isOpen = false
		state.type = null
		state.step = null
	},
})

export default modalReducer
