export const LOAD_TEAM_MEMBERS_REQUEST = "LOAD_TEAM_MEMBERS_REQUEST"
export const LOAD_TEAM_MEMBERS_FAILED = "LOAD_TEAM_MEMBERS_FAILED"

export const LOAD_TEAM_MEMBER_REQUEST = "LOAD_TEAM_MEMBER_REQUEST"
export const LOAD_TEAM_MEMBER_FAILED = "LOAD_TEAM_MEMBER_FAILED"

export const SET_TEAM_MEMBER = "SET_TEAM_MEMBER"
export const SET_TEAM_MEMBERS = "SET_TEAM_MEMBERS"

export const UPDATE_TEAM_MEMBER_REQUEST = "UPDATE_TEAM_MEMBER_REQUEST"
export const UPDATE_TEAM_MEMBER_FAILED = "UPDATE_TEAM_MEMBER_FAILED"

export const ACCEPT_INVITE = "ACCEPT_INVITE"
export const ACCEPT_INVITE_SUCCESS = "ACCEPT_INVITE_SUCCESS"
export const ACCEPT_INVITE_FAILED = "ACCEPT_INVITE_FAILED"

export const REJECT_INVITE = "REJECT_INVITE"
export const REJECT_INVITE_SUCCESS = "REJECT_INVITE_SUCCESS"
export const REJECT_INVITE_FAILED = "REJECT_INVITE_FAILED"

export const CANCEL_INVITE = "CANCEL_INVITE"
export const CANCEL_INVITE_SUCCESS = "CANCEL_INVITE_SUCCESS"
export const CANCEL_INVITE_FAILED = "CANCEL_INVITE_FAILED"

export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST"
export const INVITE_USER_FAILED = "INVITE_USER_FAILED"

export const LEAVE_TEAM_REQUEST = "LEAVE_TEAM_REQUEST"
export const LEAVE_TEAM_FAILED = "LEAVE_TEAM_FAILED"

export const APPLY_TEAM_REQUEST = "APPLY_TEAM_REQUEST"
export const APPLY_TEAM_FAILED = "APPLY_TEAM_FAILED"

export const ACCEPT_TEAM_REQUEST = "ACCEPT_TEAM_REQUEST"
export const ACCEPT_TEAM_FAILED = "ACCEPT_TEAM_FAILED"

export const REJECT_TEAM_REQUEST = "REJECT_TEAM_REQUEST"
export const REJECT_TEAM_FAILED = "REJECT_TEAM_FAILED"

export const REMOVE_MEMBER_REQ = "REMOVE_MEMBER_REQ"
export const REMOVE_MEMBER_FAILED = "REMOVE_MEMBER_FAILED"

export const SET_TEAM_INVITATIONS = "SET_TEAM_INVITATIONS"
export const LOAD_INVITATIONS = "LOAD_INVITATIONS"
export const LOAD_INVITATIONS_FAILED = "LOAD_INVITATIONS_FAILED"
