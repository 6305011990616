import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import RioCard from "./RioCard"
import MediaPlayer from "../Media"
// import RioButton from "../Button"
import RioTextButton from "../Button/TextButton"
import "./StationCard.scss"

const StationCard = ({
	item,
	tokens,
	onSelect,
	onShowDetail,
	customFooter: CustomFooter,
}) => {
	const { t } = useTranslation()
	const { name, assetId, image, animation_url } = item

	const title = useMemo(() => {
		let base = `#${assetId} `
		if (name) {
			base = `${base} - ${name}`
		}
		return base
	}, [name, assetId])
	return (
		<RioCard className="RioStationCard">
			<div className="RioStationCardHead">
				<div className="heading2">
					<span>{title}</span>
				</div>
			</div>
			<div className="RioStationCardBody">
				<MediaPlayer
					url={animation_url || image}
					previewUrl={image || animation_url}
					preview={false}
				/>
			</div>
			{onShowDetail && (
				<div className="RioStationCardFooter">
					{/* <div className="AccruedTokens">
          <span className="subtitle2">{t("accruedTokens")}</span>
          <span className="subtitle2 tokens">
            {tokens} {t("riot")}
          </span>
        </div> */}
					{/* <RioButton size="small" onClick={onSelect}>
          {t("claim")}
        </RioButton> */}
					<RioTextButton text={t("car.showDetails")} onClick={onShowDetail} />
				</div>
			)}
			{CustomFooter && <CustomFooter />}
		</RioCard>
	)
}

export default StationCard
