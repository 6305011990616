import { createAction } from "@reduxjs/toolkit"
import * as types from "./types"

export const web3ModalInit = createAction(types.WEB3_MODAL_INIT)
export const web3ModalInitSuccess = createAction(types.WEB3_MODAL_INIT_SUCCESS)
export const web3ModalConnect = createAction(types.WEB3_MODAL_CONNECT_WALLET)
export const web3ModalConnectSuccess = createAction(
  types.WEB3_MODAL_CONNECT_WALLET_SUCCESS
)
export const web3ModalDisconnect = createAction(types.WEB3_MODAL_DISCONNECT)
