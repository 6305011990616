import request from "./request"

export const getAssets = async (type) => {
	const response = await request({
		path: `assets/${type}/`,
		opts: {
			method: "GET",
		},
	})
	return response.data
}

export const getSelectedCar = async (userId) => {
	const response = await request({
		path: `assets/car/u/${userId}/`,
		opts: {
			method: "GET",
		},
	})
	return response.data
}

export const getCurrentAsset = async (id, type) => {
	const response = await request({
		path: `assets/${type}/c/${id}`,
		opts: {
			method: "GET",
		},
	})
	return response.data
}

export const getAssetsByEth = (eth, type, params) =>
	request({
		path: `assets/${type}/address/${eth}`,
		opts: {
			method: "GET",
			params,
		},
	})

export const getAssetsUsername = async (username) => {
	const response = await request({
		path: `assets/user/${username}`,
		opts: {
			method: "GET",
		},
	})
	return response.data
}

export const checkAssetName = async (name, assetType) => {
	const response = await request({
		path: `assets/${assetType}/checkname/${name}`,
		opts: {
			method: "GET",
		},
	})
	return response.data
}

export const updateAssetName = (name, assetType, assetId) =>
	request({
		path: `assets/${assetType}/assetname/${assetId}`,
		opts: {
			method: "PUT",
			data: {
				name,
			},
		},
	})

export const toggleFavoriteAsset = async (assetType, assetId) => {
	const response = await request({
		path: `assets/${assetType}/f/${assetId}`,
		opts: {
			method: "POST",
		},
	})
	return response.data
}

export const getAssetsApi = (assetType, params) =>
	request({
		path: `assets/${assetType.toLowerCase()}/all`,
		opts: {
			method: "GET",
			params,
		},
	})
