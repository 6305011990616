import { createAction } from "@reduxjs/toolkit"
import ClubActionTypes from "./types"

export const getClubRocLevels = createAction(ClubActionTypes.GET_ROC_LEVELS)
export const enrollClubLevel = createAction(ClubActionTypes.ENROLL_CLUB_LEVEL)
export const claimClubTokens = createAction(ClubActionTypes.CLAIM_REQUEST)
export const setROCLevels = createAction(ClubActionTypes.SET_ROC_LEVELS)
export const fetchLevelsFailed = createAction(ClubActionTypes.GET_LEVELS_FAILED)
export const enrollClubLevelFailed = createAction(
	ClubActionTypes.ENROLL_CLUB_FAILED
)
export const levelLoading = createAction(ClubActionTypes.LEVEL_LOADING)
export const claimFailed = createAction(ClubActionTypes.CLAIM_FAILED)
export const rocLevelsLoading = createAction(ClubActionTypes.ROC_LEVELS_LOADING)
