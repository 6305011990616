import { put, call, select, takeLatest } from "redux-saga/effects"
import GroupActionTypes from "./types"
import * as api from "api/group"
import notification from "components/commons/notification"
import { formatNum } from "helper/number"
import { groupDetailSelector } from "./selectors"
import * as actions from "./actions"

function* registerGroupSaga({ payload }) {
	try {
		yield put(actions.groupRegisterLoading(true))
		const groupDetail = yield select(groupDetailSelector)
		const data = yield call(api.registerApi, payload)
		const groupStatus = { ...groupDetail, ...data }
		yield put(actions.setGroupAction(groupStatus))
		notification({
			messageKey: "groupRegisterSuccess",
			type: "success",
			duration: 0,
			opts: { drop: groupStatus.drop },
		})
	} catch (error) {
		if (error?.response?.data?.errorKey) {
			const { errorKey, amount } = error.response.data
			const opts = { messageKey: errorKey }
			if (amount) {
				opts.opts = { amount: formatNum(amount) }
			}
			notification(opts)
		}
		yield put(actions.groupStatusFailed())
	} finally {
		yield put(actions.groupRegisterLoading(false))
	}
}

function* getGroupStateSaga() {
	try {
		const response = yield call(api.statusApi)
		yield put(actions.setGroupAction(response))
	} catch (error) {
		yield put(actions.groupStatusFailed())
	}
}

const groupSagas = [
	takeLatest(GroupActionTypes.REGISTER_GROUP, registerGroupSaga),
	takeLatest(GroupActionTypes.GET_GROUP_STATUS, getGroupStateSaga),
]

export default groupSagas
