const profileTypes = {
	UPDATE_USER_ASSET_NAME_REQ: "[profile] update user asset name",
	UPDATE_USER_ASSET_SUCCESS: "[profile] update user asset",
	GET_PROFILE_REQ: "[profile] get profile by username request",
	GET_PROFILE_SUCCESS: "[profile] get profile by username success",
	GET_PROFILE_FAILED: "[profile] get profile by username failed",
	GET_USER_ASSETS_REQ: "[profile] get user's assets request",
	GET_USER_ASSETS_SUCCESS: "[profile] get user's assets success",
	GET_USER_ASSETS_FAILED: "[profile] get user's assets failed",
}

export default profileTypes
