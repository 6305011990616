const walletActionTypes = {
	GET_WALLET: "GET_WALLET",
	GET_WALLET_SUCCESS: "GET_WALLET_SUCCESS",
	GET_WALLET_FAILED: "GET_WALLET_FAILED",

	GET_WALLET_TRANSACTIONS: "GET_WALLET_TRANSACTIONS",
	GET_WALLET_TRANSACTIONS_SUCCESS: "GET_WALLET_TRANSACTIONS_SUCCESS",
	GET_WALLET_TRANSACTIONS_FAILED: "GET_WALLET_TRANSACTONS_FAILED",

	INITIATE_WALLET_TRANSACTIONS: "POST_INITIATE_WALLET_TRANSACTIONS",
	INITIATE_WALLET_TRANSACTIONS_SUCCESS:
		"POST_INITIATE_WALLET_TRANSACTIONS_SUCCESS",
	INITIATE_WALLET_TRANSACTIONS_FAILED:
		"POST_WALLET_INITIATE_TRANSACTONS_FAILED",
}

export default walletActionTypes
