export const providers = [
	{
		chainId: 1,
		network: "mainnet",
		rpcURL:
			"https://eth-mainnet.alchemyapi.io/v2/-QF9nKllwL1AdclLfvxWs7VCXARjnwO5",
		baseTransactionUrl: "https://etherscan.io/tx/",
		tokens: [
			{
				contractAddress: "0xf56408077487cB879c992909C5b5C66D68c02Eb4",
				symbol: "riot",
			},
			{
				contractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
				symbol: "usdt",
			},
			{
				contractAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
				symbol: "usdc",
			},
		],
	},
	{
		chainId: 137,
		network: "matic",
		rpcURL: "https://rpc-mainnet.maticvigil.com/",
		baseTransactionUrl: "https://polygonscan.com/tx/",
		tokens: [
			{
				contractAddress: "0x4ff0b68abc2b9e4e1401e9b691dba7d66b264ac8",
				symbol: "riot",
			},
			{
				contractAddress: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
				symbol: "eth",
			},
			{
				contractAddress: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
				symbol: "usdt",
			},
			{
				contractAddress: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
				symbol: "usdc",
			},
		],
	},
]

if (process.env.REACT_APP_ENV_BUILD === "development") {
	const mumbaiNetwork = {
		chainId: 80001,
		network: "mumbai",
		rpcURL: "https://rpc-mumbai.maticvigil.com",
		tokens: [
			{
				contractAddress: "0x6c19612e057f05f3ee5ff7bb8d7bc24f63d6c7d0",
				symbol: "riot",
			},
			{
				contractAddress: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
				symbol: "eth",
			},
			{
				contractAddress: process.env.REACT_APP_USDT_MUMBAI_CONTRACT_ADDRESS,
				symbol: "usdt",
			},
		],
	}
	const ropstenNetwork = {
		chainId: 3,
		network: "ropsten",
		rpcURL: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
		tokens: [
			{
				contractAddress: "0x58a29dc940a5f3422aec821Be102F35AA03536DF",
				symbol: "riot",
			},
			{
				contractAddress: process.env.REACT_APP_USDT_ROPSTEN_CONTRACT_ADDRESS,
				symbol: "usdt",
			},
		],
	}
	providers.push(mumbaiNetwork, ropstenNetwork)
}

export const abi = [
	{
		constant: true,
		inputs: [],
		name: "mintingFinished",
		outputs: [{ name: "", type: "bool" }],
		payable: false,
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "name",
		outputs: [{ name: "", type: "string" }],
		payable: false,
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{ name: "_spender", type: "address" },
			{ name: "_value", type: "uint256" },
		],
		name: "approve",
		outputs: [],
		payable: false,
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "totalSupply",
		outputs: [{ name: "", type: "uint256" }],
		payable: false,
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{ name: "_from", type: "address" },
			{ name: "_to", type: "address" },
			{ name: "_value", type: "uint256" },
		],
		name: "transferFrom",
		outputs: [],
		payable: false,
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "decimals",
		outputs: [{ name: "", type: "uint256" }],
		payable: false,
		type: "function",
	},
	{
		constant: false,
		inputs: [],
		name: "unpause",
		outputs: [{ name: "", type: "bool" }],
		payable: false,
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{ name: "_to", type: "address" },
			{ name: "_amount", type: "uint256" },
		],
		name: "mint",
		outputs: [{ name: "", type: "bool" }],
		payable: false,
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "paused",
		outputs: [{ name: "", type: "bool" }],
		payable: false,
		type: "function",
	},
	{
		constant: true,
		inputs: [{ name: "_owner", type: "address" }],
		name: "balanceOf",
		outputs: [{ name: "balance", type: "uint256" }],
		payable: false,
		type: "function",
	},
	{
		constant: false,
		inputs: [],
		name: "finishMinting",
		outputs: [{ name: "", type: "bool" }],
		payable: false,
		type: "function",
	},
	{
		constant: false,
		inputs: [],
		name: "pause",
		outputs: [{ name: "", type: "bool" }],
		payable: false,
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "owner",
		outputs: [{ name: "", type: "address" }],
		payable: false,
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "symbol",
		outputs: [{ name: "", type: "string" }],
		payable: false,
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{ name: "_to", type: "address" },
			{ name: "_value", type: "uint256" },
		],
		name: "transfer",
		outputs: [
			{
				name: "",
				type: "bool",
			},
		],
		payable: false,
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{ name: "_to", type: "address" },
			{ name: "_amount", type: "uint256" },
			{ name: "_releaseTime", type: "uint256" },
		],
		name: "mintTimelocked",
		outputs: [{ name: "", type: "address" }],
		payable: false,
		type: "function",
	},
	{
		constant: true,
		inputs: [
			{ name: "_owner", type: "address" },
			{ name: "_spender", type: "address" },
		],
		name: "allowance",
		outputs: [{ name: "remaining", type: "uint256" }],
		payable: false,
		type: "function",
	},
	{
		constant: false,
		inputs: [{ name: "newOwner", type: "address" }],
		name: "transferOwnership",
		outputs: [],
		payable: false,
		type: "function",
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, name: "to", type: "address" },
			{ indexed: false, name: "value", type: "uint256" },
		],
		name: "Mint",
		type: "event",
	},
	{ anonymous: false, inputs: [], name: "MintFinished", type: "event" },
	{ anonymous: false, inputs: [], name: "Pause", type: "event" },
	{ anonymous: false, inputs: [], name: "Unpause", type: "event" },
	{
		anonymous: false,
		inputs: [
			{ indexed: true, name: "owner", type: "address" },
			{ indexed: true, name: "spender", type: "address" },
			{ indexed: false, name: "value", type: "uint256" },
		],
		name: "Approval",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, name: "from", type: "address" },
			{ indexed: true, name: "to", type: "address" },
			{ indexed: false, name: "value", type: "uint256" },
		],
		name: "Transfer",
		type: "event",
	},
]
