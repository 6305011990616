import request from "./request"

export const createUserApi = async (data) => {
	const response = await request({
		path: `users`,
		opts: {
			method: "POST",
			data,
		},
	})

	return response.data
}

export const authenticateApi = () =>
	request({
		path: `users/authenticate`,
		opts: {
			method: "GET",
		},
	})

export const challengeApi = async (publicKey, clientSeed) => {
	const response = await request({
		path: `users/challenge`,
		opts: {
			method: "POST",
			data: {
				publicKey,
				clientSeed,
			},
		},
	})

	return response.data
}

export const authApi = async (
	publicAddress,
	signature,
	email,
	clientSeed,
	challengeId
) => {
	const response = await request({
		path: `auth`,
		opts: {
			method: "POST",
			data: {
				publicAddress,
				signature,
				email,
				clientSeed,
				challengeId,
			},
		},
	})

	return response.data
}

export const fetchActivitiesApi = async () => {
	const response = await request({
		path: `users/activities`,
		opts: {
			method: "GET",
		},
	})

	return response.data
}

export const setCarApi = (userId, carId) =>
	request({
		path: `users/select-car`,
		opts: {
			method: "POST",
			data: {
				carId,
				userId,
			},
		},
	})

export const getGasBalanceApi = (userId) =>
	request({
		path: `users/${userId}/gas-balance`,
		opts: {
			method: "GET",
		},
	})

export const checkUsernameApi = (username) =>
	request({
		path: `users/username/${username}`,
		opts: {
			method: "GET",
		},
	})

export const updateUserApi = (payload, userId) =>
	request({
		path: `users/${userId}`,
		opts: {
			method: "PUT",
			data: payload,
		},
	})

export const checkEmailApi = (email, publicAddress) =>
	request({
		path: `users?email=${email}&publicAddress=${publicAddress}`,
		opts: {
			method: "GET",
		},
	})

export const renewTokenApi = () =>
	request({
		path: `users/renew-token`,
		opts: {
			method: "GET",
		},
	})

export const getProfileApi = (username) =>
	request({
		path: `users/profiles/${username}`,
		opts: {
			method: "GET",
		},
	})

export const searchUsersApi = (params) =>
	request({
		path: `users/search`,
		opts: {
			method: "GET",
			params,
		},
	})
