import React from "react"

export default () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
	>
		<g
			id="noun_white_paper_3405268"
			data-name="noun_white paper_3405268"
			transform="translate(-1 -1)"
		>
			<g id="Group_907" data-name="Group 907" transform="translate(1 1)">
				<path
					id="Path_4"
					data-name="Path 4"
					d="M24.613,21.9H21.9V2.935A1.938,1.938,0,0,0,19.968,1H2.935A1.938,1.938,0,0,0,1,2.935V3.71a.387.387,0,0,0,.387.387H4.1V23.065A1.938,1.938,0,0,0,6.032,25H23.065A1.938,1.938,0,0,0,25,23.065V22.29A.387.387,0,0,0,24.613,21.9ZM1.774,2.935A1.163,1.163,0,0,1,2.935,1.774H18.42a1.925,1.925,0,0,0-.388,1.161v.387H1.774ZM7.194,22.29v.774a1.161,1.161,0,0,1-2.323,0V4.1H18.419a.387.387,0,0,0,.387-.387V2.935a1.161,1.161,0,1,1,2.323,0V21.9H7.581A.387.387,0,0,0,7.194,22.29Zm17.032.774a1.163,1.163,0,0,1-1.161,1.161H7.58a1.925,1.925,0,0,0,.388-1.161v-.387H24.226Z"
					transform="translate(-1 -1)"
					fill="#d339fa"
				/>
				<path
					id="Path_5"
					data-name="Path 5"
					d="M16.484,17.968a3.486,3.486,0,0,0,3.23-4.791L19,13.468a2.686,2.686,0,0,1,.2,1.016,2.708,2.708,0,1,1-1.4-2.371l.376-.677a3.484,3.484,0,1,0-1.688,6.532Z"
					transform="translate(-8.355 -7.129)"
					fill="#d339fa"
				/>
				<path
					id="Path_6"
					data-name="Path 6"
					d="M17.84,13.888l-.547.547,1.548,1.548a.386.386,0,0,0,.274.113.346.346,0,0,0,.045,0,.386.386,0,0,0,.285-.182l3.1-5.032-.659-.406-2.84,4.615Z"
					transform="translate(-10.986 -6.807)"
					fill="#d339fa"
				/>
				<rect
					id="Rectangle_604"
					data-name="Rectangle 604"
					width="0.774"
					height="0.774"
					transform="translate(4.645 11.613)"
					fill="#d339fa"
				/>
				<rect
					id="Rectangle_605"
					data-name="Rectangle 605"
					width="13.161"
					height="0.774"
					transform="translate(6.194 11.613)"
					fill="#d339fa"
				/>
				<rect
					id="Rectangle_606"
					data-name="Rectangle 606"
					width="0.774"
					height="0.774"
					transform="translate(18.581 13.161)"
					fill="#d339fa"
				/>
				<rect
					id="Rectangle_607"
					data-name="Rectangle 607"
					width="13.161"
					height="0.774"
					transform="translate(4.645 13.161)"
					fill="#d339fa"
				/>
				<rect
					id="Rectangle_608"
					data-name="Rectangle 608"
					width="0.774"
					height="0.774"
					transform="translate(4.645 14.71)"
					fill="#d339fa"
				/>
				<rect
					id="Rectangle_609"
					data-name="Rectangle 609"
					width="13.161"
					height="0.774"
					transform="translate(6.194 14.71)"
					fill="#d339fa"
				/>
				<rect
					id="Rectangle_610"
					data-name="Rectangle 610"
					width="0.774"
					height="0.774"
					transform="translate(18.581 16.258)"
					fill="#d339fa"
				/>
				<rect
					id="Rectangle_611"
					data-name="Rectangle 611"
					width="13.161"
					height="0.774"
					transform="translate(4.645 16.258)"
					fill="#d339fa"
				/>
				<rect
					id="Rectangle_612"
					data-name="Rectangle 612"
					width="0.774"
					height="0.774"
					transform="translate(4.645 17.806)"
					fill="#d339fa"
				/>
				<rect
					id="Rectangle_613"
					data-name="Rectangle 613"
					width="13.161"
					height="0.774"
					transform="translate(6.194 17.806)"
					fill="#d339fa"
				/>
				<rect
					id="Rectangle_614"
					data-name="Rectangle 614"
					width="0.774"
					height="0.774"
					transform="translate(18.581 19.355)"
					fill="#d339fa"
				/>
				<rect
					id="Rectangle_615"
					data-name="Rectangle 615"
					width="13.161"
					height="0.774"
					transform="translate(4.645 19.355)"
					fill="#d339fa"
				/>
			</g>
		</g>
	</svg>
)
