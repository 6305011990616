import { Suspense, useEffect } from "react"
import { Switch, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import { initAuthReqAction } from "store/auth/actions"
import { authStateSelector } from "store/auth/selectors"
import { getTopNoticeAction } from "store/topNotice/actions"
import { web3ModalInit } from "store/web3Modal/actions"

import RouteWrapper from "components/RouteWrapper"
import ErrorBoundary from "components/ErrorBoundary"
import Loading from "components/commons/Loading"

import appRoutes from "routes"

import useIdleMonitor from "./hooks/useIdleMonitor"
import StarzModal from "components/StarzModal/StarzModal"

function App() {
	const dispatch = useDispatch()
	const history = useHistory()

	const { isLoggedIn, user, loading } = useSelector(authStateSelector)

	useIdleMonitor({
		delay: 4000, // timer delay in millisecond
		timeout: 60 * 120, // 2hrs in second
	})

	useEffect(() => {
		dispatch(initAuthReqAction({ history }))
		dispatch(getTopNoticeAction())
	}, [])

	useEffect(() => {
		dispatch(web3ModalInit())
	}, [isLoggedIn])

	const SwitchRoutes = (
		<Suspense fallback={<Loading />}>
			<Switch>
				{appRoutes.map((route) => (
					<RouteWrapper
						path={route.path}
						component={route.component}
						exact={route.exact}
						key={route.path}
						isPrivate={route.isPrivate}
						loading={loading}
						isLoggedIn={isLoggedIn}
						user={user}
					/>
				))}
			</Switch>
		</Suspense>
	)
	return (
		<ErrorBoundary>
			{SwitchRoutes}
			<StarzModal />
		</ErrorBoundary>
	)
}

export default App
