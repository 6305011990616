import { put, call, takeLatest } from "redux-saga/effects"
import notify from "components/commons/notification"
import { getGasBalanceApi } from "api/users"
import propertyTypes from "./types"
import * as actions from "./actions"

function* getGasBalanceSaga({ payload: userId }) {
	try {
		const result = yield call(getGasBalanceApi, userId)
		yield put(actions.getGasBalanceSuccAction(result?.gasBalance))
	} catch (error) {
		if (error.response) {
			notify({ title: error?.response?.message })
		}
		yield put(actions.getGasBalanceFailAction(error))
	}
}

export default [
	takeLatest(propertyTypes.GET_GAS_BALANCE_REQ, getGasBalanceSaga),
]
