import { createAction } from "@reduxjs/toolkit"
import TopNoticeActionTypes from "./types"

export const getTopNoticeAction = createAction(
	TopNoticeActionTypes.GET_TOP_NOTICE_REQ
)
export const hideTopNoticeAction = createAction(
	TopNoticeActionTypes.HIDE_TOP_NOTICE
)

export const getTopNoticeSuccess = createAction(
	TopNoticeActionTypes.GET_TOP_NOTICE_SUCC
)

export const getTopNoticeFail = createAction(
	TopNoticeActionTypes.GET_TOP_NOTICE_FAIL
)

const topNoticeActions = {
	getTopNoticeAction,
	hideTopNoticeAction,
	getTopNoticeSuccess,
	getTopNoticeFail,
}

export default topNoticeActions
