import request from "./request"

export const getLevelsApi = (data) =>
	request({
		path: `club/levels`,
		opts: {
			method: "POST",
			data,
		},
	})

export const getStatusApi = (data) =>
	request({
		path: `club/status`,
		opts: {
			method: "POST",
			data,
		},
	})

export const enrollClubApi = (data) =>
	request({
		path: `club/clubLevel`,
		opts: {
			method: "POST",
			data,
		},
	})

export const claimApi = (data) =>
	request({
		path: `club/claim`,
		opts: {
			method: "POST",
			data,
		},
	})
