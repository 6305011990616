const assetUpgradeTypes = {
	GET_UPGRADES: "[assets] GET_UPGRADES",
	GET_UPGRADES_SUCCESS: "[assets] GET_UPGRADES_SUCCESS",
	GET_UPGRADES_FAILED: "[assets] GET_UPGRADES_FAILED",

	GET_UPGRADES_COST: "[assets] GET_UPGRADES_COST",
	GET_UPGRADES_COST_SUCCESS: "[assets] GET_UPGRADES_COST_SUCCESS",
	GET_UPGRADES_COST_FAILED: "[assets] GET_UPGRADES_COST_FAILED",

	INITIATE_UPGRADES: "[assets] INITIATE_UPGRADES",
	INITIATE_UPGRADES_SUCCESS: "[assets] INITIATE_UPGRADES_SUCCESS",
	INITIATE_UPGRADES_FAILED: "[assets] INITIATE_UPGRADES_FAILED",
}

export default assetUpgradeTypes
