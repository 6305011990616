import { useMemo } from "react"
import ReactDOM from "react-dom"
import CirProgress from "../Icons/IconCirProgress"

import "./spinner.scss"

const Spinner = ({ fullScreen, className }) => {
	const classes = useMemo(() => {
		let base = "ant-loader"
		if (className) {
			base = `${base} ${className}`
		}
		if (fullScreen) {
			base = `${base} fullsize`
		}
		return base
	}, [fullScreen, className])

	return (
		<div className={classes}>
			<div className="ant-spin ant-spin-lg ant-spin-spinning">
				<CirProgress className="anticon-spin" />
			</div>
		</div>
	)
}

const destroy = () => {
	const spinners = document.getElementsByClassName("Riot-PortalSpin")
	if (spinners.length > 0) {
		Array.from(spinners).forEach((element) => {
			const unmountResult = ReactDOM.unmountComponentAtNode(element)
			if (unmountResult && element.parentNode) {
				element.parentNode.removeChild(element)
			}
		})
	}
}

/**
 * A function that appends spinner in the document.body.
 * DON't forgot to call destroy funtion to remove spinner.
 * Otherwise you need to pass time to destroy spinners
 * @param {number} delay time to destory spinner in millisecond
 */
const show = (delay) => {
	const div = document.createElement("div")
	div.setAttribute("class", "Riot-PortalSpin")
	div.style.top = "0px"
	div.style.left = "0px"
	div.style.zIndex = "9999"
	div.style.position = "fixed"
	div.style.width = "100%"
	div.style.height = "100%"
	document.body.appendChild(div)

	// I am dirty: fix me!!!
	if (delay) {
		setTimeout(() => {
			destroy()
		}, delay)
	}

	ReactDOM.render(<Spinner className="portal-spinner" />, div)
}

Spinner.show = show
Spinner.destroy = destroy

export default Spinner
