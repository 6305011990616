import { createReducer } from "@reduxjs/toolkit"
import * as types from "./types"

const initalState = {
	rentals: {
		response: null,
		loading: null,
		error: null,
	},
	accept: {
		loading: false,
		error: undefined, 
		response: undefined,
	}
}

const rentalReducer = createReducer(initalState, {
	[types.GET_RENTAL]: (state) => {
		state.rentals.loading = true
		state.rentals.error = null
	},
	[types.GET_RENTAL_SUCCESS]: (state, action) => {
		state.rentals.response = action.payload
		state.rentals.loading = false
		state.rentals.error = null
	},
	[types.GET_RENTAL_FAIL]: (state, action) => {
		state.rentals.loading = false
		state.rentals.error = action.payload
	},

	[types.ACCEPT_RENTAL]: (state) => {
		state.accept.loading = true
		state.accept.error = undefined
		state.accept.response = null
	},
	[types.ACCEPT_RENTAL_SUCCESS]: (state, action) => {
		state.accept.loading = false
		state.accept.error = undefined
		state.accept.response = action.payload

		state.rentals.response.rows = state.rentals.response.rows.filter(
			({ id }) => id !== action.payload
		)
	},
	[types.ACCEPT_RENTAL_FAIL]: (state, action) => {
		state.accept.loading = false
		state.accept.error = action.payload.errorKey
	},
})

export default rentalReducer
