import { createReducer } from "@reduxjs/toolkit"
import authActionTypes from "./types"

const initState = {
	loading: false,
	error: undefined,
	user: {},
	isLoggedIn: false,
	isMetaMaskLoggedIn: false,
}

const authReducer = createReducer(initState, {
	[authActionTypes.AUTHENTICATION_SUCC]: (state, action) => {
		let isLoggedIn = false
		if (action.payload.token) {
			localStorage.setItem("RiotRacersToken", action.payload.token)
		}
		if (action.payload.user.publicAddress) {
			isLoggedIn = true
		}
		state.loading = false
		state.error = null
		state.isLoggedIn = isLoggedIn
		state.isMetaMaskLoggedIn = true
		state.user = action.payload.user
	},
	[authActionTypes.AUTHENTICATION_FAIL]: (state, action) => {
		state.loading = false
		state.error = action.payload.error
		state.isLoggedIn = false
		state.isMetaMaskLoggedIn = false
	},
	[authActionTypes.OTP_SEND_REQ]: (state, action) => {
		if (state.user) {
			state.user.email = action.payload.email
			state.user.publicAddress = action.payload.publicAddress
			state.user.username = action.payload.username
		}
	},
	[authActionTypes.OTP_SEND_SUCC]: (state, action) => {
		state.error = null
		state.user = { ...state.user, ...action.payload }
	},
	[authActionTypes.OTP_SEND_FAIL]: (state, action) => {
		state.error = action.payload
	},
	[authActionTypes.VERIFY_OTP_SUCC]: (state, action) => {
		state.error = null
		state.user = { ...state.user, ...action.payload }
	},
	[authActionTypes.VERIFY_OTP_FAIL]: (state, action) => {
		state.error = action.payload
	},
	[authActionTypes.INITIAL_AUTHENTICATE_SUCC]: (state, action) => {
		state.loading = false
		state.error = null
		state.isLoggedIn = true
		state.isMetaMaskLoggedIn = true
		state.user = action.payload
	},
	[authActionTypes.INITIAL_AUTHENTICATE_FAIL]: (state, action) => {
		state.loading = false
		state.error = action.payload
		state.isLoggedIn = false
		state.isMetaMaskLoggedIn = false
		state.user = {}
	},
	[authActionTypes.LOGIN_USER_SUCC]: (state, action) => {
		if (action.payload.token) {
			localStorage.setItem("RiotRacersToken", action.payload.token)
		}
		state.loading = false
		state.isLoggedIn = true
		state.isMetaMaskLoggedIn = true
		state.user = action.payload.user
	},
	[authActionTypes.LOGIN_USER_FAIL]: (state, action) => {
		state.loading = false
		state.isLoggedIn = false
		// state.isMetaMaskLoggedIn = false
		state.error = action.payload
	},
	[authActionTypes.LOGOUT_USER]: (state) => {
		localStorage.removeItem("RiotRacersToken")
		state.loading = false
		state.isLoggedIn = false
		state.isMetaMaskLoggedIn = false
		state.user = {}
	},
})

export default authReducer
