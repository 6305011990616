const IconMechanicShop = ({ fill, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="23.767"
		height="21.231"
		viewBox="0 0 23.767 21.231"
		{...props}
	>
		<path
			id="Path_1436"
			data-name="Path 1436"
			d="M93.877,49.213A1.517,1.517,0,0,0,95.394,47.7V47.06a1.517,1.517,0,0,0-1.9-1.456l-2.427.636a1.517,1.517,0,0,0,.386,2.982ZM90.9,47.7a.546.546,0,0,1,.408-.527l2.427-.636a.54.54,0,0,1,.684.527V47.7a.546.546,0,0,1-.546.546H91.451A.546.546,0,0,1,90.9,47.7Zm8.762-5.414v.971h0a1.844,1.844,0,0,1-1.594,1.791,6.1,6.1,0,0,1,.728,3.023v6.321a1.812,1.812,0,0,1-1.81,1.81H95.265a1.813,1.813,0,0,1-1.81-1.81v-.51H85.717a.485.485,0,0,1,0-.971H93.94a.485.485,0,0,1,.485.485v.995a.839.839,0,0,0,.84.84h1.713a.839.839,0,0,0,.84-.84V48.055a5.063,5.063,0,0,0-.832-2.939c-.056-.082-.116-.167-.177-.243h0A.485.485,0,0,1,97.2,44.1h.641a.876.876,0,0,0,.859-.871v-.912a.828.828,0,0,0-.82-.828h-.485a1.725,1.725,0,0,0-1.145.527.485.485,0,0,1-.781-.172,26.079,26.079,0,0,0-1.873-3.972,3.981,3.981,0,0,0-2.747-1.582,22.326,22.326,0,0,0-3.64-.277.485.485,0,0,1,0-.971,23.224,23.224,0,0,1,3.79.291,4.907,4.907,0,0,1,3.378,1.983,24.512,24.512,0,0,1,1.735,3.579h0a2.486,2.486,0,0,1,1.264-.379h.512a1.786,1.786,0,0,1,1.784,1.769Zm-20.958,3.48v8.447a2.067,2.067,0,0,0,2.077,2.06h1.3a2.062,2.062,0,0,0,2.055-2.06V45.762a2.521,2.521,0,0,1,.888-1.915,5.528,5.528,0,0,0-.087-8.474,1,1,0,0,0-.641-.243,1.036,1.036,0,0,0-1.036,1.036V38.49h0a.971.971,0,0,1-.971.971H80.543a.971.971,0,0,1-.971-.971V36.177a1.036,1.036,0,0,0-1.017-1.036,1,1,0,0,0-.641.243,5.528,5.528,0,0,0-.087,8.464,2.518,2.518,0,0,1,.881,1.915Zm-1.851-6.115a4.55,4.55,0,0,1,1.667-3.526.037.037,0,0,1,.024,0,.068.068,0,0,1,.066.066v2.322a1.941,1.941,0,0,0,1.941,1.941h1.764a1.941,1.941,0,0,0,1.941-1.941V36.177a.068.068,0,0,1,.065-.066.036.036,0,0,1,.024,0,4.557,4.557,0,0,1,.07,6.989,3.489,3.489,0,0,0-1.254,2.662v8.447a1.092,1.092,0,0,1-1.09,1.09H80.769a1.092,1.092,0,0,1-1.09-1.09V45.762a3.49,3.49,0,0,0-1.225-2.65,4.557,4.557,0,0,1-1.6-3.465Zm4.084,13.8V45.619a.485.485,0,0,1,.971,0v7.824a.485.485,0,0,1-.971,0Z"
			transform="translate(-75.899 -35.039)"
			fill={fill}
		/>
	</svg>
)

export default IconMechanicShop
