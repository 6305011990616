import { createReducer } from "@reduxjs/toolkit"
import { initialState } from "../marketplaceInitialState"
import {
	GET_DROPLIMITS,
	GET_DROPLIMITS_SUCCESS,
	GET_DROPLIMITS_FAILED,
} from "./types"

const droplimitsReducer = createReducer(initialState.droplimits, {
	[GET_DROPLIMITS]: (state) => {
		state.loading = true
		state.error = undefined
	},
	[GET_DROPLIMITS_SUCCESS]: (state, action) => {
		state.types = {}
		if (action.payload) {
			action.payload.forEach((drop) => {
				state.types[drop.dropLimitType] = drop.dropItemLimit
				state.typeCartLimits[drop.dropLimitType] = drop.cartLimit
			})
		}

		state.error = undefined
		state.loading = false
	},
	[GET_DROPLIMITS_FAILED]: (state, action) => {
		state.loading = false
		state.error = action.error
	},
})

export default droplimitsReducer
