import request from "./request"

export const getLeaderboard = async (args) => {
	const response = await request({
		path: `leaderboards/${args.payload.type}/load`,
		opts: {
			method: "GET",
			params: args.payload.params,
		},
	})

	return response.data
}
