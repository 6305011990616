import request from "./request"

export const discordApi = async () =>
	request({
		path: "discord",
		opts: {
			method: "GET",
		},
	})

export const discordCallbackApi = async (code) =>
	request({
		path: "discord/callback",
		opts: {
			method: "POST",
			data: {
				code,
			},
		},
	})

export const discordUnlinkApi = async (userId) =>
	request({
		path: `discord/unlink/${userId}`,
		opts: {
			method: "DELETE",
		},
	})

export const discordSyncRocRolesApi = async (userId) =>
	request({
		path: `discord/sync/${userId}`,
		opts: {
			method: "POST",
		},
	})
