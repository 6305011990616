import { createReducer } from "@reduxjs/toolkit"
import * as types from "./types"

const initialState = {
	entity: null,
	error: null,
	loading: false,
	entities: [],
}

const sentRequest = (state) => {
	state.processing = true
}

const finishedRequest = (state) => {
	state.processing = false
}

const bulletinsReducer = createReducer(initialState, {
	[types.CREATE_BULLETIN_REQ]: sentRequest,
	[types.CREATE_BULLETIN_FAILED]: finishedRequest,
	[types.READ_BULLETIN_REQ]: sentRequest,
	[types.READ_BULLETIN_FAILED]: finishedRequest,
	[types.SET_BULLETIN]: (state, { payload }) => {
		state.processing = false
		state.loading = false
		state.entity = payload
	},
	[types.SET_BULLETINS]: (state, { payload }) => {
		state.processing = false
		state.loading = false
		state.entities = payload
	},
	[types.LOAD_BULLETIN_REQ]: (state) => {
		state.loading = true
		state.entity = null
	},
	[types.LOAD_BULLETINS_REQ]: (state) => {
		state.loading = true
	},
})

export default bulletinsReducer
