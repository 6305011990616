import { combineReducers } from "redux"
import activitiesReducer from "./activities/reducer"
import authReducer from "./auth/reducer"
import coinReducer from "./coin/reducer"
import modalReducer from "./modal/reducer"
import propertyReducer from "./property/reducer"
import assetsReducer from "./assets/reducer"
import topNoticeReducer from "./topNotice/reducer"
import profileReducer from "./profile/reducer"
import teamsReducer from "./teams/reducer"
import teammembersReducer from "./teammembers/reducer"
import bulletinsReducer from "./bulletins/reducer"
import groupReducer from "./group/reducer"
import clubReducer from "./club/reducer"
import racesReducer from "./races/reducer"
import discordReducer from "./discord/reducer"
import conversionsReducer from "./conversions/reducer"
import leaderboardsReducer from "./leaderboards/reducer"
import walletReducer from "./wallet/reducer"
import withdrawLimitReducer from "./withdrawLimits/reducer"
import rewardsReducer from "./rewards/reducer"
import rentalReducer from "./rentals/reducer"
import web3Reducer from "./web3Modal/reducers"
import starzModalReducer from "./starzModal/reducer"
import tournamentsReducer from "./tournaments/reducer"
import carReducer from "./car/reducers"
import carsReducer from "./cars/reducers"
import droplimitsReducer from "./droplimits/reducers"
import driverReducer from "./driver/reducers"
import landsReducer from "./lands/reducers"
import marketplaceReducer from "./marketplace/reducers"
import settingsReducer from "./settings/reducers"
import authActionTypes from "./auth/types"
import stakingReducer from "./stakingWallets/reducer"
import upgradeReducer from "./upgrade/reducer"

const appReducer = combineReducers({
	activities: activitiesReducer,
	auth: authReducer,
	assets: assetsReducer,
	coin: coinReducer,
	modal: modalReducer,
	property: propertyReducer,
	profile: profileReducer,
	topNotice: topNoticeReducer,
	teams: teamsReducer,
	teammembers: teammembersReducer,
	group: groupReducer,
	club: clubReducer,
	races: racesReducer,
	discord: discordReducer,
	conversions: conversionsReducer,
	leaderboards: leaderboardsReducer,
	wallet: walletReducer,
	withdrawLimit: withdrawLimitReducer,
	rewards: rewardsReducer,
	bulletins: bulletinsReducer,
	tournaments: tournamentsReducer,
	rentals: rentalReducer,
	web3Modal: web3Reducer,
	starzModal: starzModalReducer,
	stakingWallets: stakingReducer,
	car: carReducer,
	cars: carsReducer,
	droplimits: droplimitsReducer,
	driver: driverReducer,
	lands: landsReducer,
	marketplace: marketplaceReducer,
	settings: settingsReducer,
	upgrade: upgradeReducer,
})

const rootReducer = (state, action) => appReducer(state, action)
// if (action.type === authActionTypes.LOGOUT_USER_SUCCESS) {
// 	state = undefined
// }

export default rootReducer
