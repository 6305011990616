import React from "react"

export default (props) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect width="24" height="24" fill="none" />
		<path d="M14,8H8v6H6V8H0V6H6V0H8V6h6Z" transform="translate(5 5)" />
	</svg>
)
