import request from "./request"

const prefix = "team"
const path = "bulletin"

export const createBulletin = (input) =>
	request({
		path: `${prefix}/${input.teamId}/${path}`,
		opts: {
			method: "POST",
			data: {
				author: input.author,
				type: input.type,
				message: input.message,
				tournamentId: input.tournamentId,
			},
		},
	})

export const getBulletinsByTeamId = ({ userId, teamId }) =>
	request({
		path: `${prefix}/${teamId}/${path}`,
		opts: {
			method: "GET",
			params: { userId },
		},
	})

export const updateBulletin = (input) =>
	request({
		path: `${prefix}/${input.teamId}/${path}/${input.post_id}`,
		opts: {
			method: "PUT",
			data: {
				author: input.author,
				type: input.type,
				message: input.message,
				tournamentId: input.tournamentId,
			},
		},
	})

export const getBulletinsById = (teamId, bulletinId) =>
	request({
		path: `${prefix}/${teamId}/${path}/${bulletinId}`,
		opts: {
			method: "GET",
		},
	})

export const deleteBulletinsById = (teamId, bulletinId) =>
	request({
		path: `${prefix}/${teamId}/${path}/${bulletinId}`,
		opts: {
			method: "DELETE",
		},
	})

export const markBulletinRead = ({ teamId, userId, msgId }) =>
	request({
		path: `${prefix}/${teamId}/${path}/${msgId}/status`,
		opts: {
			method: "PUT",
			data: { userId, status: "read" },
		},
	})
