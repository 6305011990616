import React from "react"
import { Route, Redirect } from "react-router-dom"
import Loading from "components/commons/Loading"

export default function RouteWrapper({
	component: Component,
	isPrivate,
	loading,
	...rest
}) {
	if (isPrivate) {
		if (loading) {
			return <Loading />
		}
		if (rest.isLoggedIn === true) {
			return (
				<Route
					render={(props) => <Component {...props} {...rest} />}
					{...rest}
				/>
			)
		}
		return <Redirect to="/" />
	}
	return (
		<Route render={(props) => <Component {...props} {...rest} />} {...rest} />
	)
}
