import { createAction } from "@reduxjs/toolkit"
import assetsActionTypes from "./types"

export const getAssetsReqAction = createAction(assetsActionTypes.GET_ASSETS_REQ)
export const getAssetsSuccAction = createAction(
	assetsActionTypes.GET_ASSETS_SUCC
)
export const getAssetsFailAction = createAction(
	assetsActionTypes.GET_ASSETS_FAIL
)

export const getAssetsByEthReqAction = createAction(
	assetsActionTypes.GET_ASSETS_ETH_REQ
)

export const getCurrentCarReqAction = createAction(
	assetsActionTypes.GET_CURRENT_CAR_REQ
)
export const getActiveCarReqAction = createAction(
	assetsActionTypes.GET_ACTIVE_CAR_REQ
)
export const setActiveCarReqAction = createAction(
	assetsActionTypes.SET_ACTIVE_CAR_REQ
)

export const checkAssetNameReqAction = createAction(
	assetsActionTypes.GET_CHECK_ASSET_NAME_REQ
)
export const checkAssetNameSuccAction = createAction(
	assetsActionTypes.GET_CHECK_ASSET_NAME_SUCC
)
export const checkAssetNameFailAction = createAction(
	assetsActionTypes.GET_CHECK_ASSET_NAME_FAIL
)

export const updateAssetNameReqAction = createAction(
	assetsActionTypes.UPDATE_ASSET_NAME_REQ
)
export const updateAssetNameSuccAction = createAction(
	assetsActionTypes.UPDATE_ASSET_NAME_SUCC
)
export const updateAssetNameFailAction = createAction(
	assetsActionTypes.UPDATE_ASSET_NAME_FAIL
)

export const toggleAssetsFavorite = createAction(
	assetsActionTypes.TOGGLE_ASSET_FAVORITE
)
export const toggleAssetsFavoriteSucc = createAction(
	assetsActionTypes.TOGGLE_ASSET_FAVORITE_SUCC
)

export const updateAsset = createAction(assetsActionTypes.UPDATE_ASSET)
export const deleteAssetRental = createAction(
	assetsActionTypes.DELETE_ASSET_RENTAL
)

export const getAssetsRequest = createAction(assetsActionTypes.GET_ASSETS)
export const getAssetsSuccess = createAction(
	assetsActionTypes.GET_ASSETS_SUCCESS
)
export const getAssetsFailure = createAction(
	assetsActionTypes.GET_ASSETS_FAILED
)
