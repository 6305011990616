import { findIndex } from "lodash"

export const updateItem = (arr = [], object) => {
	const index = findIndex(arr, { id: object.id })
	if (index === -1) {
		arr.push(object)
	} else {
		arr[index] = object
	}
	return arr
}

export const addOrReplaceAsset = (state, object) => ({
	...state,
	rows: updateItem(state?.rows, object),
})
