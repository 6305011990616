import React from "react"

export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		{...props}
	>
		<rect width="24" height="24" fill="none" />
		<path
			d="M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20ZM4.41,8.59h0L3,10l5,5,9-9L15.59,4.58,8,12.17,4.41,8.59Z"
			transform="translate(2 2)"
		/>
	</svg>
)
