import { createAction } from "@reduxjs/toolkit"
import * as types from "./types"

export const createBulletinAction = createAction(types.CREATE_BULLETIN_REQ)
export const createBulletinFailedAction = createAction(
	types.CREATE_BULLETIN_FAILED
)

export const loadBulletinAction = createAction(types.LOAD_BULLETIN_REQ)
export const loadBulletinFailedAction = createAction(types.LOAD_BULLETIN_FAILED)

export const loadBulletinsAction = createAction(types.LOAD_BULLETINS_REQ)
export const loadBulletinsFailedAction = createAction(
	types.LOAD_BULLETINS_FAILED
)

export const setBulletinAction = createAction(types.SET_BULLETIN)
export const setBulletinsAction = createAction(types.SET_BULLETINS)

export const deleteBulletinAction = createAction(types.DELETE_BULLETIN_REQ)
export const deleteBulletinFailedAction = createAction(
	types.DELETE_BULLETIN_FAILED
)

export const updateBulletinAction = createAction(types.UPDATE_BULLETIN_REQ)
export const updateBulletinFailedAction = createAction(
	types.UPDATE_BULLETIN_FAILED
)

export const readBulletinAction = createAction(types.READ_BULLETIN_REQ)
export const readBulletinFailedAction = createAction(types.READ_BULLETIN_FAILED)
