import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { store } from "store"
import HttpsRedirect from "components/HttpsRedirect"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "antd/dist/antd.css"
import "./i18n"
import "./index.css"
import "./styles.scss"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { RECAPTCHA_V3_SITE_KEY } from "constants/apiConfig"

ReactDOM.render(
	<Provider store={store}>
		<Router>
			<HttpsRedirect>
				<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_SITE_KEY}>
					<App />
				</GoogleReCaptchaProvider>
			</HttpsRedirect>
		</Router>
	</Provider>,
	document.getElementById("root")
)

reportWebVitals()
