import { createReducer } from "@reduxjs/toolkit"
import assetUpgradeTypes from "./types"

const initialState = {
	list: {
		upgrades: [],
		loading: null,
		err: null,
	},
	cost: {
		costs: [],
		loading: null,
		err: null,
	},
	initiate: {
		responses: [],
		loading: null,
		err: null,
	},
}

const upgradeReducer = createReducer(initialState, {
	[assetUpgradeTypes.GET_UPGRADES]: (state) => {
		state.list.loading = true
	},
	[assetUpgradeTypes.GET_UPGRADES_SUCCESS]: (state, action) => {
		state.list.upgrades = action.payload
		state.list.loading = false
	},
	[assetUpgradeTypes.GET_UPGRADES_FAILED]: (state, action) => {
		state.list.err = action.payload
		state.list.loading = false
	},


	[assetUpgradeTypes.GET_UPGRADES_COST]: (state) => {
		state.cost.loading = true
	},
	[assetUpgradeTypes.GET_UPGRADES_COST_SUCCESS]: (state, action) => {
		state.cost.costs = action.payload
		state.cost.loading = false
	},
	[assetUpgradeTypes.GET_UPGRADES_COST_FAILED]: (state, action) => {
		state.cost.err = action.payload
		state.cost.loading = false
	},


	[assetUpgradeTypes.INITIATE_UPGRADES]: (state) => {
		state.initiate.loading = true
	},
	[assetUpgradeTypes.INITIATE_UPGRADES_SUCCESS]: (state, action) => {
		state.initiate.responses = action.payload
		state.initiate.loading = false
	},
	[assetUpgradeTypes.INITIATE_UPGRADES_FAILED]: (state, action) => {
		state.initiate.err = action.payload
		state.initiate.loading = false
	},
})

export default upgradeReducer
