import { createReducer } from "@reduxjs/toolkit"
import { initialState } from "../marketplaceInitialState"
import { GET_DRIVER, GET_DRIVER_SUCCESS, GET_DRIVER_FAILED } from "./types"

const driverReducer = createReducer(initialState.driverState, {
	[GET_DRIVER]: (state) => {
		state.loading = true
		state.currentDriver = null
	},
	[GET_DRIVER_SUCCESS]: (state, action) => {
		state.error = undefined
		state.loading = false
		state.currentDriver = action.payload
	},
	[GET_DRIVER_FAILED]: (state, action) => {
		state.loading = false
		state.error = action.error
		state.currentDriver = null
	},
})

export default driverReducer
