import { put, call, takeLatest, select } from "redux-saga/effects"
import * as bulletinAPI from "api/bulletin"
import Spinner from "components/commons/Spinner"
import { getUserIdSelector } from "../auth/selectors"
import notify from "components/commons/notification"

import {
	UPDATE_BULLETIN_REQ,
	LOAD_BULLETIN_REQ,
	LOAD_BULLETINS_REQ,
	CREATE_BULLETIN_REQ,
	DELETE_BULLETIN_REQ,
	READ_BULLETIN_REQ,
} from "./types"

import {
	createBulletinAction,
	createBulletinFailedAction,
	setBulletinAction,
	setBulletinsAction,
	loadBulletinFailedAction,
	loadBulletinsFailedAction,
	updateBulletinFailedAction,
	readBulletinFailedAction,
} from "./actions"

import { bulletinsSelector } from "./selectors"

const showNotify = (err, top) => {
	const errCode = err?.response?.data?.errorKey
	if (errCode) {
		notify({ messageKey: errCode, top })
	}
}

/**
 * payload is an object
 * {
 *   "teamId": number,
 *   "tournamentId"?: number,
 *   "message"?: string,
 * }
 */
function* createBulletinSaga({ payload }) {
	try {
		Spinner.show()

		if (payload) {
			const bulletin = yield call([bulletinAPI, "createBulletin"], payload)
			notify({ messageKey: "CreateBulletin", type: "success", bottom: 40 })
			yield put(createBulletinAction(bulletin.data))
		}
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(createBulletinFailedAction())
	} finally {
		Spinner.destroy()
	}
}

// payload will be be a teamId
function* loadBulletinsSaga({ payload }) {
	try {
		Spinner.show()
		const userId = yield select(getUserIdSelector)
		const { teamId } = payload
		const bulletin = yield call([bulletinAPI, "getBulletinsByTeamId"], {
			userId,
			teamId,
		})
		yield put(setBulletinsAction(bulletin.data))
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(loadBulletinsFailedAction())
	} finally {
		Spinner.destroy()
	}
}

// payload will be be a teamId and msgId
function* loadBulletinSaga({ payload }) {
	try {
		Spinner.show()
		const bulletin = yield call(
			[bulletinAPI, "getBulletinsById"],
			payload.t,
			payload.m
		)
		yield put(setBulletinAction(bulletin.data))
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(loadBulletinFailedAction())
	} finally {
		Spinner.destroy()
	}
}

// payload will be be a teamId and msgId
function* deleteBulletinSaga({ payload }) {
	try {
		Spinner.show()

		const bulletin = yield call(
			[bulletinAPI, "deleteBulletinsById"],
			payload.t,
			payload.m
		)
		notify({ messageKey: "DeleteBulletin", type: "success", bottom: 40 })
		yield put(setBulletinAction(bulletin))
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(loadBulletinFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* updateBulletinSaga({ payload }) {
	try {
		Spinner.show()
		const { viewComponent, cb, ...params } = payload
		const bulletin = yield call([bulletinAPI, "updateBulletin"], params)
		notify({ messageKey: "UpdateBulletin", type: "success", bottom: 40 })
		yield put(setBulletinAction(bulletin.data))
		if (typeof cb === "function") {
			cb()
		}
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(updateBulletinFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* markReadBulletinSaga({ payload }) {
	try {
		Spinner.show()
		const { viewComponent, cb, ...params } = payload
		const bulletins = yield select(bulletinsSelector)
		yield call([bulletinAPI, "markBulletinRead"], params)
		const updated = bulletins.filter((x) => x.id !== params.msgId)
		notify({ messageKey: "ReadBulletin", type: "success", bottom: 40 })
		yield put(setBulletinsAction(updated))
		if (typeof cb === "function") {
			cb()
		}
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(readBulletinFailedAction())
	} finally {
		Spinner.destroy()
	}
}

export default [
	takeLatest(READ_BULLETIN_REQ, markReadBulletinSaga),
	takeLatest(UPDATE_BULLETIN_REQ, updateBulletinSaga),
	takeLatest(LOAD_BULLETIN_REQ, loadBulletinSaga),
	takeLatest(LOAD_BULLETINS_REQ, loadBulletinsSaga),
	takeLatest(DELETE_BULLETIN_REQ, deleteBulletinSaga),
	takeLatest(CREATE_BULLETIN_REQ, createBulletinSaga),
]
