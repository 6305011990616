import React from "react"

export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="10"
		height="5"
		viewBox="0 0 10 5"
		{...props}
	>
		<path d="M0,0,5,5l5-5Z" />
	</svg>
)
