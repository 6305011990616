import { createAction } from "@reduxjs/toolkit"
import * as types from "./types"

export const createTeamAction = createAction(types.CREATE_TEAM_REQ)
export const createTeamFailedAction = createAction(types.CREATE_TEAM_FAILED)

export const updateTeamAction = createAction(types.UPDATE_TEAM_REQ)
export const updateTeamFailedAction = createAction(types.UPDATE_TEAM_FAILED)

export const removeTeamAction = createAction(types.REMOVE_TEAM_REQ)
export const removeTeamFailedAction = createAction(types.REMOVE_TEAM_FAILED)

export const loadTeamAction = createAction(types.LOAD_TEAM_REQ)
export const loadTeamFailedAction = createAction(types.LOAD_TEAM_FAILED)

export const loadTeamsAction = createAction(types.LOAD_TEAMS_REQ)
export const loadTeamsFailedAction = createAction(types.LOAD_TEAMS_FAILED)

export const setTeamAction = createAction(types.SET_TEAM)
export const setTeamsAction = createAction(types.SET_TEAMS)

export const loadUserAssetsAction = createAction(types.LOAD_USER_ASSETS)
export const loadUserAssetsSuccesAction = createAction(
	types.LOAD_USER_ASSETS_SUCCESS
)
export const loadUserAssetsFailedAction = createAction(
	types.LOAD_USER_ASSETS_FAILED
)
