import { createReducer } from "@reduxjs/toolkit"
import propertyTypes from "./types"

const initialState = {
	loading: true,
	error: undefined,
	gasBalance: 0,
}

const propertyReducer = createReducer(initialState, {
	[propertyTypes.GET_GAS_BALANCE_REQ]: (state) => {
		state.loading = true
	},
	[propertyTypes.GET_GAS_BALANCE_SUCC]: (state, action) => {
		state.loading = false
		state.error = null
		state.gasBalance = action.payload
	},
	[propertyTypes.GET_GAS_BALANCE_FAIL]: (state, action) => {
		state.loading = false
		state.error = action.payload
	},
})

export default propertyReducer
