import { createReducer } from "@reduxjs/toolkit"
import * as types from "./types"

const initialState = {
	processing: false,
	loadUserAssets: false,
	loading: false,
	entity: null,
	error: false,
	userAssets: {},
	entities: [],
}

const sentRequest = (state) => {
	state.processing = true
}

const finishedRequest = (state) => {
	state.processing = false
}

const teamsReducer = createReducer(initialState, {
	[types.CREATE_TEAM_REQ]: sentRequest,
	[types.UPDATE_TEAM_REQ]: sentRequest,
	[types.REMOVE_TEAM_REQ]: sentRequest,
	[types.UPDATE_TEAM_FAILED]: finishedRequest,
	[types.REMOVE_TEAM_FAILED]: finishedRequest,
	[types.SET_TEAM]: (state, { payload }) => {
		state.processing = false
		state.loading = false
		state.entity = payload
	},
	[types.LOAD_TEAM_REQ]: (state) => {
		state.error = false
		state.loading = true
		state.entity = null
	},
	[types.LOAD_TEAMS_REQ]: (state) => {
		state.error = false
		state.loading = true
		state.entities = []
	},
	[types.SET_TEAMS]: (state, { payload }) => {
		state.loading = false
		state.entities = payload
	},

	[types.CREATE_TEAM_FAILED]: (state) => {
		state.error = true
		state.entity = null
	},

	[types.LOAD_TEAM_FAILED]: (state) => {
		state.error = true
		state.entity = null
	},
	[types.LOAD_TEAMS_FAILED]: (state) => {
		state.error = true
		state.entity = null
	},

	[types.LOAD_USER_ASSETS]: (state) => {
		state.userAssets = {}
		state.loadUserAssets = true
	},
	[types.LOAD_USER_ASSETS_SUCCESS]: (state, { payload }) => {
		state.loadUserAssets = false
		state.userAssets = payload
	},
	[types.LOAD_USER_ASSETS_FAILED]: (state) => {
		state.userAssets = {}
		state.loadUserAssets = false
	},
})

export default teamsReducer
