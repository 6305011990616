import request from "./request"

export const getStakingWallet = () =>
	request({
		path: `stakingwallet/load`,
		opts: {
			method: "GET",
		},
	})

export const postStakingInitiateTransaction = (data) =>
	request({
		path: `stakingwallet/transactions/initiate`,
		opts: {
			method: "POST",
			data,
		},
	})