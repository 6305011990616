import request from "./request"

export const claimRewards = (data) =>
	request({
		path: `rewards/claim`,
		opts: {
			method: "POST",
			data,
		},
	})

export const getBalance = () =>
	request({
		path: `rewards/balance`,
		opts: {
			method: "GET",
		},
	})

// history request
export const getHistory = (params) =>
	request({
		path: `rewards/list`,
		opts: {
			method: "GET",
			params,
		},
	})
