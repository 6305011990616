import request from "./request"

export const getWalletTransactions = (params) =>
	request({
		path: `wallet/transactions/list`,
		opts: {
			method: "GET",
			params,
		},
	})

export const getWallet = () =>
	request({
		path: `wallet/load`,
		opts: {
			method: "GET",
		},
	})

export const postWalletInitiateTransaction = (data) =>
	request({
		path: `wallet/transactions/initiate`,
		opts: {
			method: "POST",
			data,
		},
	})
