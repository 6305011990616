import { createAction } from "@reduxjs/toolkit"
import * as types from "./types"

export const getBalance = createAction(types.GET_BALANCE)
export const getBalanceSuccess = createAction(types.GET_BALANCE_SUCCESS)
export const getBalanceFailure = createAction(types.GET_BALANCE_FAILURE)

export const claimRewards = createAction(types.CLAIM_REWARDS)
export const claimRewardsSuccess = createAction(types.CLAIM_REWARDS_SUCCESS)
export const claimRewardsFailure = createAction(types.CLAIM_REWARDS_FAILURE)

export const getRewardsHistory = createAction(types.GET_REWARDS_HISTORY)
export const getRewardsHistorySuccess = createAction(
	types.GET_REWARDS_HISTORY_SUCCESS
)
export const getRewardsHistoryFailure = createAction(
	types.GET_REWARDS_HISTORY_FAILURE
)
