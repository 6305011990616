import { createSelector } from "@reduxjs/toolkit"

const stateSelector = (state) => state.club

export const selectClubLevels = createSelector(
	stateSelector,
	(state) => state.levels
)

export const selectRocLevelsLoader = createSelector(
	stateSelector,
	(state) => state.rocLevelsLoading
)
