import React from "react"

export default (props) => (
	<svg
		width="24"
		height="18.561"
		viewBox="0 0 24 18.561"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g transform="translate(-197.262 -425.336)">
			<path d="M197.262,443.9l3.956-12.139h12.03a3.373,3.373,0,0,0,.719-.068c.207-.044.329-.13.365-.257.055-.145-.009-.244-.189-.3a2.4,2.4,0,0,0-.677-.081h-12.03l1.87-5.717h12.789a5.487,5.487,0,0,1,4.213,1.45,4.516,4.516,0,0,1,.8,4.159l-.3.921a8.7,8.7,0,0,1-2.669,3.644,9.488,9.488,0,0,1-4.105,1.8l4.39,6.585h-8.373l-4.227-6.151L203.82,443.9Zm4.173-11.814L197.7,443.571H203.6l2.086-6.395h.189l4.417,6.395h7.451l-4.308-6.476a9.162,9.162,0,0,0,4.295-1.639,8.412,8.412,0,0,0,2.777-3.617l.271-.867a4.282,4.282,0,0,0-.759-3.957,5.315,5.315,0,0,0-4.037-1.354H203.521l-1.653,5.094h11.705a2.11,2.11,0,0,1,.867.149.372.372,0,0,1,.189.529.776.776,0,0,1-.528.5,2.859,2.859,0,0,1-.962.149Zm-2.791,10.784,3.279-10.08H212.9a3.671,3.671,0,0,0,1.531-.325,1.624,1.624,0,0,0,.935-1.03q.244-.759-.257-1.084a2.384,2.384,0,0,0-1.314-.325H202.817l1.192-3.658h11.733a4.738,4.738,0,0,1,3.59,1.193,3.819,3.819,0,0,1,.691,3.5l-.217.7a7.19,7.19,0,0,1-2.994,3.522,9.309,9.309,0,0,1-4.782,1.165l4.227,6.422H210.81l-4.417-6.422h-1.22l-2.086,6.422Z" />
		</g>
	</svg>
)
