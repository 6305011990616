import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import { Affix } from "antd"
import useTopNoticeVisible from "hooks/useTopNoticeVisible"
import logoOrange from "assets/images/logo-orange.png"
import connectMetaMask from "assets/images/Connect_Meta_Mask.png"

import RioButton from "../../Button"
import LanguageSelector from "../../LanguageSelector"
import MainMenu from "./MainMenu"
import UserMenu from "./UserMenu"
import UserBallance from "./UserBallance"
import CartButton from "./CartButton"
import MyOrdersButton from "./MyOrdersButton"
import MarketplaceDropRegister from "./MarketplaceDropRegister"
import TopNotice from "../../TopNotice"
import "./styles.scss"

const DesktopHeader = ({ authState, handleAuth, OpenModal, handleLogout }) => {
	const { t } = useTranslation()
	const topNoticeVisible = useTopNoticeVisible()
	const { isLoggedIn, isMetaMaskLoggedIn } = authState || {}
	const { pathname } = useLocation()
	return (
		<Affix offsetTop={0}>
			<div>
				{topNoticeVisible && <TopNotice />}
				<div className="HeaderDesktop">
					<div className="HeaderLeft overflow-x-scroll">
						<div className="HeaderLogo">
							<Link to="/">
								<img src={logoOrange} alt="Riot" />
							</Link>
						</div>
						<div className="PageMenu">
							<MainMenu />
						</div>
					</div>
					<div className="HeaderRight">
						{isLoggedIn && isMetaMaskLoggedIn && (
							<>
								{isMetaMaskLoggedIn && <UserBallance />}
								{!isMetaMaskLoggedIn && (
									<div onClick={handleAuth}>
										<img
											src={connectMetaMask}
											alt="Connect MetaMask"
											className="ConnectMetaMask"
										/>
									</div>
								)}
								<LanguageSelector showlabel={false} />
								<UserMenu handleLogout={handleLogout} />
							</>
						)}
						{(!isLoggedIn || !isMetaMaskLoggedIn) && (
							<LanguageSelector showlabel={false} />
						)}

						{!isLoggedIn && (
							<RioButton onClick={OpenModal} className="ml30">
								{t("start")}
							</RioButton>
						)}
					</div>
				</div>

				{isLoggedIn && pathname.indexOf("/marketplace") !== -1 && (
					<section className="MarketHeaderDesktop">
						<CartButton />
						<MyOrdersButton />
						<MarketplaceDropRegister />
					</section>
				)}
			</div>
		</Affix>
	)
}

export default DesktopHeader
