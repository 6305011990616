import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import DefaultLayout from "layout/DefaultLayout"
import RioButton from "components/commons/Button"

import "./styles.scss"

const Error = ({ onClick }) => {
	const { t } = useTranslation()

	return (
		<DefaultLayout>
			<div className="error-page">
				<div>
					<p className="title">{t("errorBoundary.title")}</p>
					<p className="desc">{t("errorBoundary.desc")}</p>
					<RioButton onClick={onClick}>Back to Home</RioButton>
				</div>
			</div>
		</DefaultLayout>
	)
}

export default Error
