import { createReducer } from "@reduxjs/toolkit"
import * as types from "./types"

const initialState = {
	processing: false,
	loading: true,
	entity: null,
	entities: [],
	hasTeam: false,
	invitations: [],
}

const sentRequest = (state) => {
	state.processing = true
}

const finishedRequest = (state) => {
	state.processing = false
}

const teamsReducer = createReducer(initialState, {
	[types.ACCEPT_INVITE]: sentRequest,
	[types.ACCEPT_INVITE_FAILED]: finishedRequest,
	[types.REJECT_INVITE]: sentRequest,
	[types.INVITE_USER_REQUEST]: sentRequest,
	[types.INVITE_USER_FAILED]: finishedRequest,
	[types.CANCEL_INVITE]: sentRequest,
	[types.CANCEL_INVITE_FAILED]: finishedRequest,
	[types.APPLY_TEAM_REQUEST]: sentRequest,
	[types.APPLY_TEAM_FAILED]: finishedRequest,
	[types.ACCEPT_TEAM_REQUEST]: sentRequest,
	[types.ACCEPT_TEAM_FAILED]: finishedRequest,
	[types.REJECT_INVITE_FAILED]: finishedRequest,
	[types.REMOVE_MEMBER_REQ]: sentRequest,
	[types.REMOVE_MEMBER_FAILED]: finishedRequest,
	[types.SET_TEAM_MEMBERS]: (state, { payload }) => {
		state.processing = false
		state.loading = false
		state.entities = payload
	},
	[types.SET_TEAM_MEMBER]: (state, { payload }) => {
		state.processing = false
		state.loading = false
		state.entity = payload
	},
	[types.LOAD_TEAM_MEMBERS_FAILED]: finishedRequest,
	[types.LOAD_TEAM_MEMBER_FAILED]: finishedRequest,
	[types.LOAD_TEAM_MEMBER_REQUEST]: (state) => {
		state.loading = true
		state.entity = null
	},
	[types.LOAD_TEAM_MEMBERS_REQUEST]: (state) => {
		state.loading = true
	},
	[types.UPDATE_TEAM_MEMBER_REQUEST]: (state) => {
		state.processing = true
	},
	[types.UPDATE_TEAM_MEMBER_FAILED]: (state) => {
		state.processing = false
	},
	[types.SET_TEAM_INVITATIONS]: (state, { payload }) => {
		state.loading = false
		state.invitations = payload.invitations
		state.hasTeam = payload.userHasTeam
	},
	[types.LOAD_INVITATIONS]: (state) => {
		state.loading = true
		state.invitations = []
	},
	[types.LOAD_INVITATIONS_FAILED]: (state) => {
		state.invitations = null
	},
})

export default teamsReducer
