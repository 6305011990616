import { put, call, takeLatest, select } from "redux-saga/effects"
import Web3 from "web3"
import Web3Modal from "web3modal"
import providerOptions from "constants/providerOptions"
import i18n from "i18n"
import { challengeApi } from "api/users"
import * as actions from "./actions"
import * as types from "./types"

import { selectWeb3Modal, selectWeb3Provider } from "./selectors"
import { authStateSelector } from "store/auth/selectors"
import { closeModalAction, openModalAction } from "store/modal/actions"
import {
	authenticateReqAction,
	authenticateFailAction,
} from "store/auth/actions"

import notify from "components/commons/notification"

/**
 * Initalize Web3Modal instance
 */
function* web3ModalInstance() {
	const web3Modal = new Web3Modal({
		cacheProvider: false,
		providerOptions,
		disableInjectedProvider: false,
		theme: "dark",
	})
	yield put(actions.web3ModalInitSuccess(web3Modal))
}

/**
 * Connect wallet provider
 */
function* web3ModalConnect({ payload: history }) {
	try {
		const web3Modal = yield select(selectWeb3Modal)
		if (web3Modal) {
			const provider = yield call(web3Modal.connect)
			yield put(actions.web3ModalInitSuccess({ ...web3Modal, show: true }))
			yield put(actions.web3ModalConnectSuccess(provider))
			yield call(handleAuth, history)
		}
	} catch (e) {
		console.log(e)
		if (e !== "Modal closed by user") {
			notify({ messageKey: "noWalletConnection" })
		}
	}
}

/**
 * Disconnect wallet provider
 */
function* web3ModalOnDisconnect() {
	try {
		localStorage.clear()
		const web3Modal = yield select(selectWeb3Modal)
		if (web3Modal?.cachedProvider) {
			yield call(web3Modal.clearCachedProvider)
		}
	} catch (err) {
		console.log("ERR", err)
	}
}

/**
 * if ethereum in not found send user to AuthModal
 * to enter email and password to create an account
 */
function* initWeb3(auth = false) {
	const provider = yield select(selectWeb3Provider)
	if (window.ethereum || window.web3) {
		if (auth && window.ethereum && provider?.isMetaMask)
			yield call(window.ethereum.enable)
		return new Web3(provider)
	}
	yield put(openModalAction({ type: "authModal", step: 2 }))
	return null
}

function* handleAuth(history) {
	try {
		const web3 = yield call(initWeb3, true)
		if (!web3) return
		const coinbase = yield call(web3.eth.getCoinbase)
		const publicAddress = coinbase.toLowerCase()
		const authState = yield select(authStateSelector)
		if (!authState.isLoggedIn) {
			yield call(findOrCreateAccount, web3, publicAddress, null, history)
		} else if (
			authState.isLoggedIn &&
			(!authState.user.publicAddress ||
				authState.user.publicAddress === publicAddress)
		) {
			yield call(
				findOrCreateAccount,
				web3,
				publicAddress,
				authState.user.email,
				history
			)
		} else {
			notify({ title: i18n.t("addressNotAssociated") })
		}
	} catch (err) {
		console.log(err)
	}
}

function randomHex(length) {
	const arr = new Uint8Array((length || 40) / 2)
	window.crypto.getRandomValues(arr)
	return Array.from(arr, (dec) => dec.toString(16).padStart(2, "0")).join("")
}

/**
 * Create client seed and signature to authenticate user's wallet
 * and credentials.
 */
function* findOrCreateAccount(ethereum, publicAddress, email, history) {
	try {
		const welcomeMetaMask = i18n.t("riotRacersSignIn")
		const clientSeed = yield call(randomHex, 40)
		const result = yield call(challengeApi, publicAddress, clientSeed)
		const { challengeText, id } = result.challenge
		const metaMaskMessage = `${welcomeMetaMask}\n${challengeText}\n${clientSeed}`
		const signature = yield call(
			ethereum.eth.personal.sign,
			metaMaskMessage,
			publicAddress,
			""
		)

		yield put(
			authenticateReqAction({
				publicAddress,
				signature,
				email,
				history,
				clientSeed,
				challengeId: id,
			})
		)
	} catch (err) {
		yield put(closeModalAction())
		yield put(authenticateFailAction(err))
	}
}

const web3Sagas = [
	takeLatest(types.WEB3_MODAL_INIT, web3ModalInstance),
	takeLatest(types.WEB3_MODAL_CONNECT_WALLET, web3ModalConnect),
	takeLatest(types.WEB3_MODAL_DISCONNECT, web3ModalOnDisconnect),
]

export default web3Sagas
