import { put, call, takeLatest } from "redux-saga/effects"
import * as actions from "./actions"
import * as api from "api/withdrawLimits"
import withdrawLimitsActionTypes from "./types"

function* getWithdrawLimitInit() {
	try {
		const { data } = yield call(api.getWithdrawLimits)
		yield put(actions.getWithdrawLimitSuccess(data))
	} catch (err) {
		yield put(actions.getWithdrawLimitFailure(err))
	}
}

const withdrawLimitSagas = [
	takeLatest(
		withdrawLimitsActionTypes.GET_WITHDRAW_LIMIT,
		getWithdrawLimitInit
	),
]

export default withdrawLimitSagas
