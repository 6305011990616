import React, { useMemo } from "react"
import IconCaretRight from "../Icons/IconCaretRight"

import "./TextButton.scss"

const RioTextButton = ({
	text,
	variant,
	className,
	onClick,
	hideIcon,
	disabled,
	children,
}) => {
	const classes = useMemo(() => {
		let baseStyle = "RioTextButton"
		if (variant) {
			baseStyle = `${baseStyle} ${variant}`
		}
		if (className) {
			baseStyle = `${baseStyle} ${className}`
		}

		if (disabled) {
			baseStyle = `${baseStyle} disabled`
		}
		return baseStyle
	}, [variant, className, disabled])

	return (
		<div className={classes} role="presentation" onClick={onClick}>
			{!text ? children : text}
			{!hideIcon && <IconCaretRight />}
		</div>
	)
}

export default RioTextButton
