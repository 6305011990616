import React from "react"

export default (props) => (
	<svg
		width="24px"
		height="24px"
		viewBox="0 0 512 336"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g fill="none">
			<path
				d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
				fill="#F5F5F5"
			/>
			<circle fill="#FF4B55" cx="256" cy="168" r="97.103" />
		</g>
	</svg>
)
