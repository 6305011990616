import { createReducer } from "@reduxjs/toolkit"
import * as types from "./types"

const initialState = {
	web3Modal: null,
	provider: null,
}

const web3Reducer = createReducer(initialState, {
	[types.WEB3_MODAL_INIT_SUCCESS]: (state, action) => {
		state.web3Modal = action.payload
	},
	[types.WEB3_MODAL_CONNECT_WALLET_SUCCESS]: (state, action) => {
		state.provider = action.payload
	},
	[types.WEB3_MODAL_DISCONNECT]: (state) => {
		// state.provider = null
	},
})

export default web3Reducer
