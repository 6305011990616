import { call, takeLatest, select, put } from "redux-saga/effects"
import ClubActionTypes from "./types"
import * as api from "../../api/club"
import i18n from "i18n"
import { selectClubLevels } from "./selectors"
import { getEthSelector } from "../auth/selectors"
import * as actions from "./actions"

import notify from "components/commons/notification"

const showNotify = (err) => {
	const errCode = err?.response?.data?.errorKey
	if (errCode) {
		notify({ messageKey: errCode })
	}
}

function* fetchClubLevels() {
	try {
		yield put(actions.rocLevelsLoading(true))
		const ethAddress = yield select(getEthSelector)
		const data = { ethAddress }
		const { data: levels } = yield call(api.getLevelsApi, data)
		const levelsWithLoader = levels.map((level) => ({
			...level,
			loading: false,
		}))
		yield put(actions.setROCLevels(levelsWithLoader))
	} catch (error) {
		yield put(actions.fetchLevelsFailed(error))
	} finally {
		yield put(actions.rocLevelsLoading(false))
	}
}

// payload = levelId
function* enrollClub({ payload: levelId }) {
	try {
		yield put(actions.levelLoading({ id: levelId, loading: true }))
		let currLevelName = null
		const publicAddress = yield select(getEthSelector)
		const data = { publicAddress, levelId }
		const levels = yield select(selectClubLevels)

		yield call(api.enrollClubApi, data)

		const newLevels = levels.map((level) => {
			if (level.levelId === levelId) {
				currLevelName = level.level
				return { ...level, active: true }
			}
			return { ...level, active: false }
		})

		yield put(actions.setROCLevels(newLevels))
		if (levelId === 0) {
			notify({
				type: "success",
				title: i18n.t("notification.unenrolledSuccess.title"),
				desc: i18n.t("notification.unenrolledSuccess.description"),
			})
		} else {
			notify({
				type: "success",
				title: i18n.t("notification.enrolledSuccess.title"),
				desc: i18n.t("notification.enrolledSuccess.description", {
					currLevelName,
				}),
			})
		}
	} catch (error) {
		yield call(showNotify, error)
		yield put(actions.enrollClubLevelFailed(error))
	} finally {
		yield put(actions.levelLoading({ id: levelId, loading: false }))
	}
}

// payload={ id: levelId, claimPrice: num }
function* claimTokens({ payload }) {
	try {
		yield put(actions.levelLoading({ id: payload.id, loading: true }))
		const ethAddress = yield select(getEthSelector)
		const riotBalance = parseFloat(payload.claimPrice)
		const status = yield call(api.getStatusApi, { ethAddress })
		if (status) {
			yield call(api.claimApi, { ethAddress, riotBalance })
			notify({
				type: "success",
				title: i18n.t("notification.tokensTransferSuccess.title"),
				desc: i18n.t("notification.tokensTransferSuccess.description"),
			})
		} else {
			notify({ title: i18n.t("notification.tokensTransferFail.title") })
		}
	} catch (error) {
		yield call(showNotify, error)
		yield put(actions.claimFailed(error))
		notify({ title: i18n.t("notification.tokensClaimFail.title") })
	} finally {
		yield put(actions.levelLoading({ id: payload.id, loading: false }))
	}
}

function* claimTokensStart() {
	yield takeLatest(ClubActionTypes.CLAIM_REQUEST, claimTokens)
}

function* enrollClubStart() {
	yield takeLatest(ClubActionTypes.ENROLL_CLUB_LEVEL, enrollClub)
}

function* fetchClubLevelsStart() {
	yield takeLatest(ClubActionTypes.GET_ROC_LEVELS, fetchClubLevels)
}

export default [
	call(fetchClubLevelsStart),
	call(enrollClubStart),
	call(claimTokensStart),
]
