import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { web3ModalConnect } from "store/web3Modal/actions"
// import RioButton from "components/commons/Button"
import { isAuthorizedSelector } from "store/auth/selectors"

import "./styles.scss"

const RioFooter = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { t } = useTranslation()
	const isAuthorized = useSelector(isAuthorizedSelector)

	const openModal = () => {
		dispatch(web3ModalConnect(history))
	}
	return (
		<div className="RioFooter">
			<div className={isAuthorized ? "Buttons Authorized" : "Buttons"}>
				{/* <div className="HelpButton">
          <RioButton>
            <IconChat />
            <span>{t("help")}</span>
          </RioButton>
        </div> */}
				{/* {!isAuthorized && (
					<RioButton onClick={openModal} className="StartButton">
						{t("start")}
					</RioButton>
				)} */}
			</div>
			<p className="copyright">{t("footer.text")}</p>
			<div className="links">
				<Link to="/about-us">{t("footer.aboutUs")}</Link>
				<Link to="/terms-of-service">{t("footer.terms")}</Link>
				<Link to="/privacy-policy">{t("footer.privacy")}</Link>
				<a href="https://whitepaper.riot.fun" target="_blank" rel="noreferrer">
					{t("footer.whitepaper")}
				</a>
				<a
					href="https://discord.gg/riotracers"
					target="_blank"
					rel="noreferrer"
				>
					{t("footer.discord")}
				</a>
			</div>
		</div>
	)
}

export default RioFooter
