export const initialState = {
	carState: {
		loading: true,
		internalLoad: false,
		error: undefined,
		car: {},
		property: {},
		selectedCar: null,
		currentCar: null,
	},
	driverState: {
		loading: true,
		internalLoad: false,
		error: undefined,
		driver: {},
		currentDriver: null,
	},
	modalState: {
		isOpen: false,
		type: null,
		step: null,
	},
	droplimits: {
		loading: true,
		error: undefined,
		types: {},
		typeCartLimits: {},
	},
	authState: {
		loading: true,
		error: undefined,
		user: {},
		isLoggedIn: false,
		isMetaMaskLoggedIn: false,
	},
	coingState: {
		loading: true,
		error: undefined,
		balances: {},
	},
	carsState: {
		loading: true,
		internalLoad: false,
		error: undefined,
		property: {},
		selectedCar: null,
		cars: {},
		currentCar: null,
	},
	activities: {
		loading: true,
		error: undefined,
		entities: [],
	},
	assets: {
		loading: true,
		error: undefined,
		cars: {},
		stations: {},
		lands: {},
		shops: {},
	},
	shops: {
		loading: true,
		error: undefined,
		shops: [],
	},
	lands: {
		loading: true,
		error: undefined,
		lands: [],
	},
	marketPlaceState: {
		loading: false,
		buyLoading: false, // TODO: REMOVE buyloading
		addingToCart: {},
		payLoading: false,
		deleteLoading: false,
		paidStatus: {},
		assetStatus: {},
		error: undefined,
		cart: {},
		orders: {},
		ordersLoading: false,
		transfers: {},
		transfersLoading: false,
		ethCart: {},
		ethLoading: {},
		riotCart: {},
		riotLoading: {},
	},
	settingsState: {
		loading: false,
		error: undefined,
		settings: {},
	},
}
