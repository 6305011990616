import React, { useMemo } from "react"

import "./iconButton.scss"

const IconButton = ({ onClick, icon, favBtn, label, className, actived }) => {
	const classes = useMemo(() => {
		let base = "rioIconButton"
		if (className) {
			base = `${base} ${className}`
		}
		if (favBtn) {
			base = `${base} fav`
		}
		if (actived) {
			base = `${base} actived`
		}
		return base
	}, [className, actived, favBtn])

	return (
		<div onClick={onClick} role="presentation" className={classes}>
			{icon}
			{label && <span>{label}</span>}
		</div>
	)
}

export default IconButton
