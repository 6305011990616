import { put, call, takeLatest } from "redux-saga/effects"
import TopNoticeActionTypes from "./types"
import { getTopNoticeSuccess, getTopNoticeFail } from "./actions"
import { getTopNoticeApi } from "api/topnotice"

function* getTopNoticeSaga() {
	try {
		const { data } = yield call(getTopNoticeApi)
		yield put(getTopNoticeSuccess(data))
	} catch (error) {
		yield put(getTopNoticeFail(error))
	}
}

const topNoticeSagas = [
	takeLatest(TopNoticeActionTypes.GET_TOP_NOTICE_REQ, getTopNoticeSaga),
]

export default topNoticeSagas
