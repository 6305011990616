import React from "react"

export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		{...props}
	>
		<path d="M14.468,24H9.532a.622.622,0,0,1-.618-.51l-.463-3.18a9.071,9.071,0,0,1-2.083-1.17L3.3,20.34a.692.692,0,0,1-.214.034.612.612,0,0,1-.542-.3L.073,15.916a.608.608,0,0,1,.155-.765l2.607-1.98A7.4,7.4,0,0,1,2.743,12a7.262,7.262,0,0,1,.108-1.17L.243,8.851a.57.57,0,0,1-.154-.765L2.558,3.93a.62.62,0,0,1,.756-.27l3.071,1.2A9.37,9.37,0,0,1,8.467,3.69L8.93.51a.6.6,0,0,1,.6-.51h4.936a.612.612,0,0,1,.618.51l.463,3.18a9.114,9.114,0,0,1,2.083,1.17l3.07-1.2a.692.692,0,0,1,.214-.034.612.612,0,0,1,.542.3l2.469,4.156a.608.608,0,0,1-.155.765l-2.607,1.98A7.27,7.27,0,0,1,21.257,12a9.519,9.519,0,0,1-.078,1.17l2.577,1.98a.57.57,0,0,1,.154.765L21.442,20.07a.62.62,0,0,1-.756.27l-3.071-1.2a9.282,9.282,0,0,1-2.083,1.17l-.463,3.18A.58.58,0,0,1,14.468,24ZM12,7.5A4.571,4.571,0,0,0,7.371,12a4.63,4.63,0,0,0,9.257,0A4.571,4.571,0,0,0,12,7.5Z" />
	</svg>
)
