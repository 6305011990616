import { useEffect } from "react"
import { Popover } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import useMetaMaskAuth from "hooks/useMetaMaskAuth"
import { formatNum } from "helper/number"

import { authStateSelector } from "store/auth/selectors"
import { getBalanceReqAction } from "store/coin/actions"
import { getWalletAction } from "store/wallet/actions"

import riotIconTurq from "assets/icons/riot-icon-turquoise.svg"
import { balanceSelector } from "store/wallet/selectors"
import { Link } from "react-router-dom"
import IconUSDC from "components/commons/Icons/IconUSDC"

const WalletValue = ({ value = "" }) => {
	const withPopover = value.toString().length > 9
	if (!withPopover) {
		return value
	}

	return (
		<Popover
			trigger="click"
			content={<span>{value}</span>}
			placement="bottom"
			getPopupContainer={(triger) => triger.parentNode}
		>
			<span style={{ fontWeight: "bold" }} className="truncate">
				{value}
			</span>
		</Popover>
	)
}

const UserBallance = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { getBalance } = useMetaMaskAuth()
	const { isMetaMaskLoggedIn } = useSelector(authStateSelector)
	const walletBalance = useSelector(balanceSelector)

	useEffect(() => {
		if (isMetaMaskLoggedIn) {
			dispatch(getWalletAction())
			getBalance()
		} else {
			dispatch(getBalanceReqAction())
		}
	}, [isMetaMaskLoggedIn])

	return (
		<div className="col">
			<div
				className="mr-4 mb-1 p-1 px-3 rounded-full"
				style={{ backgroundColor: "#1d2228" }}
			>
				<Link to="/dashboard/wallet?tab=assets" className="flex align-center">
					<div className="flex row">
						<div className="mr-2" style={{ minWidth: 20 }}>
							<img
								style={{ width: 20 }}
								alt="transaction icon"
								src={riotIconTurq}
							/>
						</div>
						<div className="text-sm text-aqua font-bold">
							<WalletValue
								value={formatNum(
									walletBalance?.walletResponse?.currentBalance || 0
								)}
							/>
						</div>
					</div>
				</Link>
			</div>
			<div
				className="mr-4 mt-1 p-1 px-3 rounded-full"
				style={{ backgroundColor: "#1d2228" }}
			>
				<Link to="/dashboard/wallet?tab=assets" className="flex align-center">
					<div className="flex row">
						<div className="mr-2" style={{ minWidth: 20 }}>
							<IconUSDC 
								style={{
									width: "20px",
								}}
								fill="var(--rio-secondary)"
							/>
						</div>
						<div className="text-sm text-aqua font-bold">
							<WalletValue
								value={formatNum(
									walletBalance?.walletResponse?.usdcCurrentBalance || 0
								)}
							/>
						</div>
					</div>
				</Link>
			</div>
		</div>
	)
}

export default UserBallance
