import { put, call, takeLatest } from "redux-saga/effects"
import notify from "components/commons/notification"
import { getProfileApi } from "api/users"
import * as api from "../../api/assets"
import { groupBy } from "lodash"
import profileTypes from "./types"
import * as actions from "./actions"

const showNotify = (err, top) => {
	const errCode = err?.response?.data?.errorKey
	if (errCode) {
		notify({ messageKey: errCode, top })
	}
}

// payload is username:string
function* getProfileSaga({ payload }) {
	try {
		const { data } = yield call(getProfileApi, payload)
		yield put(actions.getProfileSuccAction(data))
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(actions.getProfileFailAction(error?.response?.data))
		return
	}

	try {
		const assets = yield call(api.getAssetsUsername, payload)
		yield put(actions.getUserAssetsSuccAction(groupBy(assets, "assetType")))
	} catch (error) {
		yield put(actions.getUserAssetsFailAction(error))
	}
}

export default [takeLatest(profileTypes.GET_PROFILE_REQ, getProfileSaga)]
