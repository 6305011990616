import { createReducer } from "@reduxjs/toolkit"
import * as types from "./types"

const initialState = {
	processing: false,
	loadUserAssets: false,
	loading: false,
	entity: null,
	heat: null,
	error: false,
	entities: [],
	invites: [],
	tracks: [],
}

const sentRequest = (state) => {
	state.processing = true
	state.error = false
}

const finishedRequest = (state) => {
	state.processing = false
}

const tournamentsReducer = createReducer(initialState, {
	[types.UPDATE_TOURNAMENT_REQ]: sentRequest,
	[types.UPDATE_TOURNAMENT_FAILED]: finishedRequest,
	[types.CANCEL_TOURNAMENT_REQ]: sentRequest,
	[types.CANCEL_TOURNAMENT_FAILED]: finishedRequest,
	[types.INVITE_USER_REQ]: sentRequest,
	[types.INVITE_USER_REQ_FAILED]: finishedRequest,
	[types.REJECT_USER_REQ]: sentRequest,
	[types.REJECT_USER_REQ_FAILED]: (state) => {
		state.processing = false
		state.error = true
	},
	[types.REJECT_USER_REQ_FINISHED]: finishedRequest,
	[types.REJECT_INVITE_REQ]: sentRequest,
	[types.REJECT_INVITE_REQ_FAILED]: (state) => {
		state.processing = false
		state.error = true
	},
	[types.CREATE_TOURNAMENT_REQ]: sentRequest,
	[types.CREATE_TOURNAMENT_REQ_FINISHED]: (state) => {
		state.processing = false
		state.error = false
	},
	[types.CREATE_TOURNAMENT_FAILED]: (state) => {
		state.processing = false
		state.error = true
	},
	[types.ENTER_TOURNAMENT_REQ]: sentRequest,
	[types.ENTER_TOURNAMENT_REQ_FINISHED]: (state) => {
		state.processing = false
		state.error = false
	},
	[types.ENTER_TOURNAMENT_REQ_FAILED]: (state) => {
		state.processing = false
		state.error = true
	},
	[types.ENTER_TOURNAMENT_HEAT_REQ]: sentRequest,
	[types.ENTER_TOURNAMENT_HEAT_REQ_FINISHED]: (state) => {
		state.processing = false
		state.error = false
	},
	[types.ENTER_TOURNAMENT_HEAT_REQ_FAILED]: (state) => {
		state.processing = false
		state.error = true
	},
	[types.SET_TOURNAMENT]: (state, { payload }) => {
		state.processing = false
		state.loading = false
		state.entity = payload
	},
	[types.SET_TOURNAMENTS]: (state, { payload }) => {
		state.loading = false
		state.entities = payload
	},
	[types.LOAD_TOURNAMENT_REQ]: (state) => {
		state.error = false
		state.loading = true
		state.entity = null
	},
	[types.LOAD_TOURNAMENTS_REQ]: (state) => {
		state.error = false
		state.loading = true
		state.entities = []
	},
	[types.LOAD_TOURNAMENT_FAILED]: (state) => {
		state.error = true
		state.entity = null
	},
	[types.LOAD_TOURNAMENTS_FAILED]: (state) => {
		state.error = true
		state.entity = null
	},
	[types.LOAD_TOURNAMENT_HEAT_REQ]: (state) => {
		state.error = false
		state.loading = true
		state.heat = null
	},
	[types.LOAD_TOURNAMENT_HEAT_FAILED]: (state) => {
		state.error = true
		state.heat = null
	},
	[types.SET_TOURNAMENT_HEAT]: (state, { payload }) => {
		state.loading = false
		state.heat = payload
	},
	[types.LOAD_INVITATIONS_REQ]: (state) => {
		state.error = false
		state.loading = true
		state.invites = []
	},
	[types.SET_INVITATIONS]: (state, { payload }) => {
		state.loading = false
		state.invites = payload
	},
	[types.LOAD_INVITATIONS_FAILED]: (state) => {
		state.error = true
		state.invites = null
	},
	[types.LOAD_TRACKS_REQ]: (state) => {
		state.error = false
		state.loading = true
		state.tracks = []
	},
	[types.SET_TRACKS]: (state, { payload }) => {
		state.loading = false
		state.tracks = payload
	},
	[types.LOAD_TRACKS_FAILED]: (state) => {
		state.error = true
		state.tracks = null
	},
})

export default tournamentsReducer
