import React from "react"

export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="28"
		height="24"
		viewBox="0 0 28 24"
		{...props}
	>
		<path
			d="M16.25,4.5A14,14,0,0,0,5.769,27.775c.069.075.131.15.2.219a1.574,1.574,0,0,0,2.319-.006,10.82,10.82,0,0,1,15.925,0,1.574,1.574,0,0,0,2.319.006l.2-.219A14,14,0,0,0,16.25,4.5Zm-.875,2.869a.875.875,0,0,1,1.75,0v2.25a.875.875,0,0,1-1.75,0Zm-8,12H5.125a.875.875,0,1,1,0-1.75h2.25a.875.875,0,0,1,0,1.75Zm3.219-6.531h0a.883.883,0,0,1-1.237,0L7.762,11.25a.883.883,0,0,1,0-1.238h0a.883.883,0,0,1,1.237,0l1.594,1.594A.875.875,0,0,1,10.594,12.838Zm10.038,2.156-2.969,4.719a1.937,1.937,0,0,1-.437.438,1.882,1.882,0,1,1-2.188-3.062l4.719-2.969a.639.639,0,0,1,.731,0A.629.629,0,0,1,20.631,14.994Zm2.512-2.156a.883.883,0,0,1-1.237,0h0a.883.883,0,0,1,0-1.238L23.5,10.006a.883.883,0,0,1,1.238,0h0a.883.883,0,0,1,0,1.237Zm4.231,6.531h-2.25a.875.875,0,0,1,0-1.75h2.25a.875.875,0,0,1,0,1.75Z"
			transform="translate(-2.25 -4.5)"
		/>
	</svg>
)
