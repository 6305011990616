import { createReducer } from "@reduxjs/toolkit"
import { addOrReplaceAsset } from "helper/addOrReplace"
import assetsActionTypes from "./types"

const initialState = {
	loading: true,
	error: undefined,
	cars: {},
	cryptowhips: {},
	stations: {},
	lands: {},
	shops: {},
	drivers: {},
	billboardlands: {},
	selectedCar: null,
	currentAsset: null,
	assetNameCheck: null,
	assetNameUpdate: null,
	currentName: null,
	carUpgrades: null,
}

const requestSent = (state) => {
	state.loading = true
}

const requestSuccess = (state, action) => {
	const { assetType, data } = action.payload
	state.loading = false
	state.error = null
	state[assetType] = data
}

const requestFailed = (state, action) => {
	state.loading = false
	state.error = action.payload
}

const checkAssetNameSucc = (state, action) => {
	const { name, data } = action.payload
	state.loading = false
	state.error = null
	state.currentName = name
	state.assetNameUpdate = null
	state.assetNameCheck = data
}

const checkAssetNameFail = (state, action) => {
	state.loading = false
	state.error = action.payload
	state.assetNameCheck = action.payload
}

const updateAssetNameSucc = (state, action) => {
	const { name, assetType, data } = action.payload
	state.loading = false
	state.error = null
	state.currentName = name
	state.assetNameCheck = null
	state.assetNameUpdate = true
	state[assetType] = addOrReplaceAsset(state[assetType], data)
}

const updateAssetNameFail = (state, action) => {
	state.loading = false
	state.error = action.payload
	state.assetNameUpdate = action.payload
}

const toggleFavoriteSucc = (state, action) => {
	const { assetType, data, assetId } = action.payload
	state.loading = false
	state.error = null
	state[assetType].assets.rows = state[assetType].assets.rows.map((d) =>
		d.assetId === assetId
			? { ...d, favorite: !!data.favorite, updatedAt: data.updatedAt }
			: d
	)
}
const updateAsset = (state, action) => {
	const { assetType, assetId, key, value } = action.payload
	state.loading = false
	state.error = null
	state[assetType].assets.rows = state[assetType].assets.rows.map((asset) =>
		asset.id === assetId ? { ...asset, [key]: value } : asset
	)
}

const deleteAssetRental = (state, action) => {
	const { assetType, rentalId } = action.payload
	state.loading = false
	state.error = null
	state[assetType].rentals.rows = state[assetType].rentals.rows.filter(
		(asset) => asset.id !== rentalId
	)
}

const assetsReducer = createReducer(initialState, {
	[assetsActionTypes.GET_ASSETS_REQ]: requestSent,
	[assetsActionTypes.GET_ASSETS_SUCC]: requestSuccess,
	[assetsActionTypes.GET_ASSETS_FAIL]: requestFailed,
	[assetsActionTypes.GET_ACTIVE_CAR_REQ]: requestSent,
	[assetsActionTypes.SET_ACTIVE_CAR_REQ]: requestSent,
	[assetsActionTypes.GET_CHECK_ASSET_NAME_SUCC]: checkAssetNameSucc,
	[assetsActionTypes.GET_CHECK_ASSET_NAME_FAIL]: checkAssetNameFail,
	[assetsActionTypes.UPDATE_ASSET_NAME_SUCC]: updateAssetNameSucc,
	[assetsActionTypes.UPDATE_ASSET_NAME_FAIL]: updateAssetNameFail,
	[assetsActionTypes.TOGGLE_ASSET_FAVORITE_SUCC]: toggleFavoriteSucc,
	[assetsActionTypes.UPDATE_ASSET]: updateAsset,
	[assetsActionTypes.DELETE_ASSET_RENTAL]: deleteAssetRental,
	[assetsActionTypes.GET_ASSETS]: (state) => {
		state.loading = true
		state.error = undefined
	},
	[assetsActionTypes.GET_ASSETS_SUCCESS]: (state, action) => {
		const { ASSET_STATE_KEY, data } = action.payload
		state.error = undefined
		state.loading = false
		state[ASSET_STATE_KEY] = data
	},
	[assetsActionTypes.GET_ASSETS_FAILED]: (state, action) => {
		state.loading = false
		state.error = action.error
	},
})

export default assetsReducer
