import React from "react"

export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			d="M15,3A12,12,0,1,0,27,15,12,12,0,0,0,15,3ZM13.8,19.8H11.4V10.2h2.4Zm4.8,0H16.2V10.2h2.4Z"
			transform="translate(-3 -3)"
		/>
	</svg>
)
