import { createReducer } from "@reduxjs/toolkit"
import conversionActionTypes from "./types"

const initialState = {
	latest: {
		loading: false,
		error: undefined,
		conversions: {},
	},

	conversion: {
		loading: false,
		error: undefined,
		conversion: null,
	},
}

const conversionReducer = createReducer(initialState, {
	[conversionActionTypes.GET_LATEST_CONVERSION]: (state) => {
		state.latest.loading = true
		state.latest.error = undefined
		state.latest.conversions = {}
	},
	[conversionActionTypes.GET_LATEST_CONVERSION_SUCCESS]: (state, action) => {
		const conversions = { [action.payload.data.fromCurrency]: action.payload }
		state.latest.loading = false
		state.latest.error = undefined
		state.latest.conversions = { ...state.latest.conversions, ...conversions }
	},
	[conversionActionTypes.GET_LATEST_CONVERSION_FAILED]: (state, action) => {
		state.latest.loading = false
		state.latest.error = action.error
	},
	[conversionActionTypes.GET_CONVERSION]: (state) => {
		state.conversion.loading = true
	},
	[conversionActionTypes.GET_CONVERSION_SUCCESS]: (state, action) => {
		state.conversion.loading = false
		state.conversion.error = undefined
		state.conversion.conversion = action.payload
	},
	[conversionActionTypes.GET_CONVERSION_FAILURE]: (state) => {
		state.conversion.loading = false
		state.conversion.error = null
		state.conversion.conversion = null
	},
})

export default conversionReducer
