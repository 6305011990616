import { createAction } from "@reduxjs/toolkit"
import {
  GET_DROPLIMITS,
  GET_DROPLIMITS_SUCCESS,
  GET_DROPLIMITS_FAILED,
} from "./types"

export const getDropLimitsRequest = createAction(GET_DROPLIMITS)
export const getDropLimitsSuccess = createAction(GET_DROPLIMITS_SUCCESS)
export const getDropLimitsFailure = createAction(GET_DROPLIMITS_FAILED)
