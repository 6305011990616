import { createReducer } from "@reduxjs/toolkit"
import GroupActionTypes from "./types"

const initState = {
	detail: {},
	loaded: true,
	sent: false,
	groupRegisteringLoading: false,
}

const groupReducer = createReducer(initState, {
	[GroupActionTypes.GROUP_REGISTER_LOADING]: (state, { payload }) => {
		state.groupRegisteringLoading = payload
	},

	[GroupActionTypes.REGISTER_GROUP]: (state) => {
		state.sent = true
	},

	[GroupActionTypes.GET_GROUP_STATUS_FAILED]: (state) => {
		state.sent = false
	},

	[GroupActionTypes.GET_GROUP_STATUS]: (state) => {
		state.loaded = false
	},

	[GroupActionTypes.REGISTER_GROUP_FAILED]: (state) => {
		state.loaded = true
	},

	[GroupActionTypes.SET_GROUP]: (state, { payload }) => {
		state.detail = payload
		state.loaded = true
		state.sent = false
	},
})

export default groupReducer
