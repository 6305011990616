import { useState, useEffect } from "react"
import useWindowDimensions from "hooks/useWindowDimensions"
import { IPAD_PORTRAIT } from "constants/breakPoints"
import {
	view as billboardViewApi,
	increment as billboardIncApi,
} from "api/billboard"
import { Button } from "antd"
import IconCaretLeft from "./commons/Icons/IconCaretLeft"

const BillboardBanner = () => {
	const { width } = useWindowDimensions()
	const isSmallDevice = width <= IPAD_PORTRAIT
	const [billboards, setBillboards] = useState([])
	const [activeBillboardIndex, setActiveBillboardIndex] = useState(0)
	const [, setBillboardsLoading] = useState(true)
	const [timer, setTimer] = useState()

	const fetchBillboards = async () => {
		try {
			setBillboardsLoading(true)
			const { data } = await billboardViewApi()
			setBillboards(data.result)
		} catch (err) {
			//
		} finally {
			setBillboardsLoading(false)
		}
	}

	const onBanner = async (id, url) => {
		try {
			window.open(`${url}`)
			await billboardIncApi(id)
		} catch (err) {
			//
		}
	}

	const initTimer = async () => {
		const interval = setInterval(() => {
			if (activeBillboardIndex + 1 > billboards.length) {
				clearInterval(interval)
				fetchBillboards()
			} else {
				next()
			}
		}, 10000)
		setTimer(interval)
	}

	const next = () =>
		setActiveBillboardIndex((index) => (index + 1) % billboards.length)

	const prev = () =>
		setActiveBillboardIndex((index) => {
			if (index === 0) {
				return billboards.length - 1
			}
			return index - 1
		})

	useEffect(() => {
		fetchBillboards()
	}, [])

	useEffect(() => {
		if (billboards?.length > 0) {
			initTimer()
			setActiveBillboardIndex(0)
		}
		return () => clearInterval(timer)
	}, [billboards])

	return (
		<div>
			{billboards?.length > 0 && (
				<div
					key={`global_banner_${billboards[activeBillboardIndex].id}`}
					className="cursor-pointer relative"
				>
					<div
						style={!isSmallDevice ? { height: 90 } : { height: 50 }}
						onClick={() =>
							onBanner(
								billboards[activeBillboardIndex].id,
								billboards[activeBillboardIndex].url
							)
						}
					>
						{!isSmallDevice ? (
							<img
								// style={{ "object-fit": "none" }}
								// className="w-full h-full object-center object-cover"
								className="w-full h-full object-center object-none"
								alt="banner"
								src={billboards[activeBillboardIndex].desktop_image.uri}
							/>
						) : (
							<img
								// style={{ "object-fit": "none" }}
								// className="w-full h-full object-center object-cover"
								className="w-full h-full object-center object-none"
								alt="banner"
								src={billboards[activeBillboardIndex].mobile_image.uri}
							/>
						)}
					</div>

					<Button
						type="ghost"
						className="border-none absolute left-0 top-8"
						style={{ zIndex: 2 }}
						onClick={prev}
					>
						<IconCaretLeft fill="#fff" />
					</Button>
					<Button
						type="ghost"
						onClick={next}
						className="transform rotate-180 border-none absolute right-0 top-8"
						style={{ zIndex: 2 }}
					>
						<IconCaretLeft fill="#fff" />
					</Button>
				</div>
			)}
		</div>
	)
}

export default BillboardBanner
