import i18n from "i18n"

import IconOverview from "components/commons/Icons/IconOverview"
import IconGarage from "components/commons/Icons/IconGarage"
import IconAssets from "components/commons/Icons/IconAssets"
import IconSetting from "components/commons/Icons/IconSetting"
import IconRio from "components/commons/Icons/IconRio"
import IconShop from "components/commons/Icons/IconShop"
import IconRace from "components/commons/Icons/IconRace"
import IconUser from "components/commons/Icons/IconUser"
import IconDrivers from "components/commons/Icons/IconDrivers"
import IconCirInfo from "components/commons/Icons/IconCirInfo"
import IconTeam from "components/commons/Icons/IconTeam"
import IconBuyACar from "components/commons/Icons/IconBuyACar"
import IconJoinTheCommunity from "components/commons/Icons/IconJoinTheCommunity"
import IconLatestNews from "components/commons/Icons/IconLatestNews"
import IconWhitePaper from "components/commons/Icons/IconWhitePaper"
import IconLigtning from "components/commons/Icons/IconLigtning"
import IconMechanicShop from "components/commons/Icons/IconMechanicShop"

import quickStartImg from "assets/images/Card_BG/1_Card_BG_Quick_start_Guide.svg"
import buyADriverImg from "assets/images/Card_BG/2_Card_BG_RR_Buy_a_Driver.svg"
import buyACarImg from "assets/images/Card_BG/3_Card_BG_Buy_a_Car.svg"
import latestNewsImg from "assets/images/Card_BG/4_Card_BG_RR_Latest_News.svg"
import communityImg from "assets/images/Card_BG/5_Card_BG_Community.svg"
import whitepaperImg from "assets/images/Card_BG/6_Card_BG_RR_Whitepaper.svg"
import IconDriversRoc from "components/commons/Icons/IconDriversRoc"
import IconRewards from "components/commons/Icons/IconRewards"
import IconWallet from "components/commons/Icons/IconWallet"
import IconBell from "components/commons/Icons/IconBell"
import IconAchievement from "components/commons/Icons/IconAchievement"
import IconTreasure from "components/commons/Icons/IconTreasure"

export const getUserMenu = (username) => [
	{
		title: i18n.t("overview"),
		route: "/dashboard/overview",
		icon: <IconOverview />,
	},
	{
		title: i18n.t("myprofile"),
		route: `/rr/${username}`,
		icon: <IconUser />,
	},
	// { title: i18n.t("wallet"), route: "/dashboard/wallet", icon: <IconOverview />, },
	{
		title: i18n.t("assets.drivers.title"),
		route: "/dashboard/drivers",
		icon: <IconDrivers />,
	},
	{
		title: i18n.t("garage.pageTitle"),
		route: "/dashboard/garage",
		icon: <IconGarage />,
	},
	{
		title: i18n.t("mechanicshop.title"),
		route: "/dashboard/mechanicshop",
		icon: <IconMechanicShop />,
	},
	...(process.env.REACT_APP_DISABLE_BREEDING !== "true"
		? [
				{
					title: i18n.t("breeding"),
					route: "/dashboard/breeding",
					icon: <IconLigtning />,
				},
		  ]
		: []),
	{
		title: i18n.t("assets.pageTitle"),
		route: "/dashboard/assets",
		icon: <IconAssets />,
	},
	{
		title: i18n.t("ownerclub.menu"),
		route: "/dashboard/owners-club",
		icon: <IconRewards />,
	},
	/** TODO: Finish feature before routing the Activity */
	// {
	//   title: i18n.t("activity.pageTitle"),
	//   route: "/dashboard/activity",
	//   icon: <IconActivity />,
	// },
	{
		title: i18n.t("lootbox.pageTitle"),
		route: "/dashboard/lootbox",
		icon: <IconTreasure />,
	},
	{
		title: i18n.t("team.pageTitle"),
		route: "/dashboard/team",
		icon: <IconTeam />,
	},
	{
		title: "Notifications",
		route: "/dashboard/invitations",
		icon: <IconBell />,
	},
	{
		title: i18n.t("achievementsTitle"),
		route: "/dashboard/achievements",
		icon: <IconAchievement />,
	},
	{
		title: i18n.t("wallet"),
		route: "/dashboard/wallet",
		icon: <IconWallet />,
	},
	{
		title: i18n.t("settings.title"),
		route: "/dashboard/setting",
		icon: <IconSetting />,
	},
	// {
	//   title: i18n.t("claim-tokens"),
	//   route: "/dashboard/claim-tokens",
	//   icon: <IconActivity />,
	// },
]

export const getMainMenu = () => [
	{
		title: i18n.t("menu.marketplace"),
		link: "/marketplace",
		icon: <IconShop />,
		activeKey: "marketplace",
	},
	{
		title: i18n.t("menu.myAccount"),
		link: "/dashboard/overview",
		icon: <IconOverview />,
		activeKey: "dashboard",
	},
	{
		title: i18n.t("menu.learn"),
		link: "/learn",
		icon: <IconRio />,
		activeKey: "learn",
	},
	/* {
    title: i18n.t("menu.theWorld"),
    icon: <IconRio />,
    menus: [
      { title: i18n.t("menu.fuelMe"), link: "/world" },
      {
        title: i18n.t("menu.visitMechanic"),
        link: "/world/mechanic",
      },
      { title: i18n.t("menu.theTracks"), link: "/world/tracks" },
      {
        title: i18n.t("menu.billboards"),
        link: "/world/billboards",
      },
    ],
  }, */
	{
		title: i18n.t("menu.letRace"),
		link: "/race",
		icon: <IconRace />,
		activeKey: "race",
	},
	{ title: i18n.t("menu.rent"), link: "/rent", activeKey: "rent" },
	{
		title: i18n.t("menu.whitepaper"),
		url: "http://whitepaper.riotracers.com/",
	},
]

export const getLearnLinks = () => [
	{
		// title: i18n.t("menu.learn"),
		// desc: i18n.t("learn.description")
		title: "Quick Start",
		desc: "Get up to speed on Riot Racers quick by reading this one page guide.",
		link: "https://quickstart.riotracers.com",
		img: quickStartImg,
		icon: <IconCirInfo />,
	},
	{
		// title: i18n.t("menu.learn"),
		// desc: i18n.t("learn.description")
		title: "Buy a Driver",
		desc: "Learn more about Drivers and find one to buy.",
		link: "/drivers",
		img: buyADriverImg,
		icon: <IconDriversRoc />,
	},
	{
		// title: i18n.t("menu.learn"),
		// desc: i18n.t("learn.description")
		title: "Buy a Car",
		desc: "Learn more about Cars and find one to buy.",
		link: "https://opensea.io/collection/riot-racers-cars",
		img: buyACarImg,
		icon: <IconBuyACar />,
	},
	{
		// title: i18n.t("menu.learn"),
		// desc: i18n.t("learn.description")
		title: "Latest News",
		desc: "Read the blog to get all the latest news.",
		link: "https://news.riotracers.com",
		img: latestNewsImg,
		icon: <IconLatestNews />,
	},
	{
		// title: i18n.t("menu.learn"),
		// desc: i18n.t("learn.description")
		title: "Join the Community",
		desc: "There is an awesome community of race fans on Discord. Join us!",
		link: "https://discord.gg/riotracers",
		img: communityImg,
		icon: <IconJoinTheCommunity />,
	},
	{
		// title: i18n.t("menu.learn"),
		// desc: i18n.t("learn.description")
		title: "Read the Whitepaper",
		desc: "Looking for super detailed info about the game? Here is where you will find it.",
		link: "https://whitepaper.riotracers.com",
		img: whitepaperImg,
		icon: <IconWhitePaper />,
	},
]
