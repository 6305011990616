const ClubActionTypes = {
	GET_ROC_LEVELS: "[roc] get roc levels request",
	SET_ROC_LEVELS: "[roc] set roc levels",
	GET_LEVELS_FAILED: "[roc] get roc levels failed",
	ENROLL_CLUB_LEVEL: "[roc] enroll club level",
	ENROLL_CLUB_FAILED: "[roc] enroll club failed",
	CLAIM_REQUEST: "[roc] claim request",
	CLAIM_FAILED: "[roc] claim failed",
	ROC_LEVELS_LOADING: "[roc] levels loading",
	LEVEL_LOADING: "[roc] level loading",
}

export default ClubActionTypes
