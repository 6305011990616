import React from "react"

export default (props) => (
	<svg
		width="40.808"
		height="24"
		viewBox="0 0 40.808 24"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g transform="translate(14.539 0)">
			<path
				d="M88.7,42.866a.769.769,0,0,0-.554-.434,7.058,7.058,0,0,1-3.117-1.447l-1.431,2.478a8.355,8.355,0,0,0,3.945,1.945c.019,0,.035.011.053.016l1.07-1.854A.765.765,0,0,0,88.7,42.866Z"
				transform="translate(-62.496 -33.073)"
			/>
			<path
				d="M80.545,49.418l-1.108,1.918a8.353,8.353,0,0,0,3.945,1.945.558.558,0,0,1,.054.016l1.082-1.874a9.412,9.412,0,0,1-2.545-1.011A11.031,11.031,0,0,1,80.545,49.418Z"
				transform="translate(-60.224 -37.682)"
			/>
			<path
				d="M73.348,42.327l-1.092,1.891c.414.4.811.822,1.2,1.236.3.317.6.63.9.935L75.452,44.5c-.419-.41-.822-.832-1.221-1.252C73.937,42.934,73.644,42.626,73.348,42.327Z"
				transform="translate(-56.299 -33.806)"
			/>
			<path
				d="M77.822,33.912l-1.41,2.442c.414.405.811.822,1.2,1.236.3.317.6.63.9.935l1.415-2.45c-.3-.3-.6-.614-.9-.93C78.637,34.73,78.241,34.313,77.822,33.912Z"
				transform="translate(-58.571 -29.207)"
			/>
			<path
				d="M50.984,25.52l.482-.835a.767.767,0,0,0-1.329-.767L37.167,46.384a.767.767,0,1,0,1.328.767l6.788-11.758a7.062,7.062,0,0,1,4.219,1.58l1.575-2.728A8.327,8.327,0,0,0,47.134,32.3c-.019,0-.035-.011-.054-.016l.8-1.389A9.749,9.749,0,0,1,51.855,32.9l1.106-1.917a8.332,8.332,0,0,0-3.943-1.95c-.019,0-.035-.011-.054-.016l.8-1.389a9.75,9.75,0,0,1,3.973,2.006l1.392-2.41A8.471,8.471,0,0,0,50.984,25.52Z"
				transform="translate(-37.064 -23.535)"
			/>
			<path
				d="M68.628,49.527l-1.559,2.7c.3.3.591.6.887.916.4.418.8.84,1.223,1.245L70.733,51.7c-.419-.41-.822-.832-1.22-1.252C69.217,50.134,68.924,49.826,68.628,49.527Z"
				transform="translate(-53.464 -37.742)"
			/>
			<path
				d="M75.869,56.619l-1.538,2.664a9.367,9.367,0,0,0,.948.63,8.358,8.358,0,0,0,2.659.981.767.767,0,0,0,.807-.37l1.1-1.9A9.4,9.4,0,0,1,77.3,57.613,11.094,11.094,0,0,1,75.869,56.619Z"
				transform="translate(-57.433 -41.618)"
			/>
		</g>
		<g transform="translate(0 0)">
			<path
				d="M5.07,42.866a.769.769,0,0,1,.554-.434,7.058,7.058,0,0,0,3.117-1.447l1.431,2.478a8.355,8.355,0,0,1-3.945,1.945c-.019,0-.035.011-.053.016L5.1,43.569A.765.765,0,0,1,5.07,42.866Z"
				transform="translate(-5 -33.073)"
			/>
			<path
				d="M13.331,49.418l1.108,1.918a8.353,8.353,0,0,1-3.945,1.945.558.558,0,0,0-.054.016L9.358,51.423A9.412,9.412,0,0,0,11.9,50.412,11.032,11.032,0,0,0,13.331,49.418Z"
				transform="translate(-7.382 -37.682)"
			/>
			<path
				d="M22.8,42.327l1.092,1.891c-.414.4-.811.822-1.2,1.236-.3.317-.6.63-.9.935L20.695,44.5c.419-.41.822-.832,1.221-1.252C22.21,42.934,22.5,42.626,22.8,42.327Z"
				transform="translate(-13.579 -33.806)"
			/>
			<path
				d="M17.936,33.912l1.41,2.442c-.414.405-.811.822-1.2,1.236-.3.317-.6.63-.9.935l-1.415-2.45c.3-.3.6-.614.9-.93C17.121,34.73,17.517,34.313,17.936,33.912Z"
				transform="translate(-10.918 -29.207)"
			/>
			<path
				d="M27.236,25.52l-.482-.835a.767.767,0,0,1,1.329-.767L41.054,46.385a.767.767,0,1,1-1.328.767L32.937,35.394a7.062,7.062,0,0,0-4.219,1.58l-1.575-2.728A8.323,8.323,0,0,1,31.087,32.3c.019,0,.035-.011.054-.016l-.8-1.389A9.749,9.749,0,0,0,26.365,32.9l-1.106-1.917a8.328,8.328,0,0,1,3.943-1.95c.019,0,.035-.011.054-.016l-.8-1.389a9.749,9.749,0,0,0-3.973,2.006l-1.392-2.41A8.471,8.471,0,0,1,27.236,25.52Z"
				transform="translate(-14.887 -23.535)"
			/>
			<path
				d="M26.955,49.527l1.559,2.7c-.3.3-.591.6-.887.916-.4.418-.8.84-1.223,1.245L24.85,51.7c.42-.41.822-.832,1.22-1.252C26.366,50.134,26.659,49.826,26.955,49.527Z"
				transform="translate(-15.85 -37.742)"
			/>
			<path
				d="M17.488,56.619l1.538,2.664a9.37,9.37,0,0,1-.948.63,8.358,8.358,0,0,1-2.659.981.767.767,0,0,1-.807-.37l-1.1-1.9a9.4,9.4,0,0,0,2.544-1.011A11.1,11.1,0,0,0,17.488,56.619Z"
				transform="translate(-9.654 -41.618)"
			/>
		</g>
	</svg>
)
