export const GET_RACES = "GET_RACES"
export const GET_RACES_SUCCESS = "GET_RACES_SUCCESS"
export const GET_RACES_FAILED = "GET_RACES_FAILED"

export const GET_WINNINGS = "GET_WINNINGS"
export const GET_WINNINGS_SUCCESS = "GET_WINNINGS_SUCCESS"
export const GET_WINNINGS_FAILED = "GET_WINNINGS_FAILED"

export const GET_RESULTS = "GET_RESULTS"
export const GET_RESULTS_SUCCESS = "GET_RESULTS_SUCCESS"
export const GET_RESULTS_FAILED = "GET_RESULTS_FAILED"

export const ENTER_RACE = "ENTER_RACE"
export const ENTER_RACE_SUCCESS = "ENTER_RACE_SUCCESS"
export const ENTER_RACE_FAILED = "ENTER_RACE_FAILED"

export const EXIT_RACE = "EXIT_RACE"
export const EXIT_RACE_SUCCESS = "EXIT_RACE_SUCCESS"
export const EXIT_RACE_FAILED = "EXIT_RACE_FAILED"

export const UPDATE_RACE = "UPDATE_RACE"
export const UPDATE_RACE_SUCCESS = "UPDATE_RACE_SUCCESS"
export const UPDATE_RACE_FAILED = "UPDATE_RACE_FAILED"

export const GET_MY_RACES = "GET_MY_RACES"
export const GET_MY_RACES_SUCCESS = "GET_MY_RACES_SUCCESS"
export const GET_MY_RACES_FAILED = "GET_MY_RACES_FAILED"


export const GET_SHARDS = "GET_SHARDS"
export const GET_SHARDS_SUCCESS = "GET_SHARDS_SUCCESS"
export const GET_SHARDS_FAILED = "GET_SHARDS_FAILED"


export const GET_MY_WINNINGS = "GET_MY_WINNINGS"
export const GET_MY_WINNINGS_SUCCESS = "GET_MY_WINNINGS_SUCCESS"
export const GET_MY_WINNINGS_FAILED = "GET_MY_WINNINGS_FAILED"

export const FETCH_MY_ENTRIES_LOADING = "FETCH_MY_ENTRIES_LOADING"
export const FETCH_MY_ENTRIES = "FETCH_MY_ENTRIES"
export const FETCH_MY_ENTRIES_SUCCESS = "FETCH_MY_ENTRIES_SUCCESS"
export const FETCH_MY_ENTRIES_FAILED = "FETCH_MY_ENTRIES_FAILED"

export const FETCH_MY_STRATEGIES_LOADING = "FETCH_MY_STRATEGIES_LOADING"
export const FETCH_MY_STRATEGIES = "FETCH_MY_STRATEGIES"
export const FETCH_MY_STRATEGIES_SUCCESS = "FETCH_MY_STRATEGIES_SUCCESS"
export const FETCH_MY_STRATEGIES_FAILED = "FETCH_MY_STRATEGIES_FAILED"

export const SAVE_NEW_STRATEGY_LOADING = "SAVE_NEW_STRATEGY_LOADING"
export const SAVE_NEW_STRATEGY = "SAVE_NEW_STRATEGY"
export const SAVE_NEW_STRATEGY_SUCCESS = "SAVE_NEW_STRATEGY_SUCCESS"
export const SAVE_NEW_STRATEGY_FAILED = "SAVE_NEW_STRATEGY_FAILED"

export const DELETE_STRATEGY = "SAVE_NEW_STRATEGY_FAILED"
export const DELETE_STRATEGY_SUCCESS = "DELETE_STRATEGY_SUCCESS"
export const DELETE_STRATEGY_FAIL = "DELETE_STRATEGY_FAIL"

export const STRATEGY_MODAL = "STRATEGY_MODAL"

export const FETCH_USER_RACE_ENTRY = "FETCH_USER_RACE_ENTRY"
export const FETCH_USER_RACE_ENTRY_SUCCESS = "FETCH_USER_RACE_ENTRY_SUCCESS"
export const FETCH_USER_RACE_ENTRY_FAIL = "FETCH_USER_RACE_ENTRY_FAIL"

export const FETCH_RACE_ENTRY = "FETCH_RACE_ENTRY"
export const FETCH_RACE_ENTRY_SUCCESS = "FETCH_RACE_ENTRY_SUCCESS"
export const FETCH_RACE_ENTRY_FAIL = "FETCH_RACE_ENTRY_FAIL"
export const LOAD_RACE = "LOAD_RACE"
export const LOAD_RACE_SUCCESS = "LOAD_RACE_SUCCESS"
export const LOAD_RACE_FAILED = "LOAD_RACE_FAILED"

export const FETCH_RACE_ENTRY_STATUS = "FETCH_RACE_ENTRY_STATUS"
export const FETCH_RACE_ENTRY_STATUS_SUCCESS =
	"FETCH_RACE_ENTRY_STATUS_COMPLETE"
export const FETCH_RACE_ENTRY_STATUS_FAILED = "FETCH_RACE_ENTRY_STATUS_FAILED"
