export default ({ fill = "#ababab", size = 16, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		viewBox="0 0 16 16"
		{...props}
	>
		<path
			d="M9,6l3.994,3.994L9,13.988"
			transform="translate(-2.997 -1.994)"
			fill={fill}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
		/>
	</svg>
)
