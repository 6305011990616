import { createAction } from "@reduxjs/toolkit"
import discordActionTypes from "./types"

export const redirectDiscordCbStart = createAction(
	discordActionTypes.REDIRECT_DISCORD_CALLBACK_START
)

export const redirectDiscordCbSuccess = createAction(
	discordActionTypes.REDIRECT_DISCORD_CALLBACK_SUCCESS
)

export const redirectDiscordCbFailure = createAction(
	discordActionTypes.REDIRECT_DISCORD_CALLBACK_FAILURE
)

export const redirectDiscordCbLoading = createAction(
	discordActionTypes.REDIRECT_DISCORD_CALLBACK_LOADING
)

export const unlinkDiscordStart = createAction(
	discordActionTypes.UNLINK_DISCORD_START
)
export const unlinkDiscordSuccess = createAction(
	discordActionTypes.UNLINK_DISCORD_SUCCESS
)
export const unlinkDiscordFailure = createAction(
	discordActionTypes.UNLINK_DISCORD_FAILURE
)

export const syncDiscordRolesStart = createAction(
	discordActionTypes.SYNC_DISCORD_ROLES_START
)
export const syncDiscordRolesSuccess = createAction(
	discordActionTypes.SYNC_DISCORD_ROLES_SUCCESS
)
export const syncDiscordRolesFailure = createAction(
	discordActionTypes.SYNC_DISCORD_ROLES_FAILURE
)
