import React from "react"

export default () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="16"
		viewBox="0 0 24 16"
	>
		<path
			id="Icon_awesome-newspaper"
			data-name="Icon awesome-newspaper"
			d="M23,4.5H3.667a1,1,0,0,0-1,1v.333H1a1,1,0,0,0-1,1V18.167A2.333,2.333,0,0,0,2.333,20.5H22a2,2,0,0,0,2-2V5.5A1,1,0,0,0,23,4.5ZM2.333,18.5A.333.333,0,0,1,2,18.167V7.833h.667V18.167A.333.333,0,0,1,2.333,18.5Zm9.833-.667H5.833a.5.5,0,0,1-.5-.5V17a.5.5,0,0,1,.5-.5h6.333a.5.5,0,0,1,.5.5v.333A.5.5,0,0,1,12.167,17.833Zm8.667,0H14.5a.5.5,0,0,1-.5-.5V17a.5.5,0,0,1,.5-.5h6.333a.5.5,0,0,1,.5.5v.333A.5.5,0,0,1,20.833,17.833Zm-8.667-4H5.833a.5.5,0,0,1-.5-.5V13a.5.5,0,0,1,.5-.5h6.333a.5.5,0,0,1,.5.5v.333A.5.5,0,0,1,12.167,13.833Zm8.667,0H14.5a.5.5,0,0,1-.5-.5V13a.5.5,0,0,1,.5-.5h6.333a.5.5,0,0,1,.5.5v.333A.5.5,0,0,1,20.833,13.833Zm0-4h-15a.5.5,0,0,1-.5-.5V7.667a.5.5,0,0,1,.5-.5h15a.5.5,0,0,1,.5.5V9.333A.5.5,0,0,1,20.833,9.833Z"
			transform="translate(0 -4.5)"
			fill="#d339fa"
		/>
	</svg>
)
