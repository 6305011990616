import React from "react"

export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			d="M15.375,3.375a12,12,0,1,0,12,12A12,12,0,0,0,15.375,3.375Zm1.1,17.538h-2.2V12.6h2.2Zm-1.1-9.219a1.152,1.152,0,1,1,1.194-1.154A1.156,1.156,0,0,1,15.369,11.694Z"
			transform="translate(-3.375 -3.375)"
		/>
	</svg>
)
