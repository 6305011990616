import { put, call, takeLatest, select } from "redux-saga/effects"
import * as teamAPI from "api/team"
import Spinner from "components/commons/Spinner"
import notify from "components/commons/notification"
import { isAuthorizedSelector } from "../auth/selectors"

import {
	LOAD_TEAM_MEMBERS_REQUEST,
	LOAD_INVITATIONS,
	UPDATE_TEAM_MEMBER_REQUEST,
	LEAVE_TEAM_REQUEST,
	INVITE_USER_REQUEST,
	ACCEPT_INVITE,
	REJECT_INVITE,
	CANCEL_INVITE,
	APPLY_TEAM_REQUEST,
	ACCEPT_TEAM_REQUEST,
} from "./types"

import {
	setTeamMembersAction,
	loadTeamMembersFailedAction,
	updateTeamMemberFailedAction,
	inviteUserFailedAction,
	loadInvitationsFailed,
	setInvitations,
	acceptInviteFailed,
	rejectInviteFailed,
	applyTeamFailedAction,
	acceptTeamFailedAction,
} from "./actions"

import { setTeamAction } from "../teams/actions"
import { updateUserAction } from "../auth/actions"

const showNotify = (err, top) => {
	const errCode = err?.response?.data?.errorKey
	if (errCode) {
		notify({ messageKey: errCode, top })
	}
}

// payload might be a teamId
function* loadTeamMembersSaga({ payload }) {
	try {
		Spinner.show()
		const { teamId, status } = payload
		const teammembers = yield call([teamAPI, "getAllTeamMembers"], teamId, {
			status,
		})
		yield put(setTeamMembersAction(teammembers.data))
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(loadTeamMembersFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* updateTeamMemberSaga({ payload }) {
	try {
		Spinner.show()
		const { viewComponent, cb, ...params } = payload
		const { teamId } = params
		const teammembers = yield call(
			[teamAPI, "updateTeamMember"],
			teamId,
			params
		)
		yield put(setTeamMembersAction(teammembers.data))
		notify({ messageKey: "UpdateTeamMember", type: "success", bottom: 40 })
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(updateTeamMemberFailedAction())
	} finally {
		Spinner.destroy()
	}
}

/**
 * payload is an username
 */
function* inviteUserSaga({ payload }) {
	try {
		Spinner.show()
		const { viewComponent, cb, ...params } = payload
		const { userId, teamId } = params
		const teammembers = yield call([teamAPI, "inviteUser"], teamId, userId)
		yield put(setTeamMembersAction(teammembers.data.members))
		notify({ messageKey: "InviteTeamMember", type: "success", bottom: 40 })
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(inviteUserFailedAction())
	} finally {
		Spinner.destroy()
	}
}

/**
 * @param {payload:{object}} payload is an invitation
 */
function* applyTeamSaga({ payload }) {
	try {
		Spinner.show()
		const { userId, discordId, message, referral, timezone, teamId } = payload
		const teammembers = yield call([teamAPI, "applyToTeam"], teamId, {
			userId,
			discordId,
			message,
			referral,
			timezone,
		})
		yield put(setTeamMembersAction(teammembers.data.members))
		notify({ messageKey: "ApplyTeam", type: "success", bottom: 40 })
	} catch (error) {
		notify({ messageKey: "ApplyTeamFail", bottom: 40 })
		yield put(applyTeamFailedAction())
	} finally {
		Spinner.destroy()
	}
}

/**
 * @param {payload:{object}} payload is an invitation
 */
function* acceptInviteSaga({ payload }) {
	try {
		Spinner.show()
		const { viewComponent, cb, ...params } = payload
		const { userId, teamId } = params
		const team = yield call([teamAPI, "acceptInvite"], teamId, userId)
		yield put(setTeamAction(team.data))
		notify({ messageKey: "InviteAccepted", type: "success", bottom: 40 })
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(acceptInviteFailed())
	} finally {
		Spinner.destroy()
	}
}

/**
 * @param {payload:{object}} payload is an invitation
 */
function* rejectInviteSaga({ payload }) {
	try {
		Spinner.show()
		const { viewComponent, cb, ...params } = payload
		const { userId, teamId } = params
		const teamMembers = yield call([teamAPI, "rejectInvite"], teamId, userId)
		yield put(setTeamMembersAction(teamMembers.data))
		notify({ messageKey: "InviteDeclined", type: "success", bottom: 40 })
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(acceptInviteFailed())
	} finally {
		Spinner.destroy()
	}
}

/**
 * @param {payload:{object}} payload is an invitation
 */
function* acceptTeamSaga({ payload }) {
	try {
		Spinner.show()
		const teammembers = yield call([teamAPI, "acceptToTeam"], payload)

		yield put(setTeamMembersAction(teammembers.data))
		notify({ messageKey: "AcceptInvite", type: "success", bottom: 40 })
	} catch (error) {
		yield call(showNotify, "Your are already on a team", 40)
		yield put(acceptTeamFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* leaveTeamSaga({ payload }) {
	try {
		Spinner.show()
		const { teamId, userId } = payload
		yield call([teamAPI, "cancelInvite"], teamId, userId)
		notify({ messageKey: "LeaveTeam", type: "success", bottom: 40 })
		yield put(updateUserAction({ teamId: null }))
		yield put(setTeamAction(null))
	} catch (error) {
		yield call(showNotify, error, 40)
		//  yield put(levatTeamFailedAction())
	} finally {
		Spinner.destroy()
	}
}

/**
 * @param {payload:{object}} payload is an invitation
 */
function* cancelInviteSaga({ payload }) {
	try {
		Spinner.show()
		const { teamId, userId } = payload
		const teammembers = yield call([teamAPI, "cancelInvite"], teamId, userId)
		yield put(setTeamMembersAction(teammembers.data))
		notify({ messageKey: "UserRemoved", type: "success", bottom: 40 })
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(rejectInviteFailed())
	} finally {
		Spinner.destroy()
	}
}

function* loadInvitationSaga() {
	try {
		const isAuthorized = yield select(isAuthorizedSelector)
		if (isAuthorized) {
			const data = yield call([teamAPI, "getInvitations"])
			yield put(setInvitations(data.data))
		}
	} catch (error) {
		yield put(loadInvitationsFailed())
	}
}

export default [
	takeLatest(LEAVE_TEAM_REQUEST, leaveTeamSaga),
	takeLatest(LOAD_INVITATIONS, loadInvitationSaga),
	takeLatest(ACCEPT_TEAM_REQUEST, acceptTeamSaga),
	takeLatest(APPLY_TEAM_REQUEST, applyTeamSaga),
	takeLatest(ACCEPT_INVITE, acceptInviteSaga),
	takeLatest(CANCEL_INVITE, cancelInviteSaga),
	takeLatest(REJECT_INVITE, rejectInviteSaga),
	takeLatest(INVITE_USER_REQUEST, inviteUserSaga),
	takeLatest(UPDATE_TEAM_MEMBER_REQUEST, updateTeamMemberSaga),
	takeLatest(LOAD_TEAM_MEMBERS_REQUEST, loadTeamMembersSaga),
]
