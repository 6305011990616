import React from "react"

const IconMenu = (props) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect id="Boundary" width="24" height="24" fill="none" />
		<path
			d="M18,12H0V10H18v2Zm0-5H0V5H18V7Zm0-5H0V0H18V2Z"
			transform="translate(3 6)"
		/>
	</svg>
)

export default IconMenu
