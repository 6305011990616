import { put, call, takeLatest } from "redux-saga/effects"
import * as actions from "./actions"
import activitiesActionTypes from "./types"
import { fetchActivitiesApi } from "api/users"

function* getActivitesSaga() {
	try {
		const activities = yield call(fetchActivitiesApi)
		yield put(actions.getActivitesSuccAction(activities))
	} catch (error) {
		yield put(actions.getActivitesFailAction(error?.response?.data))
	}
}

const activitiesSaga = [
	takeLatest(activitiesActionTypes.GET_ACTIVITIES_REQ, getActivitesSaga),
]

export default activitiesSaga
