import { createAction } from "@reduxjs/toolkit"
import conversionActionTypes from "./types"

export const getLatestConversionAction = createAction(
	conversionActionTypes.GET_LATEST_CONVERSION
)
export const getLatestConversionSuccAction = createAction(
	conversionActionTypes.GET_LATEST_CONVERSION_SUCCESS
)
export const getLatestConversionFailAction = createAction(
	conversionActionTypes.GET_LATEST_CONVERSION_FAILED
)

export const getConversion = createAction(conversionActionTypes.GET_CONVERSION)
export const getConversionSuccess = createAction(
	conversionActionTypes.GET_CONVERSION_SUCCESS
)
export const getConversionFailure = createAction(
	conversionActionTypes.GET_CONVERSION_FAILURE
)
