import { createAction } from "@reduxjs/toolkit"
import * as types from "./types"

export const getRentals = createAction(types.GET_RENTAL)
export const getRentalsSuccess = createAction(types.GET_RENTAL_SUCCESS)
export const getRentalsFail = createAction(types.GET_RENTAL_FAIL)

export const offerRental = createAction(types.OFFER_RENTAL)
export const offerRentalSuccess = createAction(types.OFFER_RENTAL_SUCCESS)
export const offerRentalFailure = createAction(types.OFFER_RENTAL_FAIL)

export const revokeRental = createAction(types.REVOKE_RENTAL)
export const revokeRentalSuccess = createAction(types.REVOKE_RENTAL_SUCCESS)
export const revokeRentalFail = createAction(types.REVOKE_RENTAL_FAIL)

export const acceptRental = createAction(types.ACCEPT_RENTAL)
export const acceptRentalSuccess = createAction(types.ACCEPT_RENTAL_SUCCESS)
export const acceptRentalFail = createAction(types.ACCEPT_RENTAL_FAIL)
