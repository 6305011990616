import React from "react"

export default () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="39.12"
		height="13.312"
		viewBox="0 0 39.12 13.312"
	>
		<g
			id="Group_908"
			data-name="Group 908"
			transform="translate(-14.25 -24.548)"
		>
			<g id="Group_908-2" data-name="Group 908">
				<path
					id="Path_7"
					data-name="Path 7"
					d="M53.165,27.546a.5.5,0,0,0-.518-.043c-.83.416-2.854-.133-4.379-.818-5.408-2.462-12.552-2.129-12.853-2.115a.5.5,0,0,0-.27.1l-4.761,3.483c-.78.044-4.563.248-8.64.3-4.754.061-7.214,2.091-7.316,2.177a.5.5,0,0,0,.027.786,1.951,1.951,0,0,1,.9,1.356,2.56,2.56,0,0,1-.662,1.709.5.5,0,0,0,.365.842h2.732a3.935,3.935,0,0,0,7.359,0H41.232a3.935,3.935,0,0,0,7.36,0H49.2a3.573,3.573,0,0,0,3.5-3l.66-4.29A.5.5,0,0,0,53.165,27.546ZM21.47,36.86a2.955,2.955,0,1,1,2.96-2.95,2.548,2.548,0,0,1-.106.747c0,.011,0,.023-.008.034A2.937,2.937,0,0,1,21.47,36.86Zm23.44,0a2.955,2.955,0,1,1,2.96-2.95,2.575,2.575,0,0,1-.1.744c0,.012,0,.025-.008.037A2.937,2.937,0,0,1,44.91,36.86Zm6.806-4.7A2.565,2.565,0,0,1,49.2,34.32h-.351a3.88,3.88,0,0,0,.021-.41,3.955,3.955,0,1,0-7.91,0,4.059,4.059,0,0,0,.021.41H25.409a3.88,3.88,0,0,0,.021-.41,3.955,3.955,0,1,0-7.91,0,4.059,4.059,0,0,0,.021.41H16a3.021,3.021,0,0,0,.354-1.637,2.645,2.645,0,0,0-.787-1.628,12.185,12.185,0,0,1,6.19-1.6c4.579-.06,8.792-.309,8.833-.311a.5.5,0,0,0,.266-.1l4.756-3.48c1.046-.036,7.465-.143,12.244,2.033a10.232,10.232,0,0,0,4.4,1.029Z"
					fill="#d339fa"
				/>
				<path
					id="Path_8"
					data-name="Path 8"
					d="M21.474,32.673a1.233,1.233,0,1,0,1.233,1.233A1.233,1.233,0,0,0,21.474,32.673Z"
					fill="#d339fa"
				/>
				<path
					id="Path_9"
					data-name="Path 9"
					d="M44.914,32.673a1.233,1.233,0,1,0,1.233,1.233A1.233,1.233,0,0,0,44.914,32.673Z"
					fill="#d339fa"
				/>
				<path
					id="Path_10"
					data-name="Path 10"
					d="M46.793,28.228a30.177,30.177,0,0,0-10.278-1.675l-.41,0a.5.5,0,0,0-.287.1l-2.24,1.637a.5.5,0,0,0,.295.9H46.607a.5.5,0,0,0,.186-.964ZM35.4,28.192l.874-.638.237,0a34.2,34.2,0,0,1,6.6.64Z"
					fill="#d339fa"
				/>
				<path
					id="Path_11"
					data-name="Path 11"
					d="M26.357,33.812h1.625l.768-1.375H27.125Z"
					fill="#d339fa"
				/>
				<path
					id="Path_12"
					data-name="Path 12"
					d="M28.75,32.437h1.625l.768-1.375H29.518Z"
					fill="#d339fa"
				/>
				<path
					id="Path_13"
					data-name="Path 13"
					d="M31.232,33.812,32,32.437H30.375l-.768,1.375Z"
					fill="#d339fa"
				/>
				<path
					id="Path_14"
					data-name="Path 14"
					d="M31.143,31.062h1.625l.768-1.375H31.91Z"
					fill="#d339fa"
				/>
			</g>
		</g>
	</svg>
)
