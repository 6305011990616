import { createReducer } from "@reduxjs/toolkit"
import * as types from "./types"

const initialState = {
	loading: false,
	error: undefined,
	races: [],
	results: [],
	shards: {
		loading: false,
		error: undefined,
		response: [],
	},
	enter: {
		loading: false,
		error: undefined,
		response: undefined,
	},
	exit: {
		loading: false,
		error: undefined,
		response: undefined,
	},
	update: {
		loading: false,
		error: undefined,
		response: undefined,
	},
	myRaces: {
		loading: false,
		error: undefined,
		response: undefined,
	},
	winnings: {
		loading: false,
		error: undefined,
		response: undefined,
	},
	cost: {
		loading: false,
		error: undefined,
		response: undefined,
	},
	winningsTotal: {
		loading: false,
		error: undefined,
		response: undefined,
	},
	myStrategies: {
		// eventual refactor: set all loading states to be false
		loading: false,
		error: null,
		response: [],
	},
	myEntries: {
		loading: null,
		error: null,
		response: [],
	},
	isModal: false,
	userRaceEntry: {
		loading: null,
		error: null,
		response: null,
	},
	raceEntry: {
		loading: null,
		error: null,
		response: null,
	},
	race: {
		loading: null,
		error: null,
		response: {},
	},

	raceEntryStatus: {
		loading: null,
		error: null,
		response: null,
	},
}

const racesReducer = createReducer(initialState, {
	[types.GET_RACES]: (state) => {
		state.loading = true
		state.error = undefined
		state.races = []
	},
	[types.GET_RACES_SUCCESS]: (state, action) => {
		state.loading = false
		state.error = undefined
		state.races = action.payload
	},
	[types.GET_RACES_FAILED]: (state, action) => {
		state.loading = false
		state.error = action.error
	},
	[types.GET_SHARDS]: (state) => {
		state.shards.loading = true
		state.shards.error = undefined
		state.shards.response = []
	},
	[types.GET_SHARDS_SUCCESS]: (state, action) => {
		state.shards.loading = false
		state.shards.error = undefined
		state.shards.response = action.payload
	},
	[types.GET_SHARDS_FAILED]: (state, action) => {
		state.shards.loading = false
		state.shards.error = action.error
	},
	[types.GET_WINNINGS]: (state) => {
		state.winnings.loading = true
		state.winnings.error = undefined
		state.winnings.response = []
	},
	[types.GET_WINNINGS_SUCCESS]: (state, action) => {
		state.winnings.loading = false
		state.winnings.error = undefined
		state.winnings.response = action.payload
	},
	[types.GET_WINNINGS_FAILED]: (state, action) => {
		state.winnings.loading = false
		state.winnings.error = action.error
	},

	[types.GET_RESULTS]: (state) => {
		state.loading = true
		state.error = undefined
		state.results = []
	},
	[types.GET_RESULTS_SUCCESS]: (state, action) => {
		state.loading = false
		state.error = undefined
		state.results = action.payload
	},
	[types.GET_RESULTS_FAILED]: (state, action) => {
		state.loading = false
		state.error = action.error
	},

	[types.ENTER_RACE]: (state) => {
		state.enter.loading = true
		state.enter.error = undefined
		state.enter.response = []
	},
	[types.ENTER_RACE_SUCCESS]: (state, action) => {
		state.enter.loading = false
		state.enter.error = undefined
		state.enter.response = action.payload
	},
	[types.ENTER_RACE_FAILED]: (state, action) => {
		state.enter.loading = false
		state.enter.error = action.payload.errorKey
	},

	[types.EXIT_RACE]: (state) => {
		state.exit.loading = true
		state.exit.error = undefined
		state.exit.response = []
	},
	[types.EXIT_RACE_SUCCESS]: (state, action) => {
		state.exit.loading = false
		state.exit.error = undefined
		state.exit.response = action.payload
	},
	[types.EXIT_RACE_FAILED]: (state, action) => {
		state.exit.loading = false
		state.exit.error = action.errorKey
	},

	[types.UPDATE_RACE]: (state) => {
		state.update.loading = true
		state.update.error = undefined
		state.update.response = []
	},
	[types.UPDATE_RACE_SUCCESS]: (state, action) => {
		state.update.loading = false
		state.update.error = undefined
		state.update.response = action.payload
	},
	[types.UPDATE_RACE_FAILED]: (state, action) => {
		state.update.loading = false
		state.update.error = action.errorKey
	},

	[types.GET_MY_RACES]: (state) => {
		state.myRaces.loading = true
		state.myRaces.error = undefined
		state.myRaces.response = []
	},
	[types.GET_MY_RACES_SUCCESS]: (state, action) => {
		state.myRaces.loading = false
		state.myRaces.error = undefined
		state.myRaces.response = action.payload
	},
	[types.GET_MY_RACES_FAILED]: (state, action) => {
		state.myRaces.loading = false
		state.myRaces.error = action.errorKey
	},

	[types.GET_MY_WINNINGS]: (state) => {
		state.winningsTotal.loading = true
		state.winningsTotal.error = undefined
		state.winningsTotal.response = []
	},
	[types.GET_MY_WINNINGS_SUCCESS]: (state, action) => {
		state.winningsTotal.loading = false
		state.winningsTotal.error = undefined
		state.winningsTotal.response = action.payload
	},
	[types.GET_MY_RACES_FAILED]: (state, action) => {
		state.winningsTotal.loading = false
		state.winningsTotal.error = action.errorKey
	},
	[types.FETCH_MY_ENTRIES_LOADING]: (state, { payload }) => {
		state.myEntries.loading = payload
		state.error = null
	},
	[types.FETCH_MY_ENTRIES_SUCCESS]: (state, { payload }) => {
		state.myEntries.response = payload
		state.error = null
	},
	[types.FETCH_MY_ENTRIES_FAILED]: (state, { payload }) => {
		state.myEntries.error = payload
	},
	[types.FETCH_MY_STRATEGIES_LOADING]: (state, { payload }) => {
		state.myStrategies.loading = payload
	},
	[types.FETCH_MY_STRATEGIES_SUCCESS]: (state, { payload }) => {
		state.myStrategies.response = payload
		state.error = null
	},
	[types.FETCH_MY_STRATEGIES_FAILED]: (state, { payload }) => {
		state.myStrategies.error = payload
	},
	[types.SAVE_NEW_STRATEGY_SUCCESS]: (state, { payload }) => {
		state.myStrategies.response.push(payload)
	},
	[types.SAVE_NEW_STRATEGY_FAILED]: (state, { payload }) => {
		state.myStrategies.error = payload
	},
	[types.SAVE_NEW_STRATEGY_LOADING]: (state, { payload }) => {
		state.myStrategies.loading = payload
	},
	[types.STRATEGY_MODAL]: (state, { payload }) => {
		state.isModal = payload
	},
	[types.FETCH_USER_RACE_ENTRY]: (state) => {
		state.userRaceEntry.error = null
		state.userRaceEntry.loading = true
	},
	[types.FETCH_USER_RACE_ENTRY_SUCCESS]: (state, { payload }) => {
		state.userRaceEntry.response = payload
		state.userRaceEntry.error = null
		state.userRaceEntry.loading = false
	},
	[types.FETCH_USER_RACE_ENTRY_FAIL]: (state, { payload }) => {
		state.userRaceEntry.response = null
		state.userRaceEntry.error = payload
		state.userRaceEntry.loading = false
	},
	[types.FETCH_RACE_ENTRY]: (state) => {
		state.raceEntry.error = null
		state.raceEntry.loading = true
	},
	[types.FETCH_RACE_ENTRY_SUCCESS]: (state, { payload }) => {
		state.raceEntry.response = payload
		state.raceEntry.error = null
		state.raceEntry.loading = false
	},
	[types.FETCH_RACE_ENTRY_FAIL]: (state, { payload }) => {
		state.raceEntry.response = null
		state.raceEntry.error = payload
		state.raceEntry.loading = false
	},
	[types.LOAD_RACE]: (state) => {
		state.race.loading = true
		state.race.error = undefined
		state.race.response = {}
	},
	[types.LOAD_RACE_SUCCESS]: (state, action) => {
		state.race.loading = false
		state.race.error = undefined
		state.race.response = action.payload
	},
	[types.LOAD_RACE_FAILED]: (state, action) => {
		state.race.loading = false
		state.race.error = action.errorKey
	},
	[types.FETCH_RACE_ENTRY_STATUS]: (state) => {
		state.raceEntryStatus.loading = true
	},
	[types.FETCH_RACE_ENTRY_STATUS_SUCCESS]: (state, action) => {
		state.raceEntryStatus.error = null
		state.raceEntryStatus.response = action.payload
		state.raceEntryStatus.loading = false
	},
	[types.FETCH_RACE_ENTRY_STATUS_FAILED]: (state, action) => {
		state.raceEntryStatus.response = null
		state.raceEntryStatus.error = action.payload
		state.raceEntryStatus.loading = false
	},
	[types.DELETE_STRATEGY]: (state, action) => {
		state.myStrategies.response = state?.myStrategies?.response?.map((strat) =>
			strat.id === action.payload ? { ...strat, loading: true } : strat
		)
	},
	[types.DELETE_STRATEGY_SUCCESS]: (state, action) => {
		state.myStrategies.response = state?.myStrategies?.response.filter(
			({ id }) => id !== action.payload
		)
	},
	[types.DELETE_STRATEGY_FAIL]: (state, action) => {
		state.myStrategies.response = state?.myStrategies?.response.map((strat) =>
			strat.id === action.payload ? { ...strat, loading: false } : strat
		)
	},
})

export default racesReducer
