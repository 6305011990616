import { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Modal as AntModal } from "antd"
import CloseIcon from "../Icons/IconClose"
import RioButton from "../Button"

import "./styles.scss"

const { confirm } = AntModal

const CustomModal = ({
	title,
	confirmLabel = "save",
	onCancel,
	onConfirm,
	children,
	visible,
	closable,
	footer,
	footerText,
	width,
	height,
	className,
	isFull,
	hideClose,
	style,
	bottom,
	confirmLoading,
	closeText,
}) => {
	const { t } = useTranslation()
	const classes = useMemo(() => {
		let base = "rio-modal"
		if (className) {
			base = `${base} ${className}`
		}
		if (isFull) {
			base = `${base} fullScreen`
		}
		if (bottom) {
			base = `${base} bottom`
		}
		return base
	}, [className, isFull, bottom])

	return (
		<AntModal
			centered
			footer={null}
			visible={visible}
			onCancel={onCancel}
			closable={false}
			style={style}
			width={isFull ? "100vw" : width}
			height={isFull ? "100vh" : height}
			wrapClassName={classes}
		>
			<div className="rio-modal-header">
				{title}
				{!hideClose && (
					<div
						className="close_btn"
						onClick={onCancel}
						onKeyDown={onCancel}
						role="button"
						tabIndex="0"
					>
						<CloseIcon />
					</div>
				)}
			</div>
			<div className="rio-modal-body">
				{children}
				{footer && (
					<div className="rio-modal-footer">
						<RioButton onClick={onCancel}>
							{closeText}
							{!closeText && t("cancel")}
						</RioButton>
						{onConfirm && (
							<RioButton
								loading={confirmLoading}
								disabled={closable}
								onClick={onConfirm}
								id="RioButton"
							>
								{t(confirmLabel)}
							</RioButton>
						)}
					</div>
				)}
				{footerText && (
					<span className="networkInfo">
						{t("assets.nameChange.networkInfo")}
					</span>
				)}
			</div>
		</AntModal>
	)
}

CustomModal.confirm = confirm

CustomModal.propTypes = {
	onCancel: PropTypes.func,
	closable: PropTypes.bool,
	visible: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	className: PropTypes.string,
}

CustomModal.defaultProps = {
	className: "",
	closable: false,
	onCancel: () => null,
	width: 520,
	height: "auto",
}

export default CustomModal
