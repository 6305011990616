const IconCart = (props) => (
	<svg
		id="Component_109_4"
		data-name="Component 109 – 4"
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="22.168"
		viewBox="0 0 24 22.168"
		{...props}
	>
		<g
			id="Icon_ionic-ios-cart"
			data-name="Icon ionic-ios-cart"
			transform="translate(-3.382 -4.493)"
		>
			<path
				id="Path_1260"
				data-name="Path 1260"
				d="M12.938,29.813a1.688,1.688,0,1,1-1.687-1.688,1.688,1.688,0,0,1,1.688,1.688Z"
				transform="translate(-1.252 -4.839)"
				fill="#ababab"
			/>
			<path
				id="Path_1261"
				data-name="Path 1261"
				d="M28.723,29.813a1.687,1.687,0,1,1-1.687-1.688,1.687,1.687,0,0,1,1.687,1.688Z"
				transform="translate(-5.239 -4.839)"
				fill="#ababab"
			/>
			<path
				id="Path_1262"
				data-name="Path 1262"
				d="M27.373,8.262a.336.336,0,0,0-.3-.232L8.276,6.292a.582.582,0,0,1-.433-.259,5.6,5.6,0,0,0-.7-1.027c-.444-.519-1.281-.5-2.816-.513a.829.829,0,0,0-.941.778.811.811,0,0,0,.9.778,8.011,8.011,0,0,1,1.5.1c.271.077.491.5.571.872a.02.02,0,0,0,.006.017c.012.066.115.563.115.568L8.784,19.289a4.192,4.192,0,0,0,.837,1.97,2.341,2.341,0,0,0,1.9.894H25.175a.806.806,0,0,0,.831-.74.785.785,0,0,0-.808-.806H11.508a.691.691,0,0,1-.479-.155,2.435,2.435,0,0,1-.664-1.435l-.248-1.308a.03.03,0,0,1,.023-.033l16.027-2.594a.329.329,0,0,0,.283-.287l.923-6.391A.3.3,0,0,0,27.373,8.262Z"
				transform="translate(0)"
				fill="#ababab"
			/>
		</g>
	</svg>
)

export default IconCart
