import request from "./request"

export const statusApi = () =>
	request({
		path: `group/status`,
		opts: {
			method: "GET",
		},
	})

export const registerApi = (data) =>
	request({
		path: `group/register`,
		opts: {
			method: "POST",
			data,
		},
	})
