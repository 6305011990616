const conversionActionTypes = {
	GET_LATEST_CONVERSION: "GET_LATEST_CONVERSION",
	GET_LATEST_CONVERSION_SUCCESS: "GET_LATEST_CONVERSION_SUCCES",
	GET_LATEST_CONVERSION_FAILED: "GET_LATEST_CONVERSION_FAILED",

	GET_CONVERSION: "GET_CONVERSION",
	GET_CONVERSION_SUCCESS: "GET_CONVERSION_SUCCESS",
	GET_CONVERSION_FAILURE: "GET_CONVERSION_FAILURE",
}

export default conversionActionTypes
