import { createReducer } from "@reduxjs/toolkit"
import activitiesActionTypes from "./types"

const initialState = {
	loading: true,
	error: undefined,
	entities: [],
}

const activitiesReducer = createReducer(initialState, {
	[activitiesActionTypes.GET_ACTIVITIES_REQ]: (state) => {
		state.loading = true
		state.entities = []
	},
	[activitiesActionTypes.GET_ACTIVITIES_SUCC]: (state, action) => {
		state.error = null
		state.loading = false
		state.entities = action.payload
	},
	[activitiesActionTypes.GET_ACTIVITIES_FAIL]: (state, action) => {
		state.loading = false
		state.error = action.payload
	},
})

export default activitiesReducer
