import React, { useMemo, useRef } from "react"
import { Button } from "antd"
import PropTypes from "prop-types"
import "./button.scss"

const RioButton = ({
	children,
	variant,
	className,
	onClick,
	size,
	rounded,
	bordered,
	disabled,
	...rest
}) => {
	const btnRef = useRef()

	const classes = useMemo(() => {
		let base = ""
		if (rounded) {
			base = `${base} rounded`
		}
		if (bordered) {
			base = `${base} bordered`
		}
		if (variant) {
			base = `${base} ${variant}`
		}
		if (className) {
			base = `${base} ${className}`
		}
		if (size) {
			base = `${base} ${size}`
		}

		if (disabled) {
			base = `${base} disabled`
		}
		return base
	}, [variant, size, rounded, bordered, className, disabled])

	const handleClick = (e) => {
		if (btnRef.current) {
			btnRef.current.blur()
		}
		if (typeof onClick === "function") {
			onClick(e)
		}
	}

	return (
		<Button
			{...rest}
			onClick={handleClick}
			id="RioButton"
			className={classes}
			ref={btnRef}
		>
			{children}
		</Button>
	)
}

RioButton.propTypes = {
	variant: PropTypes.string,
}

RioButton.defaultProps = {
	variant: "primary",
}

export default RioButton
