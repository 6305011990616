import { createAction } from "@reduxjs/toolkit"
import stakingActionTypes from "./types"

export const getStakingAction = createAction(stakingActionTypes.GET_STAKING)
export const getStakingSuccess = createAction(
	stakingActionTypes.GET_STAKING_SUCCESS
)
export const getStakingFailure = createAction(
	stakingActionTypes.GET_STAKING_FAILED
)

export const initiateStakingTransactionsAction = createAction(
	stakingActionTypes.INITIATE_STAKING_TRANSACTIONS
)
export const initiateStakingTransactionsSuccess = createAction(
	stakingActionTypes.INITIATE_STAKING_TRANSACTIONS_SUCCESS
)
export const initiateStakingTransactionsFailure = createAction(
	stakingActionTypes.INITIATE_STAKING_TRANSACTIONS_FAILED
)
