import request from "./request"

// asset upgrades
/*list, cost, buy */

export const getAssetUpgrades = () =>
	request({
		path: `upgrade/list`,
		opts: {
			method: "GET",
		},
	})

export const getAssetUpgradesCost = (upgradeId) =>
	request({
		path: `upgrade/cost/${upgradeId}`,
		opts: {
			method: "GET",
		},
	})

export const postAssetBuyUpgrade = (data) =>
	request({
		path: `upgrade/buy`,
		opts: {
			method: "POST",
			data,
		},
	})