import React from "react"

export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="28"
		height="24"
		viewBox="0 0 28 24"
		{...props}
	>
		<path
			d="M12.667,953.162,0,961.962H2.667v12.8A1.539,1.539,0,0,0,4,976.362H8v-8a1.482,1.482,0,0,1,1.333-1.6h9.334a1.5,1.5,0,0,1,1.333,1.6v8h4a1.481,1.481,0,0,0,1.333-1.6v-12.8H28l-12.667-8.8a5.073,5.073,0,0,0-1.333-.8,5,5,0,0,0-1.333.8Zm-3.333,15.2v1.6h9.334v-1.6Zm0,3.2v1.6h9.334v-1.6Zm0,3.2v1.6h9.334v-1.6Z"
			transform="translate(0 -952.362)"
		/>
	</svg>
)
