import { useState } from "react"
import useMetaMaskAuth from "hooks/useMetaMaskAuth"
import { useTranslation } from "react-i18next"

import { Button, Modal } from "antd"
import starzLogo from "assets/images/99Starz_logo.png"
import RioButton from "components/commons/Button"
import { useSelector, useDispatch } from "react-redux"
import { selectStarzModalVisible } from "store/starzModal/selectors"
import CloseIcon from "components/commons/Icons/IconClose"
import { toggleModal } from "store/starzModal/actions"

const StarzModal = () => {
	const [loading, setLoading] = useState(false)
	const { handleAuth } = useMetaMaskAuth()
	const { t } = useTranslation()
	const isVisible = useSelector(selectStarzModalVisible)
	const dispatch = useDispatch()

	const login = async () => {
		try {
			await handleAuth()
		} catch (err) {
			console.log(err)
		}
		setLoading(false)
	}

	const closeModal = () => {
		dispatch(toggleModal(false))
	}

	return (
		<Modal
			style={{ marginTop: "3%" }}
			visible={isVisible}
			bodyStyle={{ background: "#000", borderRadius: "5px" }}
			footer={null}
			closable={false}
			width={500}
		>
			<div className="absolute m-4 top-0 right-0">
				<Button type="ghost" className="border-none" onClick={closeModal}>
					<CloseIcon fill="#fff" />
				</Button>
			</div>
			<h1 className="text-center text-white font-bold text-lg p-0 m-0 mt-4">
				{t("connectWithStarz")}
			</h1>
			<div className="flex justify-center mt-4 mb-7">
				<img width={100} alt="99 Starz Logo" src={starzLogo} />
			</div>
			<div className="flex justify-center">
				<RioButton onClick={login} block bordered loading={loading}>
					{t("connect")}
				</RioButton>
			</div>
		</Modal>
	)
}

export default StarzModal
