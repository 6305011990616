import { put, call, takeLatest, select } from "redux-saga/effects"
import discordActionTypes from "./types"
import * as actions from "./actions"
import * as api from "api/discord"
import { getUserIdSelector } from "store/auth/selectors"
import { fetchAuthUser } from "store/auth/sagas"
import notify from "components/commons/notification"
import i18n from "i18n"

function* unlinkDiscord() {
	try {
		const userId = yield select(getUserIdSelector)
		yield call(api.discordUnlinkApi, userId)
		yield put(actions.unlinkDiscordSuccess())
		notify({
			type: "info",
			title: i18n.t("notification.unlinkedDiscord.title"),
			desc: i18n.t("notification.unlinkedDiscord.description"),
		})
		yield call(fetchAuthUser)
	} catch (err) {
		yield put(actions.unlinkDiscordFailure(err))
		notify({
			type: "error",
			title: i18n.t("notification.unableToLink.title"),
			desc: i18n.t("notification.unableToLink.description"),
		})
	}
}

function* syncDiscordRoles() {
	try {
		const userId = yield select(getUserIdSelector)
		yield call(api.discordSyncRocRolesApi, userId)
		yield put(actions.syncDiscordRolesSuccess())
	} catch (err) {
		yield put(actions.syncDiscordRolesFailure(err))
	}
}

/**
 *
 * @param {
 *  type: string,
 *  payload: string
 * } action
 */
function* redirectDiscordCallback({ payload }) {
	try {
		const userId = yield select(getUserIdSelector)
		yield put(actions.redirectDiscordCbLoading(true))
		yield call(api.discordCallbackApi, payload, userId)
		yield put(actions.redirectDiscordCbSuccess())
		yield call(fetchAuthUser)
	} catch (err) {
		yield put(actions.redirectDiscordCbFailure(err))
	} finally {
		yield put(actions.redirectDiscordCbLoading(false))
	}
}

const discordSagas = [
	takeLatest(discordActionTypes.UNLINK_DISCORD_START, unlinkDiscord),
	takeLatest(discordActionTypes.SYNC_DISCORD_ROLES_START, syncDiscordRoles),
	takeLatest(
		discordActionTypes.REDIRECT_DISCORD_CALLBACK_START,
		redirectDiscordCallback
	),
]

export default discordSagas
