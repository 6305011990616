import i18n from "i18n"
import { put, call, takeLatest, takeEvery, select } from "redux-saga/effects"
import notify from "components/commons/notification"
import { setCarApi } from "api/users"
import * as api from "../../api/assets"
import { ASSET_TYPE } from "constants/types"
import * as actions from "./actions"
import assetsActionTypes from "./types"
import { getEthSelector } from "../auth/selectors"

function* getSelectedCarSaga({ payload: userId }) {
	try {
		const data = yield call(api.getSelectedCar, userId)
		yield put(
			actions.getAssetsSuccAction({
				data,
				assetType: "selectedCar",
			})
		)
	} catch (err) {
		let msg = i18n.t("apologize")
		if (err.response?.data?.error) {
			msg = err?.response?.data?.error
		}
		notify({ title: msg })
		yield put(actions.getAssetsFailAction(err))
	}
}

function* getCurrentAssetSaga({ payload: { assetType, assetId } }) {
	try {
		// const { data } = yield call(api.getCurrentAsset, assetId, assetType.value)
		const data = yield call(api.getCurrentAsset, assetId, assetType.value)
		yield put(
			actions.getAssetsSuccAction({
				data,
				assetType: "currentAsset",
			})
		)
	} catch (err) {
		let msg = i18n.t("apologize")
		if (err.response?.data?.error) {
			msg = err?.response?.data?.error
		}
		notify({ title: msg })
		yield put(actions.getAssetsFailAction(err))
	}
}

function* setSelectedCarSaga({ payload: { userId, carId } }) {
	try {
		// const {data} = yield call(setCarApi, userId, carId)
		const data = yield call(setCarApi, userId, carId)
		yield put(
			actions.getAssetsSuccAction({
				data,
				assetType: "selectedCar",
			})
		)
	} catch (err) {
		let msg = i18n.t("apologize")
		if (err.response?.data?.error) {
			msg = err?.response?.data?.error
		}
		notify({ title: msg })
		yield put(actions.getAssetsFailAction(err))
	}
}
function* getAssetsSaga({ payload: assetType }) {
	try {
		const data = yield call(api.getAssets, assetType.value)
		yield put(actions.getAssetsSuccAction({ data, assetType: assetType.key }))
	} catch (err) {
		if (err.response) {
			notify({ title: err.response })
		}
		yield put(actions.getAssetsFailAction(err))
	}
}

function* getAssetByAddressSaga({ payload }) {
	const { assetType, params } = payload
	try {
		const ethAddress = yield select(getEthSelector)
		const { data } = yield call(
			api.getAssetsByEth,
			ethAddress,
			assetType.value,
			params
		)
		yield put(
			actions.getAssetsSuccAction({
				data,
				assetType: assetType.key,
			})
		)
	} catch (err) {
		if (err.response) {
			notify({ title: err?.response?.data?.error })
		}
		yield put(actions.getAssetsFailAction(err))
	}
}

function* getCheckAssetNameSaga({ payload: { name, assetType } }) {
	try {
		const result = yield call(api.checkAssetName, name, assetType)
		yield put(
			actions.checkAssetNameSuccAction({
				name,
				data: result,
			})
		)
	} catch (err) {
		if (err.response) {
			notify({ title: err?.response?.data?.errors })
		}
		yield put(actions.checkAssetNameFailAction(err))
	}
}

function* updateAssetNameSaga({ payload: { name, assetType, assetId } }) {
	try {
		const result = yield call(api.updateAssetName, name, assetType, assetId)
		yield put(
			actions.updateAssetNameSuccAction({
				name,
				assetType,
				assetId,
				data: result,
			})
		)
	} catch (err) {
		if (err.response) {
			notify({ title: err?.response?.data?.errors })
		}
		yield put(actions.updateAssetNameFailAction(err))
	}
}

function* toggleAssetFavoriteSaga({ payload: { assetType, assetId } }) {
	try {
		const result = yield call(api.toggleFavoriteAsset, assetType, assetId)
		yield put(
			actions.toggleAssetsFavoriteSucc({
				assetId,
				assetType: ASSET_TYPE[assetType.toUpperCase()].key,
				// data: result.data,
				data: result,
			})
		)
	} catch (err) {
		if (err.response) {
			notify({ title: err?.response?.data?.error })
		}
	}
}

function* getAssetDropsSaga({ payload: { assetType, params } }) {
	try {
		const result = yield call(
			api.getAssetsApi,
			assetType.value.toLowerCase(),
			params
		)
		yield put(
			actions.getAssetsSuccess({
				data: result.data,
				ASSET_STATE_KEY: assetType.key,
			})
		)
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(actions.getAssetsFailure(err))
	}
}

export default [
	takeLatest(assetsActionTypes.GET_CURRENT_CAR_REQ, getCurrentAssetSaga),
	takeLatest(assetsActionTypes.GET_ACTIVE_CAR_REQ, getSelectedCarSaga),
	takeLatest(assetsActionTypes.SET_ACTIVE_CAR_REQ, setSelectedCarSaga),
	takeLatest(assetsActionTypes.GET_ASSETS_REQ, getAssetsSaga),
	takeEvery(assetsActionTypes.GET_ASSETS_ETH_REQ, getAssetByAddressSaga),
	takeLatest(assetsActionTypes.GET_CHECK_ASSET_NAME_REQ, getCheckAssetNameSaga),
	takeLatest(assetsActionTypes.UPDATE_ASSET_NAME_REQ, updateAssetNameSaga),
	takeEvery(assetsActionTypes.TOGGLE_ASSET_FAVORITE, toggleAssetFavoriteSaga),
	takeLatest(assetsActionTypes.GET_ASSETS, getAssetDropsSaga),
]
