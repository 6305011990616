import React from "react"

export default (props) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g transform="translate(-7 -7)">
			<path
				d="M21.824,21.4h2.824v2.057H21.824Zm5.647-2.057a5.052,5.052,0,0,0,1.412-.2v9.8A2.09,2.09,0,0,1,26.765,31H19V22.086a.7.7,0,0,0-.706-.686H13.353a.7.7,0,0,0-.706.686V31H11.235a2.09,2.09,0,0,1-2.118-2.057v-9.8a5.052,5.052,0,0,0,1.412.2,4.968,4.968,0,0,0,2.824-.868,5.027,5.027,0,0,0,5.647,0,5.027,5.027,0,0,0,5.647,0,4.968,4.968,0,0,0,2.824.868Zm-1.412,1.371a.7.7,0,0,0-.706-.686H21.118a.7.7,0,0,0-.706.686v3.429a.7.7,0,0,0,.706.686h4.235a.7.7,0,0,0,.706-.686ZM14.059,31h3.529V22.771H14.059ZM28.489,8.221A2.124,2.124,0,0,0,26.553,7H11.447A2.124,2.124,0,0,0,9.511,8.221l-2.264,4.95H30.753ZM7,14.543A3.428,3.428,0,0,0,9.416,17.8a3.578,3.578,0,0,0,3.937-1.221,3.559,3.559,0,0,0,5.647,0,3.559,3.559,0,0,0,5.647,0A3.578,3.578,0,0,0,28.584,17.8,3.428,3.428,0,0,0,31,14.543Z"
				transform="translate(0)"
			/>
		</g>
	</svg>
)
