import { createReducer } from "@reduxjs/toolkit"
import starzActionTypes from "./types"

const initialState = {
	isVisible: false,
}

const starzModalReducer = createReducer(initialState, {
	[starzActionTypes.TOGGLE_MODAL]: (state, action) => {
		state.isVisible = action.payload
	},
})

export default starzModalReducer
