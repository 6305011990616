import React from "react"
import i18n from "i18n"
import { notification as antNotification } from "antd"
import { EXTRA_DESKTOP } from "constants/breakPoints"
import IconCirCheck from "../Icons/IconCirCheck"
import IconCirClose from "../Icons/IconCirClose"
import IconCirInfo from "../Icons/IconCirInfo"

import "./styles.scss"

const iconMap = {
	success: <IconCirCheck />,
	error: <IconCirClose />,
	info: <IconCirInfo />,
	warning: <IconCirInfo />,
}

/**
 * functions will show a notification
 *
 * @param {Object} options for notificaton.
 * @param {string} options.title is required.
 * @param {string} options.desc is optional.
 * @param {number} options.bottom is optional. specify notification position.
 * @param {string} options.type is optional, default is error.
 * @param {string} options.messageKey is optional, if passed, message comes from i18n.
 */
const notification = (options) => {
	const {
		desc,
		bottom,
		placement,
		title,
		duration,
		messageKey,
		type = "error",
		opts,
		...rest
	} = options || {}
	const isExtra = window.innerWidth >= EXTRA_DESKTOP
	const titlePath = `notification.${messageKey}.title`
	const descPath = `notification.${messageKey}.description`

	const titleToRender = i18n.exists(titlePath) ? i18n.t(titlePath) : title
	const descToRender = i18n.exists(descPath) ? i18n.t(descPath, opts) : desc
	const chosenDuration = duration || 4

	const bottomPos = bottom || (isExtra ? 50 : 20)

	antNotification[type]({
		...rest,
		className: "custom-ant-notification",
		placement: "bottomRight",
		message: titleToRender,
		icon: iconMap[type],
		bottom: bottomPos,
		duration: chosenDuration,
		description: (
			<div className={descToRender ? "mt20" : "hidden"}>{descToRender}</div>
		),
	})
}

export default notification
