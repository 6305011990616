import React from "react"

export default (props) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M15.375,3.375a12,12,0,1,0,12,12A12,12,0,0,0,15.375,3.375Zm-.248,17.538a1.19,1.19,0,1,1,1.24-1.188A1.2,1.2,0,0,1,15.127,20.913Zm2.319-5.59c-1,.583-1.344,1.01-1.344,1.748v.456h-2l-.017-.5a2.4,2.4,0,0,1,1.362-2.538c.975-.583,1.385-.952,1.385-1.667a1.4,1.4,0,0,0-1.552-1.24A1.459,1.459,0,0,0,13.731,13H11.683c.04-1.858,1.413-3.173,3.733-3.173,2.163,0,3.652,1.2,3.652,2.925A2.824,2.824,0,0,1,17.446,15.323Z"
			transform="translate(-3.375 -3.375)"
		/>
	</svg>
)
