import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useRouteMatch } from "react-router-dom"
import { isVisibleTopNotice } from "store/topNotice/selectors"

const useTopNoticeVisible = () => {
	const topNoticeVisible = useSelector(isVisibleTopNotice)
	const { path } = useRouteMatch()
	const hideTopNotice = useMemo(() => path === "/rr/:username", [path])

	return topNoticeVisible && !hideTopNotice
}

export default useTopNoticeVisible
