import { put, call, takeLatest } from "redux-saga/effects"
import notify from "components/commons/notification"
import i18n from "i18n"
import * as actions from "./actions"
import {
	getStakingWallet,
	postStakingInitiateTransaction,
} from "api/stakingWallets"
import stakingActionTypes from "./types"

function* getStakingSaga() {
	try {
		const result = yield call(getStakingWallet)
		yield put(actions.getStakingSuccess(result.data))
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(actions.getStakingFailure(err))
	}
}

function* initiateStakingTransactionsSaga({ payload }) {
	try {
		const result = yield call(postStakingInitiateTransaction, payload)
		yield put(
			actions.initiateStakingTransactionsSuccess({
				data: result.data,
			})
		)
		notify({
			type: "success",
			title: i18n.t("transactionSuccess"),
		})

		// probably add more specific error notifications for staking specifically
		yield call(getStakingSaga)
	} catch (err) {
		if (err.response?.data?.errorKey) {
			notify({ messageKey: err.response.data.errorKey })
		} else {
			notify({ messageKey: "walletTransactionFailed" })
		}
		yield put(actions.initiateStakingTransactionsFailure(err))
	}
}

const stakingSaga = [
	takeLatest(stakingActionTypes.GET_STAKING, getStakingSaga),
	takeLatest(
		stakingActionTypes.INITIATE_STAKING_TRANSACTIONS,
		initiateStakingTransactionsSaga
	),
]

export default stakingSaga
