export const LOAD_TOURNAMENT_REQ = "LOAD_TOURNAMENT_REQ"
export const LOAD_TOURNAMENT_FAILED = "LOAD_TOURNAMENT_FAILED"

export const LOAD_TOURNAMENTS_REQ = "LOAD_TOURNAMENTS_REQ"
export const LOAD_TOURNAMENTS_FAILED = "LOAD_TOURNAMENTMS_FAILED"

export const LOAD_TOURNAMENT_HEAT_REQ = "LOAD_TOURNAMENT_HEAT_REQ"
export const LOAD_TOURNAMENT_HEAT_FAILED = "LOAD_TOURNAMENT_HEAT_FAILED"

export const SET_TOURNAMENT = "SET_TOURNAMENT"
export const SET_TOURNAMENT_HEAT = "SET_TOURNAMENT_HEAT"
export const SET_TOURNAMENTS = "SET_TOURNAMENTS"

export const CREATE_TOURNAMENT_REQ = "CREATE_TOURNAMENT_REQ"
export const CREATE_TOURNAMENT_FAILED = "CREATE_TOURNAMENT_FAILED"
export const CREATE_TOURNAMENT_REQ_FINISHED = "CREATE_TOURNAMENT_REQ_FINISHED"

export const CANCEL_TOURNAMENT_REQ = "CANCEL_TOURNAMENT_REQ"
export const CANCEL_TOURNAMENT_FAILED = "CANCEL_TOURNAMENT_FAILED"

export const INVITE_USER_REQ = "INVITE_USER_REQ"
export const INVITE_USER_REQ_FAILED = "INVITE_USER_REQ_FAILED"

export const ENTER_TOURNAMENT_REQ = "ENTER_TOURNAMENT_REQ"
export const ENTER_TOURNAMENT_REQ_FINISHED = "ENTER_TOURNAMENT_REQ_FINISHED"
export const ENTER_TOURNAMENT_REQ_FAILED = "ENTER_TOURNAMENT_REQ_FAILED"

export const UPDATE_TOURNAMENT_REQ = "UPDATE_TOURNAMENT_REQ"
export const UPDATE_TOURNAMENT_FAILED = "UPDATE_TOURNAMENT_FAILED"

export const LOAD_INVITATIONS_REQ = "LOAD_INVITATIONS_REQ"
export const LOAD_INVITATIONS_FAILED = "LOAD_INVITATIONS_FAILED"
export const SET_INVITATIONS = "SET_INVITATIONS"

export const REJECT_USER_REQ = "REJECT_USER_REQ"
export const REJECT_USER_REQ_FAILED = "REJECT_USER_REQ_FAILED"

export const REJECT_USER_REQ_FINISHED = "REJECT_USER_REQ_FINISHED"
export const REJECT_INVITE_REQ = "REJECT_INVITE_REQ"
export const REJECT_INVITE_REQ_FAILED = "REJECT_INVITE_REQ_FAILED"

export const ENTER_TOURNAMENT_HEAT_REQ = "ENTER_TOURNAMENT_HEAT_REQ"
export const ENTER_TOURNAMENT_HEAT_REQ_FINISHED =
	"ENTER_TOURNAMENT_HEAT_REQ_FINISHED"
export const ENTER_TOURNAMENT_HEAT_REQ_FAILED =
	"ENTER_TOURNAMENT_HEAT_REQ_FAILED"

export const LOAD_TRACKS_REQ = "LOAD_TRACKS_REQ"
export const LOAD_TRACKS_FAILED = "LOAD_TRACKS_FAILED"
export const SET_TRACKS = "SET_TRACKS"
