import { useEffect, useState } from "react"
import { Link, useLocation, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Drawer, Affix } from "antd"

import IconMenu from "components/commons/Icons/IconMenu"
import IconExit from "components/commons/Icons/IconExit"
import IconWallet from "components/commons/Icons/IconWallet"
import IconButton from "components/commons/Button/IconButton"
import CartButton from "../DesktopHeader/CartButton"
import logoOrange from "assets/images/logo-orange.png"
import SideNavbar from "components/SideNavbar/SideNavbar"

import "./MobileHeader.scss"

const MobileHeader = ({ authState, OpenModal }) => {
	const { pathname } = useLocation()
	const { t } = useTranslation()
	const [isOpenSide, setIsOpenSide] = useState(false)
	const history = useHistory()

	const onWalletClick = () => {
		history.push("/dashboard/wallet")
	}

	const toggleSider = () => {
		setIsOpenSide(!isOpenSide)
	}

	const openModal = () => {
		if (isOpenSide) toggleSider()
		if (OpenModal) OpenModal()
	}

	const isAuthorized = authState?.isLoggedIn

	useEffect(() => {
		setIsOpenSide(false)
	}, [pathname])

	return (
		<Affix offsetTop={0}>
			<div className="headerMobile">
				<div className="leftContent">
					<IconButton icon={<IconMenu />} onClick={toggleSider} />
				</div>
				<div className="midContent">
					<Link to="/">
						<img src={logoOrange} alt="" />
					</Link>
				</div>
				<div className="rightContent flex align-center">
					<IconButton
						icon={isAuthorized ? <IconWallet /> : <IconExit />}
						label={isAuthorized ? "" : t("start")}
						onClick={isAuthorized ? onWalletClick : openModal}
					/>
					{isAuthorized && (
						<div className="ml-9">
							<CartButton />
						</div>
					)}
				</div>
			</div>
			<Drawer
				onClose={toggleSider}
				visible={isOpenSide}
				closable={false}
				placement="left"
				width="100%"
				zIndex={100}
				style={{ marginTop: 76 }}
			>
				<SideNavbar />
				<div className="mb-20" />
			</Drawer>
		</Affix>
	)
}

export default MobileHeader
