import { put, call, takeEvery, takeLatest } from "redux-saga/effects"
import notify from "components/commons/notification"
import * as actions from "./actions"
import conversionActionTypes from "./types"
import * as conversionAPI from "api/conversions"

function* getLatestConversionSaga({ payload: symbol }) {
	try {
		const { data } = yield call(conversionAPI.getLatestConversion, symbol)
		yield put(actions.getLatestConversionSuccAction({ data, symbol }))
	} catch (err) {
		if (err.response) {
			notify({ title: err.response })
			yield put(actions.getLatestConversionFailAction(err))
		}
	}
}

/**
 * @param {
 *  type: String,
 *  payload: {
 *    toCurrencyType: enum(ETH, RIOT, USD)
 *    fromCurrencyType: enum(ETH, RIOT, USD)
 *  }
 * } action
 */
function* getConversionSaga({ payload }) {
	try {
		const { data } = yield call(conversionAPI.getConversion, payload)
		yield put(actions.getConversionSuccess(data))
	} catch (err) {
		yield put(actions.getConversionFailure(err))
	}
}

export default [
	takeEvery(
		conversionActionTypes.GET_LATEST_CONVERSION,
		getLatestConversionSaga
	),
	takeLatest(conversionActionTypes.GET_CONVERSION, getConversionSaga),
]
