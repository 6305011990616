import { put, call, takeLatest } from "redux-saga/effects"
import i18n from "i18n"
import request from "api/request"
import notify from "components/commons/notification"
import {
	addItemToCartSuccAction,
	addItemToCartFailAction,
	getCartSuccessAction,
	getCartFailureAction,
	payCarSuccessAction,
	payCarFailureAction,
	deleteCartSuccessAction,
	deleteCartFailureAction,
	getCartAction,
	getOrdersSuccessAction,
	getOrdersFailureAction,
	getTransferedSuccessAction,
	getTransferedFailureAction,
	getEthCartSuccessAction,
	getEthCartFailureAction,
	getRiotCartSuccessAction,
	getRiotCartFailureAction,
	payOrderSuccessAction,
	payOrderFailureAction,
	getRiotCartAction,
	getEthCartAction,
} from "./actions"
import { getWalletAction } from "../wallet/actions"
import { getNetworkStatus } from "hooks/useMetaMaskAuth"
import errorNotify from "helper/errorNotify"
import { ETH_TEST_NET } from "constants/apiConfig"
import {
	ADD_ITEM_TO_CART,
	GET_CART,
	PAY_CAR,
	DELETE_CART,
	REMOVE_EXPIRED_ITEM,
	GET_ORDERS,
	GET_TRANSFERED,
	GET_ETH_CART,
	GET_RIOT_CART,
	PAY_ORDER,
} from "./types"

function removeItemFromCartApi({
	assetId,
	assetType,
	removeQuantity,
	quantity,
}) {
	return request({
		path: `marketplace/cart/${assetId}`,
		opts: {
			method: "DELETE",
			data: {
				assetType,
				removeQuantity,
				quantity,
			},
		},
	})
}

function* removeItemFromCartSaga({ payload }) {
	try {
		const result = yield call(removeItemFromCartApi, payload)
		if (result.data.message === "success") {
			yield put(deleteCartSuccessAction())
			yield put(getCartAction())
			yield put(getEthCartAction())
			yield put(getRiotCartAction())
			// yield put(removeExpiredItemAction(payload))
			notify({
				type: "success",
				title: i18n.t("helperText.itemDeletedFromCart"),
			})
		}
	} catch (err) {
		if (err.response) {
			notify({
				title: err.response.data.message,
				desc: err.response.data.errors,
			})
		}
		yield put(deleteCartFailureAction(err))
	}
}

function* removeExpiredItemSaga({ payload }) {
	try {
		// I just try to remove expired item from DB,
		// when the timer after timer runs out
		// I don't catch error or response here.
		yield call(removeItemFromCartApi, payload)
	} catch (error) {
		console.log(error)
	}
}

function addItemToCartApi(params) {
	const { itemId, assetType, captchaV2, captchaV3, quantity } = params
	return request({
		path: `marketplace/buy/${itemId}`,
		opts: {
			method: "POST",
			data: {
				assetType: assetType.toLowerCase(),
				captchaV2,
				captchaV3,
				quantity,
			},
		},
	})
}

function* addItemToCartSaga({ payload }) {
	const { itemId, assetType, captchaV2, captchaV3, callback, quantity } =
		payload
	const params = {
		itemId,
		assetType: assetType ? assetType.toLowerCase() : assetType,
		captchaV2,
		captchaV3,
		quantity,
	}
	try {
		const result = yield call(addItemToCartApi, params)
		if (result.data.assetHoldResult) {
			if (result.data.partialFailureCount) {
				errorNotify(
					i18n.t("helperText.itemAddedToCartPartial", {
						quantity: result.data.partialFailureCount,
					})
				)
			} else {
				notify({
					type: "success",
					title: i18n.t("helperText.itemAddedToCart"),
				})
			}
			yield put(addItemToCartSuccAction(result.data))
			yield put(getCartAction())
			yield put(getEthCartAction())
			yield put(getRiotCartAction())
		} else {
			yield put(addItemToCartFailAction(result.data))
			errorNotify()
		}
		if (typeof callback === "function") {
			callback(false)
		}
	} catch (err) {
		if (err.response) {
			errorNotify(err.response?.data)
		}
		yield put(addItemToCartFailAction(err))
		if (typeof callback === "function") {
			callback(false)
		}
	}
}

function getCartRequest(payload) {
	const { page = 1, size = 24 } = payload || {}

	return request({
		path: `marketplace/cart?page=${page}&size=${size}`,
		opts: {
			method: "GET",
		},
	})
}

function* getCartSaga({ payload }) {
	try {
		const result = yield call(getCartRequest, payload)
		yield put(getCartSuccessAction(result.data))
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(getCartFailureAction(err))
	}
}

function getEthCartRequest(payload) {
	const { page = 1, size = 24 } = payload || {}

	return request({
		path: `marketplace/cart?page=${page}&size=${size}&currency=ETH`,
		opts: {
			method: "GET",
		},
	})
}

function* getEthCartSaga({ payload }) {
	try {
		const result = yield call(getEthCartRequest, payload)
		yield put(getEthCartSuccessAction(result.data))
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(getEthCartFailureAction(err))
	}
}

function getRiotCartRequest(payload) {
	const { page = 1, size = 24 } = payload || {}

	return request({
		path: `marketplace/cart?page=${page}&size=${size}&currency=RIOT`,
		opts: {
			method: "GET",
		},
	})
}

function* getRiotCartSaga({ payload }) {
	try {
		const result = yield call(getRiotCartRequest, payload)
		yield put(getRiotCartSuccessAction(result.data))
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(getRiotCartFailureAction(err))
	}
}

function getOrdersRequest(payload) {
	const { page = 1, size = 24 } = payload || {}
	return request({
		path: `marketplace/inprogress?page=${page}&size=${size}`,
		opts: {
			method: "GET",
		},
	})
}

function* getOrdersSaga({ payload }) {
	try {
		const result = yield call(getOrdersRequest, payload)
		yield put(getOrdersSuccessAction(result.data))
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(getOrdersFailureAction(err))
	}
}

function getCompleteTransfersRequest(payload) {
	const { page = 1, size = 24 } = payload || {}
	return request({
		path: `marketplace/complete?page=${page}&size=${size}`,
		opts: {
			method: "GET",
		},
	})
}

function* getCompleteTransfersSaga({ payload }) {
	try {
		const result = yield call(getCompleteTransfersRequest, payload)
		yield put(getTransferedSuccessAction(result.data))
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(getTransferedFailureAction(err))
	}
}

function payCarRequest(params) {
	const { itemId, assetType, transactionHash } = params
	return request({
		path: `marketplace/prePay/${itemId}`,
		opts: {
			method: "POST",
			data: {
				assetType: assetType.toLowerCase(),
				transactionHash,
			},
		},
	})
}

const unitValue = (price, currency) => {
	switch (currency) {
		case "ETH":
			return price * 1e18
		case "milliether":
			return price * 1e15
		case "microether":
			return price * 1e12
		case "Gwei":
			return price * 1e9
		case "Mwei":
			return price * 1e6
		case "Kwei":
			return price * 1e3
		case "wei":
			return price * 1
		default:
			break
	}
}

async function etherCallRequest({ ethAddress, salePrice, saleCurrency }) {
	const value = unitValue(salePrice, saleCurrency)

	try {
		let result
		if (window.ethereum) {
			const accounts = await window.ethereum.request({
				method: "eth_requestAccounts",
			})

			if (accounts[0]) {
				const transactionParameters = {
					to: ethAddress, // Required except during contract publications.
					from: accounts[0], // must match user's active address.
					value: value?.toString(16), // Only required to send ether to the recipient from the initiating external account.
				}

				// txHash is a hex string
				// As with any RPC call, it may throw an error
				const txHash = await window.ethereum.request({
					method: "eth_sendTransaction",
					params: [transactionParameters],
				})
				return txHash
			} else {
				notify({
					title: i18n.t("helperText.invalidAccount"),
				})
			}
		}
		return result
	} catch (err) {
		// eslint-disable-next-line
    console.error("Payment Failed")
		throw err
	}
}

function getAssetRequest(assetId, assetType) {
	return request({
		path: `assets/${assetType.toLowerCase()}/c/${assetId}`,
		opts: {
			method: "GET",
		},
	})
}

function* payCarSaga({ payload }) {
	const {
		item: {
			assetId: itemId,
			sales: { assetType, salePrice, saleCurrency },
		},
	} = payload

	const isValidChain = yield call(getNetworkStatus)
	if (!(isValidChain === 1) && !ETH_TEST_NET) {
		notify({
			title: i18n.t("helperText.invalidNetwork"),
		})
		yield put(payCarFailureAction())
	} else {
		try {
			const result = yield call(
				getAssetRequest,
				itemId,
				assetType.toLowerCase()
			)
			if (result.data.saleStatus === 2) {
				const { ethAddress } = result.data
				const resultEther = yield call(etherCallRequest, {
					ethAddress,
					salePrice,
					saleCurrency,
				})

				if (resultEther) {
					notify({
						type: "success",
						title: i18n.t("helperText.paymentSubmitted"),
					})
					const params = {
						itemId,
						assetType: assetType.toLowerCase(),
						transactionHash: resultEther,
					}
					const { data } = yield call(payCarRequest, params)
					yield put(
						payCarSuccessAction({
							itemId,
							assetType: assetType.toLowerCase(),
							data,
						})
					)
				} else {
					notify({
						title: i18n.t("apologize"),
					})
					yield put(payCarFailureAction())
				}
			} else {
				notify({
					title: i18n.t("helperText.notAvailableToBuy"),
				})
				yield put(payCarFailureAction())
			}
		} catch (err) {
			// eslint-disable-next-line
      if (err.response || err.message) {
				notify({
					title: "Error",
					desc: err.response?.data?.message || err?.message,
				})
			}
			yield put(payCarFailureAction(err))
		}
	}
}

function createOrderRequest(currency) {
	return request({
		path: `orders/create?saleCurrency=${currency}`,
		opts: {
			method: "GET",
		},
	})
}

function* payOrderSaga({ payload }) {
	const { saleCurrency } = payload

	if (saleCurrency === "RIOT") {
		try {
			const result = yield call(createOrderRequest, saleCurrency)

			yield put(
				payOrderSuccessAction({
					data: {
						...result?.data,
					},
				})
			)

			// TODO: Dispatch wallet update.
			yield put(getWalletAction())

			notify({
				type: "success",
				title: i18n.t("helperText.paymentGameWalletSubmitted"),
			})
		} catch (err) {
			if (err.response || err.message) {
				notify({
					title: "Error",
					desc: err.response?.data?.message || err?.message,
				})
			}
			yield put(payOrderFailureAction(err))
		}
	} else {
		const isValidChain = yield call(getNetworkStatus)
		if (!(isValidChain === 1) && !ETH_TEST_NET) {
			notify({
				title: i18n.t("helperText.invalidNetwork"),
			})
			yield put(payOrderFailureAction())
		} else {
			try {
				const result = yield call(createOrderRequest, saleCurrency)
				if (result.data.saleStatus === 2) {
					const { sellerEthAddress, salePriceTotal } = result.data
					const orderId = result.data.id
					const resultEther = yield call(etherCallRequest, {
						ethAddress: sellerEthAddress,
						salePrice: salePriceTotal,
						saleCurrency,
					})

					if (resultEther) {
						notify({
							type: "success",
							title: i18n.t("helperText.paymentSubmitted"),
						})
						const params = {
							orderId,
							transactionHash: resultEther,
							saleCurrency,
						}
						const { data } = yield call(payOrderRequest, params)

						yield put(
							payOrderSuccessAction({
								data,
							})
						)
					} else {
						notify({
							title: i18n.t("apologize"),
						})
						yield put(payOrderFailureAction())
					}
				} else {
					notify({
						title: i18n.t("helperText.notAvailableToBuy"),
					})
					yield put(payOrderFailureAction())
				}
			} catch (err) {
				if (err.response || err.message) {
					notify({
						title: "Error",
						desc: err.response?.data?.message || err?.message,
					})
				}
				yield put(payOrderFailureAction(err))
			}
		}
	}
}

function payOrderRequest(params) {
	const { orderId, transactionHash } = params
	return request({
		path: `marketplace/prepay/order/${orderId}`,
		opts: {
			method: "POST",
			data: {
				transactionHash,
			},
		},
	})
}

const marketplaceSaga = [
	takeLatest(ADD_ITEM_TO_CART, addItemToCartSaga),
	takeLatest(GET_CART, getCartSaga),
	takeLatest(PAY_CAR, payCarSaga),
	takeLatest(DELETE_CART, removeItemFromCartSaga),
	takeLatest(REMOVE_EXPIRED_ITEM, removeExpiredItemSaga),
	takeLatest(GET_ORDERS, getOrdersSaga),
	takeLatest(GET_TRANSFERED, getCompleteTransfersSaga),
	takeLatest(GET_ETH_CART, getEthCartSaga),
	takeLatest(GET_RIOT_CART, getRiotCartSaga),
	takeLatest(PAY_ORDER, payOrderSaga),
]
export default marketplaceSaga
