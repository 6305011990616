import request from "./request"

export const getRacesApi = (params) =>
	request({
		path: `race/all`,
		opts: {
			method: "GET",
			params,
		},
	})

export const getShardsApi = () =>
	request({
		path: `race/shards`,
		opts: {
			method: "GET",
		},
	})

export const enterRaceApi = (data) =>
	request({
		path: `race/enter`,
		opts: {
			method: "POST",
			data,
		},
	})

export const exitRaceApi = (data) =>
	request({
		path: `race/leave`,
		opts: {
			method: "POST",
			data,
		},
	})

export const updateRaceApi = (data) =>
	request({
		path: `race/update`,
		opts: {
			method: "POST",
			data,
		},
	})

export const getMyRacesApi = (params) =>
	request({
		path: `race/mine`,
		opts: {
			method: "GET",
			params,
		},
	})

export const getMyWinningsTotalApi = (params) =>
	request({
		path: `race/winnings`,
		opts: {
			method: "GET",
			params,
		},
	})

export const getMyEntries = (params) =>
	request({
		path: `race/mine/entries`,
		opts: {
			method: "GET",
			params,
		},
	})

export const getMyStrategies = () =>
	request({
		path: "race/mine/strategy",
		opts: {
			method: "GET",
		},
	})

export const saveStrategy = (data) =>
	request({
		path: `race/strategy`,
		opts: {
			method: "POST",
			data,
		},
	})

export const saveStrategyName = (entryId, name) =>
	request({
		path: `race/strategy/${entryId}`,
		opts: {
			method: "POST",
			data: name,
		},
	})

export const deleteStrategy = (id) =>
	request({
		path: `race/strategy/${id}`,
		opts: { method: "DELETE" },
	})

export const fetchUserRaceEntry = (entry) =>
	request({
		path: `race/mine/entries/${entry.raceId}?shardKey=${entry.shardKey}`,
		opts: {
			method: "GET",
		},
	})

export const fetchRaceEntry = (entry) =>
	request({
		path: `race/entries/${entry.raceId}?shardKey=${entry.shardKey}`,
		opts: {
			method: "GET",
		},
	})

export const loadRace = (data) => {
	const path = data.shardKey
		? `race/load/${data.raceId}?shardKey=${data.shardKey}`
		: `race/load/${data.raceId}`

	return request({
		path,
		opts: {
			method: "GET",
			data,
		},
	})
}

export const fetchRaceEntryStatus = (raceId) =>
	request({
		path: `race/entry/status/${raceId}`,
		opts: {
			method: "GET",
		},
	})
