import request from "./request"

// public
export const view = (data) =>
	request({
		path: "billboard/view",
		opts: {
			method: "GET",
			data,
		},
	})

// public
export const get = (data) =>
	request({
		path: "billboard/load",
		opts: {
			method: "GET",
			data,
		},
	})

/**
 * AUTH
 * @param {
 *  billboardId: string
 *  desktopImageId: string
 *  mobileImageId: string
 *  url: string
 * } data
 * @returns
 */
export const create = (data) =>
	request({
		path: "billboard/create",
		opts: {
			method: "POST",
			data,
		},
	})

// auth
export const toggleActive = (billboardId) =>
	request({
		path: `billboard/toggleIsActive/${billboardId}`,
		opts: {
			method: "POST",
		},
	})

/**
 * AUTH
 * @param billboardId
 * @returns
 */
export const increment = (billboardId) =>
	request({
		path: `billboard/incrementClickCounter/${billboardId}`,
		opts: {
			method: "POST",
		},
	})
