export const BUY_CAR = "BUY_CAR"
export const BUY_CAR_SUCCESS = "BUY_CAR_SUCCESS"
export const BUY_CAR_FAILED = "BUY_CAR_FAILED"

export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART"
export const ADD_ITEM_TO_CART_SUCC = "ADD_ITEM_TO_CART_SUCC"
export const ADD_ITEM_TO_CART_FAILED = "ADD_ITEM_TO_CART_FAILED"

export const GET_CART = "GET_CART"
export const GET_CART_SUCCESS = "GET_CART_SUCCESS"
export const GET_CART_FAILED = "GET_CART_FAILED"

export const GET_ETH_CART = "GET_ETH_CART"
export const GET_ETH_CART_SUCCESS = "GET_ETH_CART_SUCCESS"
export const GET_ETH_CART_FAILED = "GET_ETH_CART_FAILED"

export const GET_RIOT_CART = "GET_RIOT_CART"
export const GET_RIOT_CART_SUCCESS = "GET_RIOT_CART_SUCCESS"
export const GET_RIOT_CART_FAILED = "GET_RIOT_CART_FAILED"

export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAILED = "GET_ORDERS_FAILED"

export const GET_TRANSFERED = "GET_TRANSFERED"
export const GET_TRANSFERED_SUCCESS = "GET_TRANSFERED_SUCCESS"
export const GET_TRANSFERED_FAILED = "GET_TRANSFERED_FAILED"

export const DELETE_CART = "DELETE_CART"
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS"
export const DELETE_CART_FAILED = "DELETE_CART_FAILED"

export const PAY_CAR = "PAY_CAR"
export const PAY_CAR_SUCCESS = "PAY_CAR_SUCCESS"
export const PAY_CAR_FAILED = "PAY_CAR_FAILED"

export const REMOVE_EXPIRED_ITEM = "REMOVE_EXPIRED_ITEM"

export const PAY_ORDER = "PAY_ORDER"
export const PAY_ORDER_SUCCESS = "PAY_ORDER_SUCCESS"
export const PAY_ORDER_FAILED = "PAY_ORDER_FAILED"
