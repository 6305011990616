export const CREATE_TEAM_REQ = "CREATE_TEAM_REQ"
export const CREATE_TEAM_FAILED = "CREATE_TEAM_FAILED"

export const UPDATE_TEAM_REQ = "UPDATE_TEAM_REQ"
export const UPDATE_TEAM_FAILED = "UPDATE_TEAM_FAILED"

export const REMOVE_TEAM_REQ = "REMOVE_TEAM_REQ"
export const REMOVE_TEAM_FAILED = "REMOVE_TEAM_FAILED"

export const REMOVE_MEMBER_REQ = "REMOVE_MEMBER_REQ"
export const REMOVE_MEMBER_FAILED = "REMOVE_MEMBER_FAILED"

export const LOAD_TEAM_REQ = "LOAD_TEAM_REQ"
export const LOAD_TEAM_FAILED = "LOAD_TEAM_FAILED"

export const LOAD_TEAMS_REQ = "LOAD_TEAMS_REQ"
export const LOAD_TEAMS_FAILED = "LOAD_TEAMS_FAILED"

export const ACCEPT_INVITE = "ACCEPT_INVITE"
export const ACCEPT_INVITE_SUCCESS = "ACCEPT_INVITE_SUCCESS"
export const ACCEPT_INVITE_FAILED = "ACCEPT_INVITE_FAILED"

export const REJECT_INVITE = "REJECT_INVITE"
export const REJECT_INVITE_SUCCESS = "REJECT_INVITE_SUCCESS"
export const REJECT_INVITE_FAILED = "REJECT_INVITE_FAILED"

export const CANCEL_INVITE = "CANCEL_INVITE"
export const CANCEL_INVITE_SUCCESS = "CANCEL_INVITE_SUCCESS"
export const CANCEL_INVITE_FAILED = "CANCEL_INVITE_FAILED"

export const SET_TEAM = "SET_TEAM"
export const SET_TEAMS = "SET_TEAMS"

export const LOAD_USER_ASSETS = "LOAD_USER_ASSETS"
export const LOAD_USER_ASSETS_SUCCESS = "LOAD_USER_ASSETS_SUCCESS"
export const LOAD_USER_ASSETS_FAILED = "LOAD_USER_ASSETS_FAILED"
