import request from "./request"
import tournaments from "./mockdata/tournaments"

const prefix = "tournaments"
const prefixusers = "tournamentusers"
const prefixheats = "tournamentheats"
const prefixtrack = "racetrack"

export const getAllTournaments = (params) =>
request({
  path: prefix,
  opts: {
    method: "GET",
    params,
  },
})
export const getTournamentById = (tournamentId) =>
request({
  path: `${prefix}/${tournamentId}`,
  opts: {
    method: "GET",
  },
})
export const getTournamentHeatById = (tournamentId) =>
request({
  path: `${prefixheats}/${tournamentId}`,
  opts: {
    method: "GET",
  },
})
export const createTournament = (data) =>
request({
  path: prefix,
  opts: {
    method: "POST",
    data,
  },
})
export const updateTournament = (data) =>
request({
  path: `${prefix}/update`,
  opts: {
    method: "POST",
    data,
  },
})
export const cancelTournament = (data) =>
request({
  path: `${prefix}/cancel`,
  opts: {
    method: "POST",
    data,
  },
})
export const inviteUser = (tournamentId, userId) =>
request({
	path: `${prefixusers}/invite`,
	opts: {
		method: "POST",
		data: { userId, tournamentId },
	},
})
export const rejectUser = (tournamentId, userId) =>
request({
	path: `${prefixusers}/kick`,
	opts: {
		method: "POST",
		data: { userId, tournamentId },
	},
})
export const rejectInvite = (tournamentId) =>
request({
	path: `${prefixusers}/reject`,
	opts: {
		method: "POST",
		data: { tournamentId },
	},
})
export const enterTournament = (tournamentId) =>
request({
	path: `${prefix}/enter`,
	opts: {
		method: "POST",
		data: { tournamentId },
	},
})
export const enterTournamentHeat = (data) =>
request({
	path: `${prefixheats}/enter`,
	opts: {
		method: "POST",
		data,
	},
})
export const getTournamentUsers = (params) =>
request({
  path: prefix,
  opts: {
    method: "GET",
    params,
  },
})
export const getAllinvites = (params) =>
request({
  path: prefixusers,
  opts: {
    method: "GET",
    params,
  },
})
export const getAllTracks = (params) =>
request({
  path: `${prefixtrack}`,
  opts: {
    method: "GET",
    params,
  },
})
