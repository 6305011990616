import React from "react"

const IconWallet = (props) => (
	<svg
		width="24"
		height="21"
		viewBox="0 0 24 21"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			id="Icon_awesome-wallet"
			data-name="Icon awesome-wallet"
			d="M21.619,6.75H3.75a.75.75,0,0,1,0-1.5h18a.75.75,0,0,0,.75-.75,2.25,2.25,0,0,0-2.25-2.25H3a3,3,0,0,0-3,3v15a3,3,0,0,0,3,3H21.619A2.321,2.321,0,0,0,24,21V9A2.321,2.321,0,0,0,21.619,6.75ZM19.5,16.5A1.5,1.5,0,1,1,21,15,1.5,1.5,0,0,1,19.5,16.5Z"
			transform="translate(0 -2.25)"
		/>
	</svg>
)

export default IconWallet
