import React from "react"

export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			d="M15.375,3.375a12,12,0,1,0,12,12A12.006,12.006,0,0,0,15.375,3.375Zm-2.4,17.4V9.975l7.2,5.4Z"
			transform="translate(-3.375 -3.375)"
		/>
	</svg>
)
