import { createReducer } from "@reduxjs/toolkit"
import ClubActionTypes from "./types"

const initState = {
	rocLevelsLoading: false,
	levels: [],
}

const clubReducer = createReducer(initState, {
	[ClubActionTypes.ROC_LEVELS_LOADING]: (state, { payload }) => {
		state.rocLevelsLoading = payload
	},

	[ClubActionTypes.CLAIM_LOADING]: (state, { payload }) => {
		state.claimLoading = payload
	},

	[ClubActionTypes.LEVEL_LOADING]: (state, { payload }) => {
		state.levels = state.levels.map((club) =>
			club.levelId === payload.id
				? { ...club, loading: payload.loading }
				: { ...club, loading: false }
		)
	},

	[ClubActionTypes.SET_ROC_LEVELS]: (state, { payload }) => {
		state.levels = payload
	},
})

export default clubReducer
