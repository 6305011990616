import request from "./request"

export const getWithdrawLimits = (params) =>
	request({
		path: `withdrawallimits/load`,
		opts: {
			method: "GET",
			params,
		},
	})
