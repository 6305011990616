import { useCallback, useEffect, useMemo } from "react"
import { Tooltip } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import IconCircleHelp from "components/commons/Icons/IconCircleHelp"
import IconInfo from "components/commons/Icons/IconInfo"
import {
	groupDetailSelector,
	isLoadedGroupSelector,
} from "store/group/selectors"
import { getGroupStatusAction } from "store/group/actions"
import dayjs from "dayjs"

import "./dropregister.scss"

const MarketplaceDropRegister = () => {
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const loaded = useSelector(isLoadedGroupSelector)
	const data = useSelector(groupDetailSelector)
	const handleClickViewDetail = useCallback(() => {
		if (data?.article) {
			// TODO: check data.article is valid url
			window.open(data.article, "_blank")
		}
	}, [data?.article])

	const content = useMemo(() => {
		if (!Array.isArray(data?.data)) return false

		const allDrops = data.data.map((dropEntry) => {
			const {
				drop,
				date,
				group,
				groupStart,
				groupEnd,
				article,
				registered,
				whitelist,
			} = dropEntry
			const formatted = groupStart
				? i18n.format(dayjs.utc(groupStart).$d, "MMM D", i18n.language)
				: i18n.format(dayjs.utc(date).$d, "MMM D", i18n.language)
			const dropDate = `${t("group.drop", { num: drop })} - ${formatted}`

			if (!!group && registered) {
				const start = i18n.format(groupStart, "hh:mm A", i18n.language)
				const end = i18n.format(groupEnd, "hh:mm A", i18n.language)
				const str = t("group.groupStartEnd", { num: group, start, end })

				return (
					<>
						<div className="DropRegisterContainer mr-2">
							<div className="DropDate">
								<span>{dropDate}</span>
								{article && (
									<Tooltip title={t("group.viewDetail")} placement="bottom">
										<span
											className="helpIcon"
											role="presentation"
											onClick={handleClickViewDetail}
										>
											<IconCircleHelp />
										</span>
									</Tooltip>
								)}
							</div>
							<div className="GroupStartEndDate flex mt-2">
								<span>{str}</span>
								<Tooltip title={t("group.localTime")} placement="bottom">
									<span className="helpIcon" role="presentation">
										<IconInfo />
									</span>
								</Tooltip>
							</div>
							{whitelist && whitelist.length > 0 && (
								<div className="Whitelistlimits">
									<span className="whitelistDropLimit">{`${t(
										"whitelistDropLimit"
									)}: ${whitelist[0]?.dropLimit}`}</span>
									{article && (
										<Tooltip
											title={
												<>
													{whitelist.map((wl) => (
														<>
															{`${wl.asset?.name}: ${wl.quantity}`}
															<br />
														</>
													))}
													<>
														{`${t("dropLimitTotal")}: ${
															whitelist[0]?.dropLimit
														}`}
													</>
												</>
											}
											placement="bottom"
										>
											<span
												className="helpIcon"
												role="presentation"
												onClick={handleClickViewDetail}
											>
												<IconCircleHelp width="20" height="20" fill="#d339fa" />
											</span>
										</Tooltip>
									)}
								</div>
							)}
						</div>
					</>
				)
			}

			return (
				<>
					<div className="DropRegisterContainer mr-2">
						<div className="DropDate flex align-center">
							<span className="mr-1">{dropDate}</span>
							{article && (
								<Tooltip title={t("group.viewDetail")} placement="bottom">
									<span
										className="helpIcon"
										role="presentation"
										onClick={handleClickViewDetail}
									>
										<IconCircleHelp width="20" height="20" fill="#d339fa" />
									</span>
								</Tooltip>
							)}
						</div>

						<div className="GroupStartEndDate">
							<span>{t("group.notInDrop")}</span>
						</div>
					</div>
				</>
			)
		})

		return allDrops
	}, [data])


	useEffect(() => {
		dispatch(getGroupStatusAction())
	}, [])

	if (!content || !loaded) {
		return null
	}

	return <div className="DropRegisterParentContainer flex">{content}</div>
}

export default MarketplaceDropRegister
