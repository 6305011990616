/* eslint-disable no-case-declarations */
import { createReducer } from "@reduxjs/toolkit"
import { initialState } from "../marketplaceInitialState"
import errorNotify from "helper/errorNotify"
import i18n from "i18n"
import {
	ADD_ITEM_TO_CART,
	ADD_ITEM_TO_CART_SUCC,
	ADD_ITEM_TO_CART_FAILED,
	GET_CART,
	GET_CART_SUCCESS,
	GET_CART_FAILED,
	GET_ETH_CART,
	GET_ETH_CART_SUCCESS,
	GET_ETH_CART_FAILED,
	GET_RIOT_CART,
	GET_RIOT_CART_SUCCESS,
	GET_RIOT_CART_FAILED,
	PAY_CAR,
	PAY_CAR_SUCCESS,
	PAY_CAR_FAILED,
	DELETE_CART,
	DELETE_CART_SUCCESS,
	DELETE_CART_FAILED,
	REMOVE_EXPIRED_ITEM,
	GET_ORDERS,
	GET_ORDERS_SUCCESS,
	GET_ORDERS_FAILED,
	GET_TRANSFERED,
	GET_TRANSFERED_SUCCESS,
	GET_TRANSFERED_FAILED,
	PAY_ORDER,
	PAY_ORDER_SUCCESS,
	PAY_ORDER_FAILED,
} from "./types"

const removeExpiredItem = (state, action) => {
	const { assetId, assetType } = action.payload
	state.cart.rows = state.cart.rows.filter(
		(ite) =>
			!(
				ite.assetId === assetId &&
				ite.sales.assetType.toLowerCase() === assetType.toLowerCase()
			)
	)
	state.cart.count -= 1

	if (state.ethCart && state.ethCart.rows) {
		state.ethCart.rows = state.ethCart.rows.filter(
			(ite) =>
				!(
					ite.assetId === assetId &&
					ite.sales.assetType.toLowerCase() === assetType.toLowerCase()
				)
		)
		state.ethCart.count = state.ethCart.rows.length
	}
	if (state.riotCart && state.riotCart.rows) {
		state.riotCart.rows = state.riotCart.rows.filter(
			(ite) =>
				!(
					ite.assetId === assetId &&
					ite.sales.assetType.toLowerCase() === assetType.toLowerCase()
				)
		)
		state.riotCart.count = state.riotCart.rows.length
	}
}

const paySuccess = (state, action) => {
	const { itemId, assetType, data } = action.payload

	state.error = undefined
	state.payLoading = false
	state.paidStatus = data

	state.cart.rows = state.cart.rows.map((ite) => {
		if (
			ite.assetId === itemId &&
			ite.sales.assetType.toLowerCase() === assetType.toLowerCase()
		) {
			return {
				...ite,
				saleStatus: 3,
			}
		}
		return ite
	})
}

const payOrderSuccess = (state, action) => {
	const { saleCurrency, failedAssetPaymentCount } = action.payload.data

	if (failedAssetPaymentCount) {
		errorNotify(
			i18n.t("orders.partialFailure", { count: failedAssetPaymentCount })
		)
	}

	state.error = undefined
	state.payLoading = false

	// Are we clearing the ethCart?
	if (saleCurrency === "ETH") {
		state.ethCart.rows = []
		state.cart.count -= state.ethCart.count
		state.ethCart.count = 0
	}

	// Are we clearing the riotCart
	if (saleCurrency === "RIOT") {
		state.riotCart.rows = []
		state.cart.count -= state.riotCart.count
		state.riotCart.count = 0
	}
}

const payOrderFailure = (state, action) => {
	state.payLoading = false
	state.error = action.error
	state.paidStatus = {}
}

const carReducer = createReducer(initialState.marketPlaceState, {
	[ADD_ITEM_TO_CART]: (state, action) => {
		state.addingToCart = {
			[action.payload.itemId]: true,
		}
		state.buyLoading = true // TODO: REMOVE buyloading
	},
	[ADD_ITEM_TO_CART_SUCC]: (state, action) => {
		state.error = undefined
		state.buyLoading = false // TODO: REMOVE buyloading
		state.addingToCart = {}
		state.assetStatus = action.payload
	},
	[ADD_ITEM_TO_CART_FAILED]: (state, action) => {
		if (
			action?.payload?.response?.data?.errorKey !== "reCaptchaV3Failed" &&
			action?.payload?.response?.data?.errorKey !== "reCaptchaVResubmit"
		) {
			state.addingToCart = {}
		}
		state.buyLoading = false // TODO: REMOVE buyloading
		state.error = action?.payload?.response?.data?.errorKey
		state.assetStatus = {}
	},

	[GET_CART]: (state) => {
		state.loading = true
	},
	[GET_CART_SUCCESS]: (state, action) => {
		state.error = undefined
		state.loading = false
		state.cart = action.payload
	},
	[GET_CART_FAILED]: (state, action) => {
		state.loading = false
		state.error = action.error
		state.cart = {}
	},

	[GET_ETH_CART]: (state) => {
		state.ethLoading = true
	},
	[GET_ETH_CART_SUCCESS]: (state, action) => {
		state.error = undefined
		state.ethLoading = false
		state.ethCart = action.payload
	},
	[GET_ETH_CART_FAILED]: (state, action) => {
		state.ethLoading = false
		state.error = action.error
		state.ethCart = {}
	},

	[GET_RIOT_CART]: (state) => {
		state.riotLoading = true
	},
	[GET_RIOT_CART_SUCCESS]: (state, action) => {
		state.error = undefined
		state.riotLoading = false
		state.riotCart = action.payload
	},
	[GET_RIOT_CART_FAILED]: (state, action) => {
		state.riotLoading = false
		state.error = action.error
		state.riotCart = {}
	},

	[GET_ORDERS]: (state) => {
		state.ordersLoading = true
	},
	[GET_ORDERS_SUCCESS]: (state, action) => {
		state.error = undefined
		state.ordersLoading = false
		state.orders = action.payload
	},
	[GET_ORDERS_FAILED]: (state, action) => {
		state.ordersLoading = false
		state.error = action.error
		state.orders = {}
	},
	[GET_TRANSFERED]: (state) => {
		state.transfersLoading = true
	},
	[GET_TRANSFERED_SUCCESS]: (state, action) => {
		state.error = undefined
		state.transfersLoading = false
		state.transfers = action.payload
	},
	[GET_TRANSFERED_FAILED]: (state, action) => {
		state.transfersLoading = false
		state.error = action.error
		state.transfers = {}
	},

	[DELETE_CART]: (state) => {
		state.deleteLoading = true
	},
	[DELETE_CART_SUCCESS]: (state) => {
		state.error = undefined
		state.deleteLoading = false
	},
	[DELETE_CART_FAILED]: (state, action) => {
		state.deleteLoading = false
		state.error = action.error
	},

	[PAY_CAR]: (state) => {
		state.payLoading = true
	},
	[PAY_CAR_SUCCESS]: paySuccess,
	[PAY_CAR_FAILED]: (state, action) => {
		state.payLoading = false
		state.error = action.error
		state.paidStatus = {}
	},

	[PAY_ORDER]: (state) => {
		state.payLoading = true
	},
	[PAY_ORDER_SUCCESS]: payOrderSuccess,
	[PAY_ORDER_FAILED]: payOrderFailure,

	[REMOVE_EXPIRED_ITEM]: removeExpiredItem,
})

export default carReducer
