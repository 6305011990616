export const ASSET_TYPE = {
	CAR: {
		key: "cars",
		value: "Car",
		slug: "riot-racers-cars",
	},
	DRIVER: {
		key: "drivers",
		value: "Driver",
		slug: "riot-racer-drivers",
	},
	STATION: {
		key: "stations",
		value: "Gasstation",
		slug: "riot-racers-gas-stations",
	},
	SHOP: {
		key: "shops",
		value: "Mechanicshop",
		slug: "riot-racers-mechanic-shops",
	},
	LAND: {
		key: "lands",
		value: "Racetrackland",
		slug: "riot-racers-racetrack-land",
	},
	BILLBOARD: {
		key: "billboardlands",
		value: "Billboardland",
		slug: "riot-racers-billboard-land",
	},
	CRYPTOWHIP: {
		key: "cryptowhips",
		value: "CryptoWhip",
		slug: "riot-racers-cryptowhipS",
	},
	ACTIVE: {
		key: "selectedCar",
		value: "Car",
	},
	CURRENT: {
		key: "currentAsset",
		value: "Car",
	},
}
