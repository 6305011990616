export const GET_BALANCE = "[rewards] GET_BALANCE"
export const GET_BALANCE_SUCCESS = "[rewards] GET_BALANCE_SUCCESS"
export const GET_BALANCE_FAILURE = "[rewards] GET_BALANCE_FAILURE"

export const CLAIM_REWARDS = "[rewards] CLAIM_REWARDS"
export const CLAIM_REWARDS_SUCCESS = "[rewards] CLAIM_REWARDS_SUCCESS"
export const CLAIM_REWARDS_FAILURE = "[rewards] CLAIM_REWARDS_FAILURE"

export const GET_REWARDS_HISTORY = "[rewards] GET_REWARDS_HISTORY"
export const GET_REWARDS_HISTORY_SUCCESS =
	"[rewards] GET_REWARDS_HISTORY_SUCCESS"
export const GET_REWARDS_HISTORY_FAILURE =
	"[rewards] GET_REWARDS_HISTORY_FAILURE"
