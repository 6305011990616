import { createSelector } from "@reduxjs/toolkit"

export const marketplaceSelector = (state) => state.marketplace

export const cartSelector = createSelector(marketplaceSelector, (state) => ({
	cart: state.cart,
}))

export const cartEthSelector = createSelector(marketplaceSelector, (state) => ({
	ethCart: state.ethCart,
}))

export const cartRiotSelector = createSelector(
	marketplaceSelector,
	(state) => ({
		riotCart: state.riotCart,
	})
)

export const orderSelector = createSelector(marketplaceSelector, (state) => ({
	orders: state.orders,
}))

export const transfersSelector = createSelector(
	marketplaceSelector,
	(state) => ({
		transfers: state.transfers,
	})
)

export const isItemInCartSelector = createSelector(
	cartSelector,
	(_, itemIdAndAssetType) => itemIdAndAssetType,
	({ cart }, itemIdAndAssetType) =>
		cart?.rows
			?.map((ite) => {
				if (!ite?.sales) {
					return null
				}
				return `${ite.assetId}-${ite.sales.assetType}`
			})
			.includes(itemIdAndAssetType)
)

export const assetStatusSelector = createSelector(
	marketplaceSelector,
	(state) => ({
		assetStatus: state.assetStatus,
	})
)

export const paidStatusSelector = createSelector(
	marketplaceSelector,
	(state) => ({
		paidStatus: state.paidStatus,
	})
)

export const loadingSelector = createSelector(marketplaceSelector, (state) => ({
	loading: state.loading,
	buyLoading: state.buyLoading,
	payLoading: state.payLoading,
	deleteLoading: state.deleteLoading,
	ordersLoading: state.ordersLoading,
	transfersLoading: state.tranfersLoading,
	ethLoading: state.ethLoading,
	riotLoading: state.riotLoading,
}))

export const errorSelector = createSelector(marketplaceSelector, (state) => ({
	error: state.error,
}))

export const cartTotalCountSelector = createSelector(
	marketplaceSelector,
	(state) => ({
		totalCount: state.cart?.count,
	})
)

export const isAddingToCartSelector = createSelector(
	marketplaceSelector,
	(state) => state.addingToCart
)

export const isAddingToCartLoadingSelector = createSelector(
	marketplaceSelector,
	(state) => ({ buyLoading: state.buyLoading })
)

export const isPayingForCartSelector = createSelector(
	marketplaceSelector,
	(state) => state.payLoading
)

export const isDeletingFromCartSelector = createSelector(
	marketplaceSelector,
	(state) => ({ deleteLoading: state.deleteLoading })
)
