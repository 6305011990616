const assetsActionTypes = {
	GET_ASSETS_REQ: "[assets] get assets request",
	GET_ASSETS_SUCC: "[assets] get assets success",
	GET_ASSETS_FAIL: "[assets] get assets failed",

	GET_ASSETS_ETH_REQ: "[assets] get assets by ETH",

	GET_ACTIVE_CAR_REQ: "[assets] get selected car request",
	GET_CURRENT_CAR_REQ: "[assets] get current car request",
	SET_ACTIVE_CAR_REQ: "[assets] set selected car request",

	GET_CHECK_ASSET_NAME_REQ: "[assets] check asset name request",
	GET_CHECK_ASSET_NAME_SUCC: "[assets] check asset name request success",
	GET_CHECK_ASSET_NAME_FAIL: "[assets] check asset name request failed",

	UPDATE_ASSET_NAME_REQ: "[assets] update asset name request",
	UPDATE_ASSET_NAME_SUCC: "[assets] update asset name success",
	UPDATE_ASSET_NAME_FAIL: "[assets] update asset name failed",

	TOGGLE_ASSET_FAVORITE: "[assets] toggle asset favorite",
	TOGGLE_ASSET_FAVORITE_SUCC: "[assets] toggle asset favorite success",

	UPDATE_ASSET: "[assets] update asset",
	DELETE_ASSET_RENTAL: "[assets] delete asset rental",

	GET_ASSETS: "[assets] GET_ASSETS",
	GET_ASSETS_SUCCESS: "[assets] GET_ASSETS_SUCCESS",
	GET_ASSETS_FAILED: "[assets] GET_ASSETS_FAILED",
}

export default assetsActionTypes
