import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import RioScrollBar from "components/RioScrollBar"
import { getUserMenu } from "constants/routes"
import { getUsernameSelector } from "store/auth/selectors"

import "./styles.scss"

const SideMenu = () => {
	const { pathname } = useLocation()
	const username = useSelector(getUsernameSelector)
	const { i18n, t } = useTranslation()
	const opts = useMemo(() => getUserMenu(username), [i18n.language, username])

	return (
		<div className="DashboardSideMenu">
			<RioScrollBar>
				<div className="heading1">{t("account")}</div>
				{opts.map((elem, index) =>
					elem.route === `/rr/${username}` ? null : (
						<Link to={elem.route} key={index}>
							<div
								className={
									pathname === elem.route
										? "SideMenuItem active"
										: "SideMenuItem"
								}
							>
								{elem.icon}
								<span>{elem.title}</span>
							</div>
						</Link>
					)
				)}
			</RioScrollBar>
		</div>
	)
}

export default SideMenu
