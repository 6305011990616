import { createReducer } from "@reduxjs/toolkit"
import * as types from "./types"

const initialState = {
	balance: {
		loading: false,
		response: null,
		error: null,
	},

	claimedRewards: {
		loading: false,
		response: null,
		error: null,
	},

	rewardsHistory: {
		loading: false,
		response: null,
		error: null,
	},
}

const rewardsReducer = createReducer(initialState, {
	[types.GET_BALANCE]: (state) => {
		state.balance.loading = true
		state.balance.error = null
	},
	[types.GET_BALANCE_SUCCESS]: (state, { payload }) => {
		state.balance.response = payload
		state.balance.loading = false
		state.balance.error = null
	},
	[types.GET_BALANCE_FAILURE]: (state, { payload }) => {
		state.balance.error = payload
		state.balance.loading = false
	},

	[types.CLAIM_REWARDS]: (state) => {
		state.claimedRewards.loading = true
		state.claimedRewards.error = null
	},
	[types.CLAIM_REWARDS_SUCCESS]: (state, { payload }) => {
		state.claimedRewards.loading = false
		state.claimedRewards.response = payload
	},
	[types.CLAIM_REWARDS_FAILURE]: (state, { payload }) => {
		state.claimedRewards.error = payload
		state.claimedRewards.loading = false
	},

	[types.GET_REWARDS_HISTORY]: (state) => {
		state.rewardsHistory.loading = true
		state.rewardsHistory.error = null
	},
	[types.GET_REWARDS_HISTORY_SUCCESS]: (state, { payload }) => {
		state.rewardsHistory.response = payload
		state.rewardsHistory.loading = false
		state.rewardsHistory.error = null
	},
	[types.GET_REWARDS_HISTORY_FAILURE]: (state, { payload }) => {
		state.rewardsHistory.error = payload
		state.rewardsHistory.loading = false
	},
})

export default rewardsReducer
