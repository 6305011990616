import { createSelector } from "@reduxjs/toolkit"

const stateSelector = (state) => state.topNotice

export const topNoticeSelector = createSelector(
	stateSelector,
	(state) => state?.data || {}
)

export const isVisibleTopNotice = createSelector(
	topNoticeSelector,
	(topNotice) => topNotice?.visible
)
