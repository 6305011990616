import React from "react"

export default () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="25.5"
		height="16.773"
		viewBox="0 0 25.5 16.773"
	>
		<path
			id="Icon_material-group"
			data-name="Icon material-group"
			d="M17.864,14.045a3.273,3.273,0,1,0-3.273-3.273A3.259,3.259,0,0,0,17.864,14.045Zm-8.727,0a3.273,3.273,0,1,0-3.273-3.273A3.259,3.259,0,0,0,9.136,14.045Zm0,2.182C6.595,16.227,1.5,17.5,1.5,20.045v2.727H16.773V20.045C16.773,17.5,11.678,16.227,9.136,16.227Zm8.727,0c-.316,0-.676.022-1.058.055a4.6,4.6,0,0,1,2.149,3.764v2.727H25.5V20.045C25.5,17.5,20.405,16.227,17.864,16.227Z"
			transform="translate(-0.75 -6.75)"
			fill="none"
			stroke="#d339fa"
			strokeWidth="1.5"
		/>
	</svg>
)
