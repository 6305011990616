import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import ErrorPage from "views/Error"
import { withRouter } from "react-router-dom"

class ErrorBoundary extends Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false, errorRoutePath: "" }
	}

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	componentDidCatch() {
		const { location, history } = this.props
		const { errorRoutePath } = this.state
		const errorState = { hasError: true, errorRoutePath: location.pathname }

		this.setState(errorState, () => {
			this.unlisten = history.listen(({ pathname }) => {
				if (pathname !== errorRoutePath) {
					this.setState({ hasError: false }, () => {
						history.push(pathname)
						this.unlisten()
					})
				}
			})
		})
	}

	componentWillUnmount() {
		this.unlisten()
	}

	routeToHome = () => {
		this.props.history.push("/")
	}

	render() {
		const { hasError } = this.state
		const { children } = this.props
		return hasError ? <ErrorPage onClick={this.routeToHome} /> : children
	}
}

export default withTranslation()(withRouter(ErrorBoundary))
