export const EXTRA_DESKTOP = 1920
export const LARGE_DESKTOP = 1600
export const MEDIUM_DESKTOP = 1366
export const IPAD_LANDSCAPE = 1024
export const IPAD_PORTRAIT = 768
export const MEDIUM_MOBILE = 575
export const SMALL_MOBILE = 375

export const tailWindBps = {
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280,
	"2xl": 1536,
}
