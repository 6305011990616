export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="37.409"
		viewBox="0 0 24 37.409"
		{...props}
	>
		<g id="noun-reward-1354503" transform="translate(-170.811 -0.002)">
			<path
				id="Path_1287"
				data-name="Path 1287"
				d="M187.132,27.673a4.333,4.333,0,1,1-4.333-4.355,4.349,4.349,0,0,1,4.333,4.355m-4.321-13.413a.795.795,0,1,1-.791.794.793.793,0,0,1,.791-.794M194.694.336A.728.728,0,0,0,194.083,0h-8.234a.728.728,0,0,0-.664.432l-1.58,3.53L187.54,12.8h-1.6L180.43.419A.727.727,0,0,0,179.773,0h-8.234a.727.727,0,0,0-.611.335.735.735,0,0,0-.053.7L176.14,12.8h-.469a.732.732,0,0,0,0,1.464h5.037a2.262,2.262,0,0,0,.622,2.491l-.013.024-2.532,5.157a.176.176,0,0,1-.132.1l-5.662.827a1.642,1.642,0,0,0-.9,2.8l4.1,4.014a.177.177,0,0,1,.05.156l-.967,5.668a1.629,1.629,0,0,0,2.367,1.729l5.064-2.676a.174.174,0,0,1,.163,0l5.064,2.676a1.615,1.615,0,0,0,1.719-.125,1.633,1.633,0,0,0,.649-1.6l-.967-5.668a.177.177,0,0,1,.051-.156l4.1-4.014a1.642,1.642,0,0,0-.9-2.8l-5.662-.827a.175.175,0,0,1-.132-.1l-2.526-5.144a2.263,2.263,0,0,0,.665-2.528h5.037a.732.732,0,0,0,0-1.464h-.469l5.265-11.762a.735.735,0,0,0-.053-.7M179.924,27.674a2.876,2.876,0,1,0,2.876-2.891,2.887,2.887,0,0,0-2.876,2.891"
			/>
		</g>
	</svg>
)
