export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="18.419"
		height="24"
		viewBox="0 0 18.419 24"
		{...props}
	>
		<g id="noun_purchasing_3146605" transform="translate(-17.563 -7.738)">
			<g
				id="Group_1352"
				data-name="Group 1352"
				transform="translate(17.563 7.738)"
			>
				<path
					id="Path_1344"
					data-name="Path 1344"
					d="M20.722,960.1a.355.355,0,0,1-.106.009,2.6,2.6,0,0,0-2.1,1.526,7.966,7.966,0,0,0-.949,3.939v18.384l1-.355a.355.355,0,0,1,.231-.009l1.437.506,1.446-.63a.355.355,0,0,1,.284,0l1.366.621,1.366-.621a.355.355,0,0,1,.284,0l1.411.612,1.286-.683a.355.355,0,0,1,.311-.009l1.216.559V965.574a8.6,8.6,0,0,1,1.047-4.294,4.5,4.5,0,0,1,.994-1.18H20.722Zm12.244.009a2.6,2.6,0,0,0-2.1,1.526,7.874,7.874,0,0,0-.9,3.567h6.024a7.872,7.872,0,0,0-.9-3.567,2.61,2.61,0,0,0-2.112-1.526Zm-12.98,1.349h8.384a.355.355,0,1,1,0,.71H20.056a.357.357,0,1,1-.071-.71Zm-.63,1.881h8.376a.355.355,0,1,1,0,.71h-8.3a.357.357,0,0,1-.071-.71Zm-.3,3.576h5.155a.355.355,0,1,1,0,.71H19.124a.357.357,0,0,1-.071-.71Zm7.293,0H27.6a.355.355,0,1,1,0,.71h-1.18a.357.357,0,0,1-.071-.71Zm-7.293,1.828a.354.354,0,0,1,.071,0h5.084a.355.355,0,1,1,0,.71H19.124a.357.357,0,1,1-.071-.71Zm7.293,0a.354.354,0,0,1,.071,0H27.6a.355.355,0,1,1,0,.71h-1.18a.357.357,0,1,1-.071-.71Zm-7.293,1.819h5.155a.355.355,0,1,1,0,.71H19.124a.357.357,0,1,1-.071-.71Zm7.293,0H27.6a.355.355,0,1,1,0,.71h-1.18a.357.357,0,1,1-.071-.71Zm.044,4.632a.355.355,0,0,1,.284.621L25,977.233a2.661,2.661,0,1,1-.39-.586l1.606-1.393a.354.354,0,0,1,.177-.089Zm-3.868,1.3a1.932,1.932,0,0,0-1.4,3.1,1.938,1.938,0,0,0,2.715.346,1.913,1.913,0,0,0,.621-2.2L22.878,979.1a.355.355,0,0,1-.488-.018l-.745-.745a.358.358,0,0,1,.506-.506l.515.515,1.411-1.224a1.9,1.9,0,0,0-1.18-.612,2.023,2.023,0,0,0-.373-.018Z"
					transform="translate(-17.563 -960.1)"
					fill="#ababab"
				/>
			</g>
		</g>
	</svg>
)
