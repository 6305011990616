import { createReducer } from "@reduxjs/toolkit"
import TopNoticeActionTypes from "./types"

const initState = {
	data: {},
	error: null,
}

const topNoticeReducer = createReducer(initState, {
	[TopNoticeActionTypes.GET_TOP_NOTICE_SUCC]: (state, action) => {
		state.data = action.payload
	},
	[TopNoticeActionTypes.GET_TOP_NOTICE_FAIL]: (state, { payload }) => {
		state.error = payload
		state.data = {}
	},
	[TopNoticeActionTypes.HIDE_TOP_NOTICE]: (state) => {
		state.data = {}
	},
})

export default topNoticeReducer
