import { createReducer } from "@reduxjs/toolkit"
import walletActionTypes from "./types"

const initialState = {
	// wallet.balance returns an object with a walletResponse -- state should be set as what it actually is
	wallet: {
		balance: [],
		loading: null,
		err: null,
	},
	transactions: {
		walletTransactions: {
			pagination: {
				count: 0,
				transactionType: "",
				page: 1,
				size: 12,
				sort: "createdAt",
				sortDir: null,
			},
			rows: [],
		},
		loading: null,
		err: null,
	},
	initiate: {
		responses: [],
		loading: null,
		err: null,
	},
}

const walletReducer = createReducer(initialState, {
	[walletActionTypes.GET_WALLET]: (state) => {
		state.wallet.loading = true
	},
	[walletActionTypes.GET_WALLET_SUCCESS]: (state, action) => {
		state.wallet.balance = action.payload
		state.wallet.loading = false
	},
	[walletActionTypes.GET_WALLET_FAILED]: (state, action) => {
		state.wallet.err = action.payload
		state.wallet.loading = false
	},
	[walletActionTypes.GET_WALLET_TRANSACTIONS]: (state) => {
		state.transactions.loading = true
	},
	[walletActionTypes.GET_WALLET_TRANSACTIONS_SUCCESS]: (state, action) => {
		state.transactions.walletTransactions = action.payload
		state.transactions.loading = false
	},
	[walletActionTypes.GET_WALLET_TRANSACTIONS_FAILED]: (state, action) => {
		state.transactions.err = action.payload
		state.transactions.loading = false
	},
	[walletActionTypes.INITIATE_WALLET_TRANSACTIONS]: (state) => {
		state.initiate.loading = true
	},
	[walletActionTypes.INITIATE_WALLET_TRANSACTIONS_SUCCESS]: (state, action) => {
		state.initiate.responses = action.payload
		state.initiate.loading = false
	},
	[walletActionTypes.INITIATE_WALLET_TRANSACTIONS_FAILED]: (state, action) => {
		state.initiate.err = action.payload
		state.initiate.loading = false
	},
})

export default walletReducer
