import React from "react"

const IconExit = (props) => (
	<svg
		width="24"
		height="21"
		viewBox="0 0 24 21"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M9,0V3H21V18H9v3H24V0Zm3,6V9H0v3H12v3l6-4.5Z" />
	</svg>
)

export default IconExit
