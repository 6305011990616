import request from "./request"

export const getRentals = (assetType, params) =>
	request({
		path: `rentals/list/${assetType}`,
		opts: {
			method: "GET",
			params,
		},
	})

/**
 *
 * @param { assetId, revShare, username } data
 * @returns { success, contractId }
 */
export const rentalOffer = (data) =>
	request({
		path: "rentals/offer",
		opts: {
			method: "POST",
			data,
		},
	})

export const rentalAccept = (data) =>
	request({
		path: `rentals/accept`,
		opts: {
			method: "POST",
			data,
		},
	})

export const rentalRevoke = (rentalId) =>
	request({
		path: `rentals/revoke`,
		opts: {
			method: "POST",
			data: {
				rentalId,
			},
		},
	})
