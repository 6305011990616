import { createAction } from "@reduxjs/toolkit"
import coinActionTypes from "./types"

export const getBalanceReqAction = createAction(coinActionTypes.GET_BALANCE_REQ)
export const getBalanceSuccAction = createAction(
	coinActionTypes.GET_BALANCE_SUCC
)
export const getBalanceFailAction = createAction(
	coinActionTypes.GET_BALANCE_FAIL
)
