import request from "./request"

export const getLatestConversion = (symbol) =>
	request({
		path: `conversions/${symbol}/latest`,
	})

export const getConversion = (data) =>
	request({
		path: `conversions/convert`,
		opts: {
			method: "POST",
			data,
		},
	})
