export const CREATE_BULLETIN_REQ = "CREATE_BULLETIN_REQ"
export const CREATE_BULLETIN_FAILED = "CREATE_BULLETIN_FAILED"

export const LOAD_BULLETIN_REQ = "LOAD_BULLETIN_REQ"
export const LOAD_BULLETIN_FAILED = "LOAD_BULLETIN_FAILED"

export const LOAD_BULLETINS_REQ = "LOAD_BULLETINS_REQ"
export const LOAD_BULLETINS_FAILED = "LOAD_BULLETINS_FAILED"

export const DELETE_BULLETIN_REQ = "DELETE_BULLETIN_REQ"
export const DELETE_BULLETIN_FAILED = "DELETE_BULLETIN_FAILED"

export const UPDATE_BULLETIN_REQ = "UPDATE_BULLETIN_REQ"
export const UPDATE_BULLETIN_FAILED = "UPDATE_BULLETIN_FAILED"

export const SET_BULLETIN = "SET_BULLETIN"
export const SET_BULLETINS = "SET_BULLETINS"

export const READ_BULLETIN_REQ = "READ_BULLETIN_REQ"
export const READ_BULLETIN_FAILED = "READ_BULLETIN_FAILED"
