const stakingActionTypes = {
	GET_STAKING: "GET_STAKING",
	GET_STAKING_SUCCESS: "GET_STAKING_SUCCESS",
	GET_STAKING_FAILED: "GET_STAKING_FAILED",

	INITIATE_STAKING_TRANSACTIONS: "INITIATE_STAKING_TRANSACTIONS",
	INITIATE_STAKING_TRANSACTIONS_SUCCESS:
		"INITIATE_STAKING_TRANSACTIONS_SUCCESS",
	INITIATE_STAKING_TRANSACTIONS_FAILED: "INITIATE_STAKING_TRANSACTONS_FAILED",
}

export default stakingActionTypes
