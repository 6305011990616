import axios from "axios"
import { BASE_URL, MOCK_API_BASE_URL } from "constants/apiConfig"

const request = async ({ headers = {}, path, opts = {}, useMock }) => {
	const defaultHeaders = {
		Authorization: localStorage.getItem("RiotRacersToken"),
		"Content-Type": "application/json",
	}

	const result = await axios({
		url: `${useMock ? MOCK_API_BASE_URL : BASE_URL}/api/${path}`,
		headers: {
			...defaultHeaders,
			...headers,
		},
		...opts,
	})

	return result
}

export default request
