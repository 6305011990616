import { put, call, takeLatest } from "redux-saga/effects"
import notify from "components/commons/notification"
import i18n from "i18n"
import * as actions from "./actions"
import assetUpgradeTypes from "./types"
import * as api from "api/upgrade"

function* getUpgradesSaga() {
	try {
		const result = yield call(api.getAssetUpgrades)
		yield put(actions.getUpgradesSuccess(result.data.upgrades))
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(actions.getUpgradesFailed(err))
	}
}

function* getUpgradeCostSaga({ payload: upgradeId }) {
	try {
		const { data } = yield call(api.getAssetUpgradesCost, upgradeId)
		yield put(actions.getUpgradesCostSuccess(data))
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(actions.getUpgradesCostFailed(err))
	}
}

function* initiateUpgradesTransactionsSaga({ payload }) {
	try {
		const result = yield call(api.postAssetBuyUpgrade, payload)
		yield put(
			actions.initiateUpgradesSuccess({
				data: result.data,
			})
		)
		notify({
			type: "success",
			title: i18n.t("upgradeSuccess"),
		})

		yield call(getUpgradesSaga)
	} catch (err) {
		if (err.response?.data?.errorKey) {
			notify({ messageKey: err.response.data.errorKey })
		} else {
			notify({ messageKey: "upgradeFailed" })
		}
		yield put(actions.initiateUpgradesFailed(err))
	}
}

const upgradeSaga = [
	takeLatest(assetUpgradeTypes.GET_UPGRADES, getUpgradesSaga),
	takeLatest(assetUpgradeTypes.GET_UPGRADES_COST, getUpgradeCostSaga),
	takeLatest(
		assetUpgradeTypes.INITIATE_UPGRADES,
		initiateUpgradesTransactionsSaga
	),
]

export default upgradeSaga
