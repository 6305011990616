export const GET_RENTAL = "GET_RENTAL"
export const GET_RENTAL_SUCCESS = "GET_RENTAL_SUCCESS"
export const GET_RENTAL_FAIL = "GET_RENTAL_FAIL"

export const OFFER_RENTAL = "OFFER_RENTAL"
export const OFFER_RENTAL_SUCCESS = "OFFER_RENTAL_SUCCESS"
export const OFFER_RENTAL_FAIL = "OFFER_RENTAL_FAIL"

export const REVOKE_RENTAL = "REVOKE_RENTAL"
export const REVOKE_RENTAL_SUCCESS = "REVOKE_RENTAL_SUCCESS"
export const REVOKE_RENTAL_FAIL = "REVOKE_RENTAL_FAIL"

export const ACCEPT_RENTAL = "ACCEPT_RENTALL"
export const ACCEPT_RENTAL_SUCCESS = "ACCEPT_RENTAL_SUCCESS"
export const ACCEPT_RENTAL_FAIL = "ACCEPT_RENTAL_FAIL"

export const REMOVE_RENTAL = "REMOVE_RENTAL"
