import { createAction } from "@reduxjs/toolkit"
import withdrawLimitsActionTypes from "./types"

export const getWithdrawLimit = createAction(
	withdrawLimitsActionTypes.GET_WITHDRAW_LIMIT
)
export const getWithdrawLimitSuccess = createAction(
	withdrawLimitsActionTypes.GET_WITHDRAW_LIMIT_SUCCESS
)
export const getWithdrawLimitFailure = createAction(
	withdrawLimitsActionTypes.GET_WITHDRAW_LIMIT_FAILURE
)
