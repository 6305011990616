const IconAchievement = ({ fill, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="18"
		height="36"
		viewBox="0 0 18 36"
		{...props}
	>
		<path
			id="Icon_open-badge"
			data-name="Icon open-badge"
			d="M9,0a9,9,0,1,0,9,9A9.026,9.026,0,0,0,9,0ZM4.5,21.645V36L9,31.5,13.5,36V21.645A13.547,13.547,0,0,1,9,22.5,13.547,13.547,0,0,1,4.5,21.645Z"
			fill={fill}
		/>
	</svg>
)

export default IconAchievement
