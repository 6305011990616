import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { Badge, Popover, Tooltip } from "antd"
import { cartTotalCountSelector } from "store/marketplace/selectors"
import { getCartAction } from "store/marketplace/actions"
import IconCart from "components/commons/Icons/IconCart"

const CartButton = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { totalCount } = useSelector(cartTotalCountSelector)

	const handleCartClick = () => {
		history.push("/marketplace/cart")
	}

	useEffect(() => {
		// TODO: move to this action to the auth saga
		dispatch(getCartAction({ page: 1, size: 12 }))
	}, [])

	if (!totalCount) {
		return (
			<div
				className="cartWrapp"
				style={{
					marginRight: "10px",
					marginLeft: "5px",
					marginTop: "auto",
					marginBottom: "auto",
				}}
			>
				<Tooltip placement="bottom" title={t("cart.title")}>
					<IconCart className="button-icon" onClick={handleCartClick} />
				</Tooltip>
			</div>
		)
	}

	return (
		<Badge
			count={Math.max(totalCount, 0)}
			style={{
				backgroundColor: "#03DAC5",
				color: "#000",
				boxShadow: "none",
			}}
		>
			<div
				className="cartWrapp"
				style={{
					marginRight: "10px",
					marginLeft: "5px",
				}}
			>
				<Tooltip placement="bottom" title={t("cart.title")}>
					<IconCart className="button-icon" onClick={handleCartClick} />
				</Tooltip>
			</div>
		</Badge>
	)
}

export default CartButton
