import React from "react"

export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 12.985"
		{...props}
	>
		<path
			d="M10.256,52.157a1.885,1.885,0,0,1,.3-.177A9.74,9.74,0,0,0,7.4,51.5a7.991,7.991,0,0,0-3.587.682C2.626,53.016,2.5,55.567,2.5,56.1a.6.6,0,0,0,.581.606H8.437C8.513,55.466,8.816,53.142,10.256,52.157Z"
			transform="translate(-2.5 -44.628)"
		/>
		<circle cx="2.981" cy="2.981" r="2.981" transform="translate(1.92 0)" />
		<path
			d="M73.584,56.1c-.025-.657-.177-3.032-1.339-3.815a7.991,7.991,0,0,0-3.587-.682,9.563,9.563,0,0,0-3.158.48c.1.051.2.126.3.177,1.415.96,1.768,3.158,1.819,4.522h5.356a.572.572,0,0,0,.581-.581A.191.191,0,0,0,73.584,56.1Z"
			transform="translate(-49.584 -44.703)"
		/>
		<circle cx="2.981" cy="2.981" r="2.981" transform="translate(16.093 0)" />
		<path
			d="M39.014,55.882a7.991,7.991,0,0,0-3.587-.682,7.991,7.991,0,0,0-3.587.682c-1.187.808-1.314,3.385-1.339,3.891a.6.6,0,0,0,.581.606h8.665a.572.572,0,0,0,.581-.581v-.1C40.3,59.065,40.151,56.665,39.014,55.882Z"
			transform="translate(-23.426 -47.394)"
		/>
		<circle
			cx="2.981"
			cy="2.981"
			r="2.981"
			transform="translate(9.019 0.935)"
		/>
	</svg>
)
