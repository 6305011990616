const authActionTypes = {
	OTP_SEND_REQ: "[auth] send otp request",
	OTP_SEND_SUCC: "[auth] send otp success",
	OTP_SEND_FAIL: "[auth] send otp failed",

	VERIFY_OTP_REQ: "[auth] verify otp request",
	VERIFY_OTP_SUCC: "[auth] verify otp success",
	VERIFY_OTP_FAIL: "[auth] verify otp failed",

	LOGIN_USER_REQ: "[auth] login user request",
	LOGIN_USER_SUCC: "[auth] login user success",
	LOGIN_USER_FAIL: "[auth] login user failed",

	LOGOUT_USER: "[auth] logout user",
	LOGOUT_USER_SUCCESS: "[auth] logout user success",

	CHALLENGE_REQ: "[auth] challenge request",
	CHALLENGE_SUCC: "[auth] challenge success",

	AUTHENTICATION_REQ: "[auth] authentication request",
	AUTHENTICATION_SUCC: "[auth] authentication success",
	AUTHENTICATION_FAIL: "[auth] authentication failed",

	INITIAL_AUTHENTICATE_REQ: "[auth] init authenticate request",
	INITIAL_AUTHENTICATE_SUCC: "[auth] init authenticate success",
	INITIAL_AUTHENTICATE_FAIL: "[auth] init authenticate failed",

	UPDATE_USER_REQ: "[auth] update user request",

	USER_INACTIVE: "[auth] user is not active in two hours",

	RENEW_TOKEN_REQ: "[auth] request new token",

	FETCH_AUTH_USER: "[auth] fetch auth user",
}

export default authActionTypes
