import { createReducer } from "@reduxjs/toolkit"
import leaderboardActionTypes from "./types"

const initialState = {
	leaderboards: {
		loading: false,
		error: undefined,
		results: {},
	},
}

const leaderboardsReducer = createReducer(initialState, {
	[leaderboardActionTypes.GET_LEADERBOARDS]: (state) => {
		state.leaderboards.loading = true
		state.leaderboards.error = undefined
		state.leaderboards.results = {}
	},
	[leaderboardActionTypes.GET_LEADERBOARDS_SUCCESS]: (state, action) => {
		state.leaderboards.loading = false
		state.leaderboards.error = undefined
		state.leaderboards.results = action.payload.data
	},
	[leaderboardActionTypes.GET_LEADERBOARDS_FAILED]: (state, action) => {
		state.leaderboards.loading = false
		state.leaderboards.error = action.error
	},
})

export default leaderboardsReducer
