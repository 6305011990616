import { put, call, takeLatest } from "redux-saga/effects"
import notify from "components/commons/notification"
import * as api from "../../api/leaderboards"
import * as actions from "./actions"
import leaderboardActionTypes from "./types"

function* getLeaderboardSaga(payload) {
	try {
		const result = yield call(api.getLeaderboard, payload)
		yield put(actions.getLeaderboardsSuccAction({ data: result, payload }))
	} catch (err) {
		if (err.response) {
			notify({ title: err.response })
			yield put(actions.getLeaderboardsFailAction(err))
		}
	}
}

export default [
	takeLatest(leaderboardActionTypes.GET_LEADERBOARDS, getLeaderboardSaga),
]
