import React, { useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { hideTopNoticeAction } from "store/topNotice/actions"
import { topNoticeSelector } from "store/topNotice/selectors"

import IconPlus from "../Icons/IconPlus"

import "./styles.scss"

const TopNotice = () => {
	const dispatch = useDispatch()
	const notice = useSelector(topNoticeSelector)

	const classes = useMemo(() => {
		if (notice?.visible) {
			return "RioTopBanner active"
		}
		return "RioTopBanner"
	}, [notice?.visible])

	useEffect(() => {
		if (notice?.visible) {
			setTimeout(() => {
				dispatch(hideTopNoticeAction())
			}, 10 * 60 * 1000)
		}
	}, [notice?.visible])

	const handleCloseBanner = () => {
		dispatch(hideTopNoticeAction())
	}

	return (
		<div className={classes}>
			<div
				className="BannerMessage"
				dangerouslySetInnerHTML={{ __html: notice?.message || "" }}
			/>
			<div
				className="BannerClose"
				role="presentation"
				onClick={handleCloseBanner}
			>
				<IconPlus />
			</div>
		</div>
	)
}

export default TopNotice
