import { takeLatest, put, call, select } from "redux-saga/effects"
import i18n from "i18n"
import { updateAsset, deleteAssetRental } from "store/assets/actions"
import * as rentalApi from "api/rental"
import * as actions from "./actions"
import * as types from "./types"
import notify from "components/commons/notification"
import { isAuthorizedSelector } from "store/auth/selectors"
/**
 *
 * @param {
 *  payload: {
 *    assetType: string
 *    ...queriesProperties
 *  }
 * }
 */
export function* getRentals({ payload }) {
	try {
		const { assetType, ...params } = payload
		const { data } = yield rentalApi.getRentals(assetType, params)
		yield put(actions.getRentalsSuccess(data))
	} catch (err) {
		yield put(actions.getRentalsFail(err))
	}
}

/**
 *
 * @param {
 *  payload: {
 *    assetId: number
 *    assetType: string
 *    revShare: string
 *    username: string
 *  }
 * }
 */
export function* offerRental({ payload }) {
	try {
		const { username, assetId, assetType, revShare } = payload
		const reqPayload = { assetId, revShare }
		if (username) reqPayload.username = username
		const { data } = yield call(rentalApi.rentalOffer, reqPayload)

		if (!username) {
			const assetUpdate = {
				assetId,
				assetType,
				key: "rental",
				value: {
					id: data.contractId,
					revShare,
					renting: null,
				},
			}
			yield put(updateAsset(assetUpdate))
		}
		if (username) {
			return notify({
				type: "success",
				title: i18n.t("userRenting", { name: username, assetId }),
			})
		}
		notify({ type: "success", title: i18n.t("putForRentSuccess", { assetId }) })
	} catch (err) {
		notify({ messageKey: err.response.data.errors })
	}
}

/**
 *
 * @param {
 *  payload: {
 *    assetId: number
 *    rentalId: number
 *    assetType: string
 *  }
 * }
 */
export function* revokeRental({ payload }) {
	try {
		const { rentalId, assetId, assetType } = payload
		yield call(rentalApi.rentalRevoke, rentalId)
		const assetUpdate = { assetId, assetType, key: "rental", value: null }
		yield put(deleteAssetRental({ assetType, rentalId }))
		yield put(updateAsset(assetUpdate))
	} catch (err) {
		notify({ messageKey: err.response.data.errors })
	}
}

/**
 *
 * @param {
 *  payload: {
 * 		rentalId: Number,
 * 		assetId: Number
 * 	}
 */
export function* acceptRental({ payload }) {
	try {
		const isAuthorized = yield select(isAuthorizedSelector)
		if (!isAuthorized) {
			return notify({ messageKey: "mustLoginRent" })
		}
		const { assetId, rentalId } = payload
		delete payload.assetId
		yield call(rentalApi.rentalAccept, payload)
		yield put(actions.acceptRentalSuccess(rentalId))
		notify({ type: "success", title: i18n.t("rentSuccessful", { assetId }) })
	} catch (err) {
		if (err.response.data.errors === "invalidOwner") {
			return notify({ messageKey: "cannotAccectOwnRental" })
		}
		if (
			err.response &&
			err?.response?.data?.errorKey !== "reCaptchaV3Failed" &&
			err?.response?.data?.errorKey !== "reCaptchaResubmit"
		) {
			notify({
				title: "Error",
				messageKey: err.response.data.errorKey,
			})
		}
		yield put(actions.acceptRentalFail(err?.response?.data))
	}
}

const assetRentalSagas = [
	takeLatest(types.GET_RENTAL, getRentals),
	takeLatest(types.OFFER_RENTAL, offerRental),
	takeLatest(types.REVOKE_RENTAL, revokeRental),
	takeLatest(types.ACCEPT_RENTAL, acceptRental),
]

export default assetRentalSagas
