import { createAction } from "@reduxjs/toolkit"
import * as types from "./types"

export const getRacesRequestAction = createAction(types.GET_RACES)
export const getRacesSuccessAction = createAction(types.GET_RACES_SUCCESS)
export const getRacesFailureAction = createAction(types.GET_RACES_FAILED)

export const getWinningsRequestAction = createAction(types.GET_WINNINGS)
export const getWinningsSuccessAction = createAction(types.GET_WINNINGS_SUCCESS)
export const getWinningsFailureAction = createAction(types.GET_WINNINGS_FAILED)

export const getResultsRequestAction = createAction(types.GET_RESULTS)
export const getResultsSuccessAction = createAction(types.GET_RESULTS_SUCCESS)
export const getResultsFailureAction = createAction(types.GET_RESULTS_FAILED)

export const enterRaceRequestAction = createAction(types.ENTER_RACE)
export const enterRaceSuccessAction = createAction(types.ENTER_RACE_SUCCESS)
export const enterRaceFailureAction = createAction(types.ENTER_RACE_FAILED)

export const exitRaceRequestAction = createAction(types.EXIT_RACE)
export const exitRaceSuccessAction = createAction(types.EXIT_RACE_SUCCESS)
export const exitRaceFailureAction = createAction(types.EXIT_RACE_FAILED)

export const updateRaceRequestAction = createAction(types.UPDATE_RACE)
export const updateRaceSuccessAction = createAction(types.UPDATE_RACE_SUCCESS)
export const updateRaceFailureAction = createAction(types.UPDATE_RACE_FAILED)

export const getMyRacesRequestAction = createAction(types.GET_MY_RACES)
export const getMyRacesSuccessAction = createAction(types.GET_MY_RACES_SUCCESS)
export const getMyRacesFailureAction = createAction(types.GET_MY_RACES_FAILED)

export const getShardsRequestAction = createAction(types.GET_SHARDS)
export const getShardsSuccessAction = createAction(types.GET_SHARDS_SUCCESS)
export const getShardsFailureAction = createAction(types.GET_SHARDS_FAILED)

export const getMyWinningsRequestAction = createAction(types.GET_MY_WINNINGS)
export const getMyWinningsSuccessAction = createAction(
	types.GET_MY_WINNINGS_SUCCESS
)
export const getMyWinningsFailureAction = createAction(
	types.GET_MY_WINNINGS_FAILED
)

export const fetchMyStrategies = createAction(types.FETCH_MY_STRATEGIES)
export const fetchMyStrategiesSuccess = createAction(
	types.FETCH_MY_STRATEGIES_SUCCESS
)
export const fetchMyStrategiesFailed = createAction(
	types.FETCH_MY_STRATEGIES_FAILED
)
export const fetchMyStrategiesLoading = createAction(
	types.FETCH_MY_STRATEGIES_LOADING
)

export const fetchMyEntries = createAction(types.FETCH_MY_ENTRIES)
export const fetchMyEntriesSuccess = createAction(
	types.FETCH_MY_ENTRIES_SUCCESS
)
export const fetchMyEntriesFailed = createAction(types.FETCH_MY_ENTRIES_FAILED)
export const fetchMyEntriesLoading = createAction(
	types.FETCH_MY_ENTRIES_LOADING
)

export const saveNewStrategy = createAction(types.SAVE_NEW_STRATEGY)
export const saveNewStrategySuccess = createAction(
	types.SAVE_NEW_STRATEGY_SUCCESS
)
export const saveNewStrategyFailed = createAction(
	types.SAVE_NEW_STRATEGY_FAILED
)
export const saveNewStrategyLoading = createAction(
	types.SAVE_NEW_STRATEGY_LOADING
)

export const openStrategyModal = createAction(types.STRATEGY_MODAL)

export const fetchUserRaceEntry = createAction(types.FETCH_USER_RACE_ENTRY)
export const fetchUserRaceEntrySuccess = createAction(
	types.FETCH_USER_RACE_ENTRY_SUCCESS
)
export const fetchUserRaceEntryFail = createAction(
	types.FETCH_USER_RACE_ENTRY_FAIL
)

export const fetchRaceEntry = createAction(types.FETCH_RACE_ENTRY)
export const fetchRaceEntrySuccess = createAction(
	types.FETCH_RACE_ENTRY_SUCCESS
)
export const fetchRaceEntryFail = createAction(types.FETCH_RACE_ENTRY_FAIL)
export const loadRaceRequestAction = createAction(types.LOAD_RACE)
export const loadRaceSuccessAction = createAction(types.LOAD_RACE_SUCCESS)
export const loadRaceFailedAction = createAction(types.LOAD_RACE_FAILED)

export const fetchRaceEntryStatus = createAction(types.FETCH_RACE_ENTRY_STATUS)
export const fetchRaceEntryStatusSuccess = createAction(
	types.FETCH_RACE_ENTRY_STATUS_SUCCESS
)
export const fetchRaceEntryStatusFail = createAction(
	types.FETCH_RACE_ENTRY_STATUS_FAILED
)

export const deleteStrategy = createAction(types.DELETE_STRATEGY)
export const deleteStrategySuccess = createAction(types.DELETE_STRATEGY_SUCCESS)
export const deleteStrategyFail = createAction(types.DELETE_STRATEGY_FAIL)
