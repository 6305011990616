import { put, call, takeLatest, select } from "redux-saga/effects"
import notify from "components/commons/notification"
import i18n from "i18n"
import * as actions from "./actions"
import walletActionTypes from "./types"
import * as api from "api/wallet"
import { selectWalletTransactions } from "./selectors"

function* getWalletSaga() {
	try {
		const result = yield call(api.getWallet)
		yield put(actions.getWalletSuccess(result.data))
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(actions.getWalletFailure(err))
	}
}

function* getWalletTransactionsSaga({ payload }) {
	try {
		const { data } = yield call(api.getWalletTransactions, payload)
		// this is the new payload
		const newPayload = {
			rows: data.rows,
			pagination: {
				...payload,
				count: data.count,
			},
		}
		yield put(actions.getWalletTransactionsSuccess(newPayload))
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				desc: err.response.data.message,
			})
		}
		yield put(actions.getWalletTransactionsFailure(err))
	}
}

function* initiateWalletTransactionsSaga({ payload }) {
	try {
		const result = yield call(api.postWalletInitiateTransaction, payload)
		yield put(
			actions.initiateWalletTransactionsSuccess({
				data: result.data,
			})
		)
		notify({
			type: "success",
			title: i18n.t("transactionSuccess"),
		})

		yield call(getWalletSaga)
	} catch (err) {
		if (err.response?.data?.errorKey) {
			notify({ messageKey: err.response.data.errorKey })
		} else {
			notify({ messageKey: "walletTransactionFailed" })
		}
		yield put(actions.initiateWalletTransactionsFailure(err))
	}
}

const walletSaga = [
	takeLatest(walletActionTypes.GET_WALLET, getWalletSaga),
	takeLatest(
		walletActionTypes.GET_WALLET_TRANSACTIONS,
		getWalletTransactionsSaga
	),
	takeLatest(
		walletActionTypes.INITIATE_WALLET_TRANSACTIONS,
		initiateWalletTransactionsSaga
	),
]

export default walletSaga
