import { createAction } from "@reduxjs/toolkit"
import * as types from "./types"

export const loadTournamentAction = createAction(types.LOAD_TOURNAMENT_REQ)
export const loadTournamentFailedAction = createAction(
	types.LOAD_TOURNAMENT_FAILED
)

export const loadTournamentsAction = createAction(types.LOAD_TOURNAMENTS_REQ)
export const loadTournamentsFailedAction = createAction(
	types.LOAD_TOURNAMENTS_FAILED
)

export const loadTournamentHeatAction = createAction(
	types.LOAD_TOURNAMENT_HEAT_REQ
)
export const loadTournamentHeatFailedAction = createAction(
	types.LOAD_TOURNAMENT_HEAT_FAILED
)

export const setTournamentAction = createAction(types.SET_TOURNAMENT)
export const setTournamentHeatAction = createAction(types.SET_TOURNAMENT_HEAT)
export const setTournamentsAction = createAction(types.SET_TOURNAMENTS)

export const createTournamentAction = createAction(types.CREATE_TOURNAMENT_REQ)
export const createTournamentFailedAction = createAction(
	types.CREATE_TOURNAMENT_FAILED
)
export const createTournamentFinishedAction = createAction(
	types.CREATE_TOURNAMENT_REQ_FINISHED
)

export const updateTournamentAction = createAction(types.UPDATE_TOURNAMENT_REQ)
export const updateTournamentFailedAction = createAction(
	types.UPDATE_TOURNAMENT_FAILED
)

export const cancelTournamentAction = createAction(types.CANCEL_TOURNAMENT_REQ)
export const cancelTournamentFailedAction = createAction(
	types.CANCEL_TOURNAMENT_FAILED
)

export const inviteTournamentAction = createAction(types.INVITE_USER_REQ)
export const inviteTournamentFailedAction = createAction(
	types.INVITE_USER_REQ_FAILED
)

export const enterTournamentAction = createAction(types.ENTER_TOURNAMENT_REQ)
export const enterTournamentFailedAction = createAction(
	types.ENTER_TOURNAMENT_REQ_FAILED
)
export const enterTournamentFinishedAction = createAction(
	types.ENTER_TOURNAMENT_REQ_FINISHED
)

export const enterTournamentHeatAction = createAction(
	types.ENTER_TOURNAMENT_HEAT_REQ
)
export const enterTournamentHeatFailedAction = createAction(
	types.ENTER_TOURNAMENT_HEAT_REQ_FAILED
)
export const enterTournamentHeatFinishedAction = createAction(
	types.ENTER_TOURNAMENT_HEAT_REQ_FINISHED
)

export const loadInvitationsAction = createAction(types.LOAD_INVITATIONS_REQ)
export const loadInvitationsFailedAction = createAction(
	types.LOAD_INVITATIONS_FAILED
)
export const setInvitationsAction = createAction(types.SET_INVITATIONS)

export const rejectTournamentAction = createAction(types.REJECT_USER_REQ)
export const rejectTournamentFailedAction = createAction(
	types.REJECT_USER_REQ_FAILED
)
export const rejectTournamentFinishedAction = createAction(
	types.REJECT_USER_REQ_FINISHED
)

export const rejectInviteAction = createAction(types.REJECT_INVITE_REQ)
export const rejectInviteFailedAction = createAction(
	types.REJECT_INVITE_REQ_FAILED
)

export const loadTracksAction = createAction(types.LOAD_TRACKS_REQ)
export const loadTracksFailedAction = createAction(types.LOAD_TRACKS_FAILED)
export const setTracksAction = createAction(types.SET_TRACKS)
