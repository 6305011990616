import { put, call, takeLatest, select } from "redux-saga/effects"
import { isUndefined, groupBy } from "lodash"
import * as teamAPI from "api/team"
import * as AssetsApi from "api/assets"
import Spinner from "components/commons/Spinner"
import notify from "components/commons/notification"
import { getUserIdSelector } from "../auth/selectors"
import * as types from "./types"
import * as actions from "./actions"
import { teamIdSelector } from "./selectors"

const showNotify = (err, top) => {
	const errCode = err?.response?.data?.error
	if (errCode) {
		notify({ desc: errCode, top })
	}
}

/**
 * payload is an object
 * {
 *   "title": string,
 *   "picture": number,
 *   "banner"?: number,
 *   "description"?: string,
 * }
 */
function* createTeamSaga({ payload }) {
	try {
		Spinner.show()
		const userId = yield select(getUserIdSelector)
		const reqParams = {
			...(payload || {}),
			userId,
		}

		if (payload.maxCapacity < 2) {
			notify({ messageKey: "maxTeamToLow", bottom: 50 })
		} else {
			const team = yield call([teamAPI, "createTeam"], reqParams)
			yield put(actions.setTeamAction(team.data))
		}
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(actions.createTeamFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* updateTeamSaga({ payload }) {
	try {
		Spinner.show()
		const { viewComponent, cb, ...params } = payload
		const teamId = yield select(teamIdSelector)
		const team = yield call([teamAPI, "updateTeam"], teamId, params)
		yield put(actions.setTeamAction(team.data))
		notify({ messageKey: "UpdateTeam", type: "success", bottom: 40 })

		if (typeof cb === "function") {
			cb()
		}
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(actions.updateTeamFailedAction())
	} finally {
		Spinner.destroy()
	}
}

// payload might be a teamId
function* loadTeamSaga({ payload: teamId }) {
	try {
		Spinner.show()
		const userId = yield select(getUserIdSelector)

		const team = isUndefined(teamId)
			? yield call([teamAPI, "getTeamByUser"], userId)
			: yield call([teamAPI, "getTeamById"], teamId)

		yield put(actions.setTeamAction(team.data))
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(actions.loadTeamFailedAction())
	} finally {
		Spinner.destroy()
	}
}

// payload might be a teamId
function* loadTeamsSaga({ payload }) {
	try {
		Spinner.show()
		const teams = yield call([teamAPI, "getAllTeams"], payload)
		yield put(actions.setTeamsAction(teams.data))
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(actions.loadTeamsFailedAction())
	} finally {
		Spinner.destroy()
	}
}

function* removeTeamSaga() {
	try {
		Spinner.show()
		const teamId = yield select(teamIdSelector)
		yield call([teamAPI, "removeTeam"], teamId)
		yield put(actions.setTeamAction(null))
		notify({ messageKey: "RemoveTeam", type: "success", bottom: 40 })
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(actions.removeTeamFailedAction())
	} finally {
		Spinner.destroy()
	}
}

/**
 * @param {string} payload is a username
 */
function* loadUserAssetSaga({ payload }) {
	try {
		Spinner.show()
		const assets = yield call([AssetsApi, "getAssetsUsername"], payload)
		yield put(actions.loadUserAssetsSuccesAction(groupBy(assets, "assetType")))
	} catch (error) {
		yield call(showNotify, error, 40)
		yield put(actions.loadUserAssetsFailedAction(error))
	} finally {
		Spinner.destroy()
	}
}

export default [
	takeLatest(types.CREATE_TEAM_REQ, createTeamSaga),
	takeLatest(types.LOAD_TEAM_REQ, loadTeamSaga),
	takeLatest(types.LOAD_TEAMS_REQ, loadTeamsSaga),
	takeLatest(types.UPDATE_TEAM_REQ, updateTeamSaga),
	takeLatest(types.REMOVE_TEAM_REQ, removeTeamSaga),
	takeLatest(types.LOAD_USER_ASSETS, loadUserAssetSaga),
]
