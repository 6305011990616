import { useEffect, useMemo, useRef, isValidElement } from "react"
import { useLocation } from "react-router-dom"
import useWindowDimensions from "hooks/useWindowDimensions"
import { IPAD_LANDSCAPE } from "constants/breakPoints"
import Spinner from "../Spinner"

import "./styles.scss"

const MainWrapper = ({ title, className, loading, children }) => {
	const { pathname } = useLocation()
	const { width } = useWindowDimensions()
	const subMenuRef = useRef()
	const containerStyle = useMemo(() => {
		if (className) {
			return `rioMainView ${className}`
		}
		return "rioMainView"
	}, [className])

	const isDashboardPage = pathname.includes("dashboard")
	const isSmallDevice = width <= IPAD_LANDSCAPE

	const handleScroll = () => {
		if (subMenuRef.current) {
			const isSticky = subMenuRef.current.offsetTop - window.pageYOffset < 0
			if (isSticky) {
				subMenuRef.current.classList.add("sticky")
			} else {
				subMenuRef.current.classList.remove("sticky")
			}
		}
	}

	useEffect(() => {
		if (isSmallDevice && isDashboardPage) {
			window.addEventListener("scroll", handleScroll)
			return () => {
				window.removeEventListener("scroll", handleScroll)
			}
		}
		return null
	}, [isDashboardPage, isSmallDevice])

	if (loading) {
		return (
			<div className="innerBlock">
				<Spinner />
			</div>
		)
	}

	return (
		<div className={containerStyle}>
			{title && (
				<div className="rioPageHeader">
					{isValidElement(title) ? (
						title
					) : (
						<>
							<h2 className="heading1">{title}</h2>
						</>
					)}
				</div>
			)}

			<div className="rioPageContent">{children}</div>
		</div>
	)
}

export default MainWrapper
