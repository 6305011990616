import i18n from "i18n"
import { isString, isObject } from "lodash"
import notification from "components/commons/notification"

const assetTypes = {
	car: "car",
	gasstation: "gasstation",
	racetrackland: "racetrackland",
	mechanicshop: "mechanicshop",
	billboardland: "billboardland",
	driver: "driver",
}

const errorNotify = (err) => {
	if (!err) {
		notification({
			title: i18n.t("apologize"),
		})
	} else if (isString(err)) {
		notification({
			title: err,
		})
	} else if (isObject(err)) {
		const { errorKey } = err
		let { assetType } = err
		assetType = assetType ? assetType.toLowerCase() : assetType
		switch (errorKey) {
			case "itemAlreadyAdded":
				notification({
					title: i18n.t("notify.itemAlreadyAdded.title"),
					desc: i18n.t("notify.itemAlreadyAdded.desc"),
				})
				break
			case "limitNumberCart":
				notification({
					title: i18n.t("notify.limitNumberCart.title"),
					desc: i18n.t("notify.limitNumberCart.desc", {
						asset: assetTypes[assetType],
					}),
				})
				break
			case "limitNumberDrop":
				notification({
					title: i18n.t("notify.limitNumberDrop.title"),
					desc: i18n.t("notify.limitNumberDrop.desc", {
						asset: assetTypes[assetType],
					}),
				})
				break
			case "limitWhitelist":
				notification({
					title: i18n.t("notify.limitWhitelist.title"),
					desc: i18n.t("notify.limitWhitelist.desc", {
						asset: assetTypes[assetType],
					}),
				})
				break
			case "outOfStock":
				notification({
					title: i18n.t("notify.outOfStock.title"),
					desc: i18n.t("notify.outOfStock.desc"),
				})
				break
			case "errorAddingToCart":
				notification({
					title: i18n.t("notify.errorAddingToCart.title"),
					desc: i18n.t("notify.errorAddingToCart.desc"),
				})
				break
			default:
				break
		}
	}
}

export default errorNotify
