import { createAction } from "@reduxjs/toolkit"
import * as types from "./types"

export const removeInviteAction = createAction(types.REMOVE_MEMBER_REQ)
export const removeInviteFailed = createAction(types.REMOVE_MEMBER_FAILED)

export const loadTeamMembersAction = createAction(
	types.LOAD_TEAM_MEMBERS_REQUEST
)
export const loadTeamMembersFailedAction = createAction(
	types.LOAD_TEAM_MEMBERS_FAILED
)

export const loadTeamMemberAction = createAction(types.LOAD_TEAM_MEMBER_REQUEST)
export const loadTeamMemberFailedAction = createAction(
	types.LOAD_TEAM_MEMBER_FAILED
)

export const setTeamMemberAction = createAction(types.SET_TEAM_MEMBER)
export const setTeamMembersAction = createAction(types.SET_TEAM_MEMBERS)

export const updateTeamMemberAction = createAction(
	types.UPDATE_TEAM_MEMBER_REQUEST
)
export const updateTeamMemberFailedAction = createAction(
	types.UPDATE_TEAM_MEMBER_FAILED
)

export const leaveTeamAction = createAction(types.LEAVE_TEAM_REQUEST)
export const levatTeamFailedAction = createAction(types.LEAVE_TEAM_FAILED)

export const applyTeamAction = createAction(types.APPLY_TEAM_REQUEST)
export const applyTeamFailedAction = createAction(types.APPLY_TEAM_FAILED)

export const acceptTeamAction = createAction(types.ACCEPT_TEAM_REQUEST)
export const acceptTeamFailedAction = createAction(types.ACCEPT_TEAM_FAILED)

export const inviteUserAction = createAction(types.INVITE_USER_REQUEST)
export const inviteUserFailedAction = createAction(types.INVITE_USER_FAILED)

export const acceptInviteAction = createAction(types.ACCEPT_INVITE)
export const acceptInviteFailed = createAction(types.ACCEPT_INVITE_FAILED)

export const rejectInviteAction = createAction(types.REJECT_INVITE)
export const rejectInviteFailed = createAction(types.REJECT_INVITE_FAILED)

export const cancelInviteAction = createAction(types.CANCEL_INVITE)
export const cancelInviteFailed = createAction(types.CANCEL_INVITE_FAILED)

export const loadInvitationsAction = createAction(types.LOAD_INVITATIONS)
export const loadInvitationsFailed = createAction(types.LOAD_INVITATIONS_FAILED)
export const setInvitations = createAction(types.SET_TEAM_INVITATIONS)
