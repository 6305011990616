import { createReducer } from "@reduxjs/toolkit"
import withdrawLimitsActionTypes from "./types"

const initialState = {
	loading: false,
	response: null,
	error: null,
}

const withdrawLimitReducer = createReducer(initialState, {
	[withdrawLimitsActionTypes.GET_WITHDRAW_LIMIT]: (state) => {
		state.loading = true
	},

	[withdrawLimitsActionTypes.GET_WITHDRAW_LIMIT_SUCCESS]: (
		state,
		{ payload }
	) => {
		state.response = payload
		state.error = null
		state.loading = false
	},

	[withdrawLimitsActionTypes.GET_WITHDRAW_LIMIT_FAILURE]: (
		state,
		{ payload }
	) => {
		state.response = null
		state.error = payload
		state.loading = false
	},
})

export default withdrawLimitReducer
