import { createAction } from "@reduxjs/toolkit"
import GroupActionTypes from "./types"

export const getGroupStatusAction = createAction(
	GroupActionTypes.GET_GROUP_STATUS
)
export const registerGroupAction = createAction(GroupActionTypes.REGISTER_GROUP)
export const setGroupAction = createAction(GroupActionTypes.SET_GROUP)
export const groupStatusFailed = createAction(
	GroupActionTypes.GET_GROUP_STATUS_FAILED
)
export const groupRegisterLoading = createAction(
	GroupActionTypes.GROUP_REGISTER_LOADING
)
