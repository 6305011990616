import { isNumber } from "lodash"

export const delineate = (value) => {
	const base = isNumber(value) ? value.toString() : value
	const parts = base.split(".")
	const intpart = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	const decimal = parts[1]
	let formattedNumber = intpart

	if (decimal) formattedNumber += `.${decimal}`
	return formattedNumber
}

export const formatNum = (value, position = 3) => {
	const base = value || "0"
	const num = isNumber(base) ? value : parseFloat(base)
	if (num === null || num === undefined) return num
	return delineate(parseFloat(num.toFixed(position)))
}

export const countDecimalPlaces = (num) => {
	if (Math.floor(num) === num) return 0
	return num?.toString()?.split(".")[1]?.length || 0
}

export const validatePostive = (value) => {
	const reg = /^\d*\.?\d*$/
	const validate = reg.test(value)
	return validate
}
