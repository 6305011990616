import request from "./request"

const prefix = "team"
const invitePrefix = "invitations"
const applyPrefix = "apply"

export const getAllTeams = (params) =>
	request({
		path: prefix,
		opts: {
			method: "GET",
			params,
		},
	})

export const getTeamByUser = (userId) =>
	request({
		path: `${prefix}/user/${userId}`,
		opts: {
			method: "GET",
		},
	})

export const getTeamById = (teamId) =>
	request({
		path: `${prefix}/${teamId}`,
		opts: {
			method: "GET",
		},
	})

export const updateTeam = (teamId, data) =>
	request({
		path: `${prefix}/${teamId}`,
		opts: {
			method: "PUT",
			data,
		},
	})

export const createTeam = (data) =>
	request({
		path: `${prefix}`,
		opts: {
			method: "POST",
			data,
		},
	})

export const removeTeam = (teamId) =>
	request({
		path: `${prefix}/${teamId}`,
		opts: {
			method: "DELETE",
		},
	})

export const leaveTeam = (teamId) =>
	request({
		path: `${prefix}/leave/${teamId}`,
		opts: {
			method: "DELETE",
		},
	})

export const cancelInvite = (teamId, userId) =>
	request({
		path: `${prefix}/${teamId}/invite/cancel`,
		opts: {
			method: "PUT",
			data: { userId },
		},
	})

export const getAllTeamMembers = (teamId, data) =>
	request({
		path: `${prefix}/${teamId}/member`,
		opts: {
			method: "GET",
			params: data,
		},
	})

export const updateTeamMember = (teamId, data) =>
	request({
		path: `${prefix}/member/${data.userId}`,
		opts: {
			method: "PUT",
			data,
		},
	})

export const inviteUser = (teamId, userId) =>
	request({
		path: `${prefix}/${teamId}/invite`,
		opts: {
			method: "POST",
			data: { userId },
		},
	})

export const applyToTeam = (teamId, data) =>
	request({
		path: `${prefix}/${teamId}/${applyPrefix}`,
		opts: {
			method: "POST",
			data,
		},
	})

export const acceptInvite = (teamId, userId) =>
	request({
		path: `${prefix}/${teamId}/invite/accept`,
		opts: {
			method: "PUT",
			data: { userId },
		},
	})

export const rejectInvite = (teamId, userId) =>
	request({
		path: `${prefix}/${teamId}/invite/reject`,
		opts: {
			method: "PUT",
			data: { userId },
		},
	})

export const getInvitations = () =>
	request({
		path: invitePrefix,
		opts: {
			method: "GET",
		},
	})
