import { createReducer } from "@reduxjs/toolkit"
import coinActionTypes from "./types"

const initialState = {
	loading: true,
	error: undefined,
	balances: {},
}

const coinReducer = createReducer(initialState, {
	[coinActionTypes.GET_BALANCE_REQ]: (state) => {
		state.loading = true
		state.balances = {}
	},
	[coinActionTypes.GET_BALANCE_SUCC]: (state, action) => {
		state.error = undefined
		state.balances = action.payload || {}
		state.loading = false
	},
	[coinActionTypes.GET_BALANCE_FAIL]: (state, action) => {
		state.balances = {}
		state.loading = false
		state.error = action.payload
	},
})

export default coinReducer
