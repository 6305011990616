import { put, call, takeLatest } from "redux-saga/effects"
import i18n from "i18n"
import notify from "components/commons/notification"
import * as types from "./types"
import * as actions from "./actions"
import * as api from "api/rewards"

function* getBalanceInit() {
	try {
		const { data } = yield call(api.getBalance)
		yield put(actions.getBalanceSuccess(data))
	} catch (err) {
		yield put(actions.getBalanceFailure(err))
	}
}

function* claimRewardsInit({ payload }) {
	try {
		const { data } = yield call(api.claimRewards, payload)
		yield put(actions.claimRewardsSuccess(data))
		yield call(getBalanceInit)
		notify({
			type: "success",
			title: i18n.t("notification.claimedSuccessfully.title"),
			desc: i18n.t("notification.claimedSuccessfully.description"),
		})
	} catch (err) {
		if (err.response) {
			notify({
				title: "Error",
				messageKey: err.response.data.errorKey,
			})
		}
		yield put(actions.claimRewardsFailure())
	}
}

function* getHistoryInit({ payload }) {
	try {
		const { data } = yield call(api.getHistory, payload)
		yield put(actions.getRewardsHistorySuccess(data))
	} catch (err) {
		yield put(actions.getRewardsHistoryFailure(err))
	}
}

const rewardsSagas = [
	takeLatest(types.GET_BALANCE, getBalanceInit),
	takeLatest(types.CLAIM_REWARDS, claimRewardsInit),
	takeLatest(types.GET_REWARDS_HISTORY, getHistoryInit),
]

export default rewardsSagas
