const discordActionTypes = {
	REDIRECT_DISCORD_CALLBACK_START: "[discord] redirect discord callback start",
	REDIRECT_DISCORD_CALLBACK_SUCCESS:
		"[discord] redirect discord callback success",
	REDIRECT_DISCORD_CALLBACK_FAILURE:
		"[discord] redirect discord callback failure",
	REDIRECT_DISCORD_CALLBACK_LOADING:
		"[discord] redirect discord callback loading",

	UNLINK_DISCORD_START: "[discord] unlink discord start",
	UNLINK_DISCORD_SUCCESS: "[discord] unlink discord success",
	UNLINK_DISCORD_FAILURE: "[discord] unlink discord failure",

	SYNC_DISCORD_ROLES_START: "[discord] sync roles start",
	SYNC_DISCORD_ROLES_SUCCESS: "[discord] sync roles success",
	SYNC_DISCORD_ROLES_FAILURE: "[discord] sync roles failure",
}

export default discordActionTypes
