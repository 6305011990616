import { createAction } from "@reduxjs/toolkit"
import activitiesActionTypes from "./types"

export const getActivitesReqAction = createAction(
	activitiesActionTypes.GET_ACTIVITIES_REQ
)
export const getActivitesSuccAction = createAction(
	activitiesActionTypes.GET_ACTIVITIES_SUCC
)
export const getActivitesFailAction = createAction(
	activitiesActionTypes.GET_ACTIVITIES_FAIL
)
