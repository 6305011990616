import { createSelector } from "@reduxjs/toolkit"
import { getUserIdSelector } from "store/auth/selectors"
import teamImg from "assets/images/team.png"
import defaultBanner from "assets/images/profile_banner.png"

const stateSelector = (state) => state.teams

export const isProcessingTeamSelector = createSelector(
	stateSelector,
	(state) => state.processing
)

export const isLoadingSelector = createSelector(
	stateSelector,
	(state) => state.loading
)

export const isTeamErrorSelector = createSelector(
	stateSelector,
	(state) => state.error
)

export const teamsSelector = createSelector(
	stateSelector,
	(state) => state.entities
)

export const teamSelector = createSelector(
	stateSelector,
	(state) => state.entity
)

export const teamDetailsSelector = createSelector(
	teamSelector,
	(team) => team || {}
)

export const teamPictureSelector = createSelector(
	teamSelector,
	(team) => team?.team_picture?.uri || teamImg
)

export const teamBannerSelector = createSelector(
	teamSelector,
	(team) => team?.team_banner?.uri || defaultBanner
)

export const teamTitleSelector = createSelector(
	teamSelector,
	(team) => team?.title
)

export const teamDescSelector = createSelector(
	teamSelector,
	(team) => team?.description
)

export const teamMembersSelector = createSelector(
	teamSelector,
	(team) => team?.members || []
)

export const teamIdSelector = createSelector(
	teamSelector,
	(team) => team?.id || null
)

export const teamDataSelector = createSelector(
	teamPictureSelector,
	teamBannerSelector,
	getUserIdSelector,
	teamDetailsSelector,
	(picture, banner, cUserId, details) => ({
		picture,
		banner,
		cUserId,
		details,
	})
)

export const getUserAssetSelector = createSelector(
	stateSelector,
	(state) => state.userAssets
)

export const isLoadUserAssetSelector = createSelector(
	stateSelector,
	(state) => state.loadUserAssets
)

export const membersSelector = createSelector(
	teamMembersSelector,
	getUserIdSelector,
	(members) => members
)

export const memberIdSelector = createSelector(
	teamMembersSelector,
	getUserIdSelector,
	(cUserId) => cUserId
)
