import { createReducer } from "@reduxjs/toolkit"
import stakingActionTypes from "./types"

const initialState = {
	stakingWallet: {
		balance: [],
		loading: null,
		err: null,
	},
	initiate: {
		responses: [],
		loading: null,
		err: null,
	},
}

const stakingReducer = createReducer(initialState, {
	[stakingActionTypes.GET_STAKING]: (state) => {
		state.stakingWallet.loading = true
	},
	[stakingActionTypes.GET_STAKING_SUCCESS]: (state, action) => {
		state.stakingWallet.balance = action.payload
		state.stakingWallet.loading = false
	},
	[stakingActionTypes.GET_STAKING_FAILED]: (state, action) => {
		state.stakingWallet.err = action.payload
		state.stakingWallet.loading = false
	},
	[stakingActionTypes.INITIATE_STAKING_TRANSACTIONS]: (state) => {
		state.initiate.loading = true
	},
	[stakingActionTypes.INITIATE_STAKING_TRANSACTIONS_SUCCESS]: (
		state,
		action
	) => {
		state.initiate.responses = action.payload
		state.initiate.loading = false
	},
	[stakingActionTypes.INITIATE_STAKING_TRANSACTIONS_FAILED]: (
		state,
		action
	) => {
		state.initiate.err = action.payload
		state.initiate.loading = false
	},
})

export default stakingReducer
