import { createAction } from "@reduxjs/toolkit"
import profileTypes from "./types"

export const getProfileReqAction = createAction(profileTypes.GET_PROFILE_REQ)
export const getProfileSuccAction = createAction(
	profileTypes.GET_PROFILE_SUCCESS
)
export const getProfileFailAction = createAction(
	profileTypes.GET_PROFILE_FAILED
)
export const updateUserAssetNameRequest = createAction(
	profileTypes.UPDATE_USER_ASSET_NAME_REQ
)
export const updateUserAssetSuccess = createAction(
	profileTypes.UPDATE_USER_ASSET_SUCCESS
)
export const getUserAssetsReqAction = createAction(
	profileTypes.GET_USER_ASSETS_REQ
)
export const getUserAssetsSuccAction = createAction(
	profileTypes.GET_USER_ASSETS_SUCCESS
)
export const getUserAssetsFailAction = createAction(
	profileTypes.GET_USER_ASSETS_FAILED
)
