import { createReducer } from "@reduxjs/toolkit"
import profileTypes from "./types"
import { updateItem } from "helper/addOrReplace"

const initialState = {
	loading: false,
	detail: null,
	error: null,
	assets: {
		loading: false,
	},
}

const profileReducer = createReducer(initialState, {
	[profileTypes.GET_PROFILE_REQ]: (state) => {
		state.loading = true
		state.detail = null
		state.error = null
	},
	[profileTypes.GET_PROFILE_SUCCESS]: (state, { payload }) => {
		state.detail = payload
		state.loading = false
		state.error = null
	},
	[profileTypes.GET_PROFILE_FAILED]: (state, { payload }) => {
		state.loading = false
		state.detail = null
		state.error = payload
	},
	[profileTypes.GET_USER_ASSETS_REQ]: (state) => {
		state.assets.loading = true
	},
	[profileTypes.GET_USER_ASSETS_SUCCESS]: (state, { payload }) => {
		state.assets = payload
	},
	[profileTypes.GET_USER_ASSETS_FAILED]: (state) => {
		state.assets = {}
	},
	[profileTypes.UPDATE_USER_ASSET_NAME_REQ]: (state, { payload }) => {
		const { assetId, assetType, ...rest } = payload
		const activedItem = state.assets[assetType]?.find(
			(asset) => asset.assetId === assetId
		)

		state.assets[assetType] = updateItem(state.assets[assetType], {
			...activedItem,
			...rest,
		})
		state.loading = false
		state.assets.loading = false
	},
	[profileTypes.UPDATE_USER_ASSET_SUCCESS]: (state, { payload }) => {
		const { assetId, assetType, data } = payload
		const activedItem = state.assets[assetType]?.find(
			(asset) => asset.assetId === assetId
		)

		state.assets[assetType] = updateItem(state.assets[assetType], {
			...activedItem,
			...data,
		})
		state.loading = false
		state.assets.loading = false
	},
})

export default profileReducer
