import i18n from "i18n"
import { isEmpty, pick } from "lodash"
import { put, call, takeLatest, select } from "redux-saga/effects"
import notify from "components/commons/notification"
import jwtDecode from "jwt-decode"
import * as api from "api/users"
import { verifyOtpApi, sendOtpApi } from "api/auth0Request"
import { openModalAction, closeModalAction } from "../modal/actions"
import { getProfileSuccAction } from "../profile/actions"
import authActionTypes from "./types"
import * as actions from "./actions"
import { web3ModalDisconnect } from "store/web3Modal/actions"
import { toggleModal } from "store/starzModal/actions"

function* loginUserSaga({
	payload: { publicAddress, username, captchaV2, captchaV3, email, history },
}) {
	try {
		const user = yield call(api.createUserApi, {
			publicAddress,
			username,
			captchaV2,
			captchaV3,
			// email,
		})
		yield put(actions.loginSuccAction(user))
		if (history && history.push) {
			yield call(history.push, "/dashboard/overview")
		}
	} catch (err) {
		yield put(actions.loginFailAction(err?.response?.data?.errorKey))
	}
}

function* authSaga({
	payload: {
		publicAddress,
		signature,
		email,
		history,
		clientSeed,
		challengeId,
	},
}) {
	try {
		const result = yield call(
			api.authApi,
			publicAddress,
			signature,
			email,
			clientSeed,
			challengeId
		)
		yield put(actions.authenticateSuccAction(result))
		if (history && history.push) {
			if (!isEmpty(result?.user)) {
				yield put(closeModalAction())
				yield put(toggleModal(false))
				yield call(history.push, "/dashboard/overview")
			} else {
				yield put(toggleModal(false))
				yield put(openModalAction({ step: 2 }))
			}
		}
	} catch (err) {
		if (err.response) {
			notify({ title: err.response.message })
		}
		yield put(actions.authenticateFailAction(err.message))
	}
}

function* sendOtpSaga({
	payload: { email, publicAddress, onSuccess, onError },
}) {
	try {
		const { data } = yield call(api.checkEmailApi, email, publicAddress)
		if (!data.isEmailValid) {
			const errMsg = publicAddress
				? i18n.t("addressNotAssociated")
				: i18n.t("emailExit")
			yield call(onError, errMsg)
			if (publicAddress) {
				yield put(actions.sendOtpFailAction(errMsg))
			}
			return
		}

		// const { data: otpData } = yield call(sendOtpApi, email)
		// yield put(actions.sendOtpSuccAction(otpData))
		yield call(onSuccess)
	} catch (err) {
		if (err?.response?.data?.error === "server_error") {
			yield call(onError, i18n.t("otpServerError"))
		} else if (err?.response?.data?.error === "bad.email") {
			yield call(onError, i18n.t("otpEmail"))
		} else {
			yield call(onError, i18n.t("otpUnableToAuth"))
		}
		yield put(actions.sendOtpFailAction(err.message))
	}
}

function* verifyOtpSaga({ payload: { otp, email, onSuccess, onError } }) {
	try {
		const { data } = yield call(verifyOtpApi, otp, email)
		yield put(actions.verifyOtpSuccAction(data))
		yield call(onSuccess)
	} catch (err) {
		yield call(onError)
		yield put(actions.verifyOtpFailAction(err.message))
	}
}

function* initAuthSaga({ payload: { history } }) {
	try {
		const token = localStorage.getItem("RiotRacersToken")
		if (token) {
			const { exp } = jwtDecode(token)
			if (!(Date.now() >= exp * 1000)) {
				yield call(fetchAuthUser)
				if (history && history?.push) {
					if (history?.location?.pathname === "/") {
						yield call(history.push, "/dashboard/overview")
					}
				}
			} else {
				yield put(actions.logoutAction())
			}
		} else {
			yield put(actions.logoutAction())
		}
	} catch (err) {
		yield put(actions.initAuthFailAction(err.message))
	}
}

// export function* fetchAuthUser() {
//   const result = yield call(api.authenticateApi)
//   yield put(actions.initAuthSuccAction(result.user))
// }

export function* fetchAuthUser() {
	const { data } = yield call(api.authenticateApi)
	yield put(actions.initAuthSuccAction(data.user))
}

function* updateUserSaga({ payload }) {
	try {
		const cUser = yield select((state) => state.auth.user)
		const { viewComponent, cb, ...params } = payload
		const data = yield call(api.updateUserApi, params, cUser.id)

		notify({ type: "success", title: i18n.t("updateSucc") })

		yield put(actions.sendOtpSuccAction(data.user))

		if (viewComponent === "AccountProfile") {
			const profileData = pick(data.user, [
				"id",
				"username",
				"email",
				"publicAddress",
				"profile_picture",
				"banner_picture",
			])
			yield put(getProfileSuccAction(profileData))
		}

		if (typeof cb === "function") {
			cb()
		}
	} catch (error) {
		const errMsg = error?.response?.data?.message || i18n.t("apologize")
		notify({ title: errMsg })
	}
}

function* userInactiveSaga() {
	yield put(actions.logoutAction())
	yield put(openModalAction({ type: "authModal", step: 2 }))
}

function* renewTokenSaga() {
	try {
		const { data } = yield call(api.renewTokenApi)
		localStorage.setItem("RiotRacersToken", data?.token)
	} catch (error) {
		yield put(actions.logoutAction())
	}
}

function* logoutUser() {
	yield put(web3ModalDisconnect())
	yield put(actions.logoutActionSuccess())
}

export default [
	takeLatest(authActionTypes.AUTHENTICATION_REQ, authSaga),
	takeLatest(authActionTypes.OTP_SEND_REQ, sendOtpSaga),
	takeLatest(authActionTypes.VERIFY_OTP_REQ, verifyOtpSaga),
	takeLatest(authActionTypes.INITIAL_AUTHENTICATE_REQ, initAuthSaga),
	takeLatest(authActionTypes.LOGIN_USER_REQ, loginUserSaga),
	takeLatest(authActionTypes.UPDATE_USER_REQ, updateUserSaga),
	takeLatest(authActionTypes.USER_INACTIVE, userInactiveSaga),
	takeLatest(authActionTypes.RENEW_TOKEN_REQ, renewTokenSaga),
	takeLatest(authActionTypes.FETCH_AUTH_USER, fetchAuthUser),
	takeLatest(authActionTypes.LOGOUT_USER, logoutUser),
]
