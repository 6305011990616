import { createAction } from "@reduxjs/toolkit"
import authActionTypes from "./types"

export const authenticateReqAction = createAction(
	authActionTypes.AUTHENTICATION_REQ
)
export const authenticateSuccAction = createAction(
	authActionTypes.AUTHENTICATION_SUCC
)
export const authenticateFailAction = createAction(
	authActionTypes.AUTHENTICATION_FAIL
)

export const sendOtpReqAction = createAction(authActionTypes.OTP_SEND_REQ)
export const sendOtpSuccAction = createAction(authActionTypes.OTP_SEND_SUCC)
export const sendOtpFailAction = createAction(authActionTypes.OTP_SEND_FAIL)

export const verifyOtpReqAction = createAction(authActionTypes.VERIFY_OTP_REQ)
export const verifyOtpSuccAction = createAction(authActionTypes.VERIFY_OTP_SUCC)
export const verifyOtpFailAction = createAction(authActionTypes.VERIFY_OTP_FAIL)

export const initAuthReqAction = createAction(
	authActionTypes.INITIAL_AUTHENTICATE_REQ
)
export const initAuthSuccAction = createAction(
	authActionTypes.INITIAL_AUTHENTICATE_SUCC
)
export const initAuthFailAction = createAction(
	authActionTypes.INITIAL_AUTHENTICATE_FAIL
)

export const loginReqAction = createAction(authActionTypes.LOGIN_USER_REQ)
export const loginSuccAction = createAction(authActionTypes.LOGIN_USER_SUCC)
export const loginFailAction = createAction(authActionTypes.LOGIN_USER_FAIL)

export const updateUserAction = createAction(authActionTypes.UPDATE_USER_REQ)

export const logoutAction = createAction(authActionTypes.LOGOUT_USER)
export const logoutActionSuccess = createAction(
	authActionTypes.LOGOUT_USER_SUCCESS
)

export const userInactiveAction = createAction(authActionTypes.USER_INACTIVE)

export const renewTokenAction = createAction(authActionTypes.RENEW_TOKEN_REQ)

export const fetchAuthUser = createAction(authActionTypes.FETCH_AUTH_USER)
