import { all } from "redux-saga/effects"

import auth from "./auth/sagas"
import properties from "./property/sagas"
import activitiesSaga from "./activities/sagas"
import assetsSaga from "./assets/sagas"
import topNoticeSagas from "./topNotice/sagas"
import profileSagas from "./profile/sagas"
import teamSagas from "./teams/sagas"
import teamMembersSagas from "./teammembers/sagas"
import bulletinSaga from "./bulletins/sagas"
import groupSagas from "./group/sagas"
import clubSagas from "./club/sagas"
import races from "./races/sagas"
import discordSagas from "./discord/sagas"
import conversions from "./conversions/sagas"
import leaderboards from "./leaderboards/sagas"
import walletSaga from "./wallet/sagas"
import withdrawLimitSagas from "./withdrawLimits/sagas"
import rewardsSagas from "./rewards/sagas"
import rentalSagas from "./rentals/sagas"
import web3Sagas from "./web3Modal/sagas"
import carSagas from "./car/sagas"
import cars from "./cars/sagas"
import droplimits from "./droplimits/sagas"
import driverSagas from "./driver/sagas"
import landsSagas from "./lands/sagas"
import marketplaceSagas from "./marketplace/sagas"
import settingsSagas from "./settings/sagas"
import tournamentsSagas from "./tournaments/sagas"
import stakingSaga from "./stakingWallets/sagas"
import upgradeSaga from "./upgrade/sagas"

export default function* rootSaga() {
	const sagas = [
		...auth,
		...properties,
		...activitiesSaga,
		...assetsSaga,
		...topNoticeSagas,
		...profileSagas,
		...teamSagas,
		...teamMembersSagas,
		...bulletinSaga,
		...groupSagas,
		...clubSagas,
		...races,
		...discordSagas,
		...conversions,
		...leaderboards,
		...walletSaga,
		...withdrawLimitSagas,
		...rewardsSagas,
		...tournamentsSagas,
		...rentalSagas,
		...web3Sagas,
		...stakingSaga,
		...carSagas,
		...cars,
		...droplimits,
		...driverSagas,
		...landsSagas,
		...marketplaceSagas,
		...settingsSagas,
		...upgradeSaga,
	]
	yield all(sagas)
}
