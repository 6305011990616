import React from "react"

export default (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		{...props}
	>
		<g transform="translate(17461 -2568)">
			<g transform="translate(-17459 2568)">
				<g transform="translate(0 0)">
					<circle cx="5.4" cy="5.4" r="5.4" transform="translate(4.6)" />
					<path
						d="M37.4,49H28.6a5.625,5.625,0,0,0-3.96,1.68,5.759,5.759,0,0,0-1.64,4v4.8a.738.738,0,0,0,.8.72H42.2a.715.715,0,0,0,.8-.72v-4.8A5.686,5.686,0,0,0,37.4,49Z"
						transform="translate(-23 -36.88)"
					/>
				</g>
			</g>
			<rect
				width="24"
				height="24"
				transform="translate(-17461 2568)"
				fill="none"
			/>
		</g>
	</svg>
)
